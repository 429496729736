//#region Imports
import React, { useState, useEffect } from "react";
import sharedDatas from "../../businessLogic/sharedDatas";
import { useNavigate } from "react-router-dom";

//oneFront SDK
import {
  useSnackbar,
  useQuery,
  useMutation,
  useTranslation,
} from "@onefront/react-sdk";

import VaporPage from "@vapor/react-custom/VaporPage";

//Vapor Components
import Grid from "@vapor/react-material/Grid";
import Stack from "@vapor/react-material/Stack";
import IconButton from "@vapor/react-material/IconButton";

//Icons
import { ArrowForward as DetailIcon } from "@mui/icons-material";

import {
  DataGridPro,
  GridToolbar,
  itIT,
} from "@mui/x-data-grid-pro";

//Custom components
import { Loading } from "../../components/Loading";
import { NotAuth } from "../../components/NotAuth";
import { NoData } from "../../components/NoData";

//Constants, Api and Data Models
import {
  bsaSegnalazione,
  pathSegnalazioneHome,
} from "../../businessLogic/constants";
import {
  setLocalStorageSelectedFascicolo,
  setLocalStorageSelectedEntity,
} from "../../businessLogic/bl";
import { userPermissionModel } from "../../businessLogic/models";
import { query_sos_byTenant } from "../../businessLogic/query";
import {
  mutation_add_log,
  mutation_add_lastActivity_byUserId,
  mutation_delete_lastActivity,
} from "../../businessLogic/mutation";
//#endregion

export const SegnalazioneHome = ({ basePath }) => {
  const tenantId = sharedDatas.getPropertyByName("tenantId");
  const aziendaId = sharedDatas.getPropertyByName("aziendaId");
  const loggedUserId = sharedDatas.getPropertyByName("userId");
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  //Query
  const apiSOSByTenant = useQuery(
    query_sos_byTenant,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );

  //#region Mutation
  const apiAddLog = useMutation(mutation_add_log, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddLastActivity = useMutation(mutation_add_lastActivity_byUserId, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiDeleteLastActivity = useMutation(mutation_delete_lastActivity, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  //#endregion

  //#region UseStates
  const [backDropOpen, setBackDropOpen] = useState(true); //Loader utilizzato durante il caricamento
  const [isDataLoaded, setIsDataLoaded] = useState(false); //Indica lo stato di caricamento del result Api
  const [userPermissions, setUserPermissions] = useState(
    new userPermissionModel(
      0,
      aziendaId,
      bsaSegnalazione,
      false,
      false,
      false,
      false
    )
  );
  const [, setSOSList] = useState([]); //Contiene le SOS

  //DataGrid
  const [, setDataItems] = useState([]); //Contiene i records
  const [dataColumns, setDataColumns] = useState([]); //Contiene le columns
  const [dataRows, setDataRows] = useState([]); //Contiene le rows
  //#endregion

  useEffect(() => {
    loadDatas();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataLoaded]);
  async function loadDatas() {
    try {
      if (
        sharedDatas.getPropertyByName("tenantId") === "" ||
        sharedDatas.getPropertyByName("tenantModel") === null ||
        sharedDatas.getPropertyByName("aziendaId") === 0 ||
        sharedDatas.getPropertyByName("aziendaModelList") === null ||
        sharedDatas.getPropertyByName("userId") === "" ||
        sharedDatas.getPropertyByName("userModel") === null
      ) {
        navigate("/");
      } else {
        setBackDropOpen(true);

        if (!isDataLoaded) {
          //Last Activity
          await apiDeleteLastActivity.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            userId: loggedUserId,
          });
          await apiAddLastActivity.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            path: pathSegnalazioneHome,
            entityId: "",
            entityName: "",
          });

          /**************************************/
          //Carico i permessi relativi all'utente
          const userPerms = sharedDatas
            .getPropertyByName("userPermissions")
            .filter((x) => x.area === bsaSegnalazione)[0];
          setUserPermissions(userPerms);

          /**************************************/
          //Carico le prestazioni
          setDataColumns([
            { field: "id", headerName: "Id", type: "number", flex: 0.2 },
            {
              field: "fascicoloNome",
              headerName: "Fascicolo",
              type: "string",
              flex: 1,
              editable: false,
            },
            {
              field: "date",
              headerName: t("autovalutazione.form.data"),
              type: "date",
              flex: 0.2,
              valueFormatter: (params) => {
                if (params.value == null) {
                  return "";
                }
                return new Date(params.value).toLocaleDateString();
              },
            },
            {
              field: "receiptId",
              headerName: "Prot. N.",
              type: "number",
              flex: 0.2,
              editable: false,
            },
            {
              field: "reportedBy",
              headerName: "Segnalata da",
              type: "string",
              flex: 0.2,
              editable: false,
            },
            {
              field: "createdOn",
              headerName: t("fascicolo.form.creatoIl"),
              type: "dateTime",
              flex: 0.2,
              valueFormatter: (params) => {
                if (params.value == null) {
                  return "";
                }
                return new Date(params.value).toLocaleDateString();
              },
            },
            {
              field: "modifiedOn",
              headerName: t("fascicolo.form.modificatoIl"),
              type: "dateTime",
              flex: 0.2,
              valueFormatter: (params) => {
                if (params.value == null) {
                  return "";
                }
                return new Date(params.value).toLocaleDateString();
              },
            },
            {
              field: "actions",
              headerName: "",
              flex: 0.2,
              filterable: false,
              renderCell: (params) =>
                renderDetailsButton(userPerms, params.row),
            },
          ]);

          const sosResponse = await apiSOSByTenant.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            userId: loggedUserId,
          });
          const arrItems = [];

          for (let i = 0; i < sosResponse.data.data.sos.length; i++) {
            const item = sosResponse.data.data.sos[i];
            const itemModel = {
              id: item.Id,
              fascicoloId: item.fascicolo.Id,
              fascicoloNome: item.fascicolo.Name,
              date: item.Date,
              receiptId: item.ReceiptId,
              reportedBy: item.user.Name + " " + item.user.Surname,
              createdOn: item.CreatedOn,
              modifiedOn: item.ModifiedOn,
            };
            arrItems.push(itemModel);
          }
          setSOSList(arrItems);
          setDataItems(arrItems);
          setDataRows(arrItems);
        }
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "SegnalazioneHome",
        method: "loadDatas",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
      setIsDataLoaded(true);
    }
  }

  //#region DataGrid
  //Grid Button Detail
  const renderDetailsButton = (userPerms, item) => {
    return (
      <Stack direction='row' spacing={0}>
        {userPerms.canRead ? (
          <IconButton
            color='primary'
            onClick={(e) => handleGridBtnDetailClick(e, item)}
          >
            <DetailIcon />
          </IconButton>
        ) : null}
      </Stack>
    );
  };
  const handleGridBtnDetailClick = (event, item) => {
    event.stopPropagation();
    setLocalStorageSelectedFascicolo(item.fascicoloId);
    setLocalStorageSelectedEntity("sos", item.id);
    navigate("/fascicolo/manage");
  };
  //#endregion

  //#region Return Graphics
  return (
    <VaporPage title={t("segnalazioni.titolo")}>
      <Loading open={backDropOpen} />

      {isDataLoaded && !userPermissions.canRead ? <NotAuth /> : null}

      {isDataLoaded && userPermissions.canRead ? (
        <VaporPage.Section>
          <Grid container>
            <Grid item xs={12} sx={{ width: "100%" }}>
              {dataRows.length > 0 ? (
                <DataGridPro
                  rows={dataRows}
                  columns={dataColumns}
                  slots={{ toolbar: GridToolbar }}
                  autoHeight={true}
                  pageSizeOptions={[25, 50, 100]}
                  localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                  }
                  pagination
                  disableRowSelectionOnClick
                  onRowClick={(item, e) =>
                    handleGridBtnDetailClick(e, item.row)
                  }
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        id: false,
                      },
                    },
                    pagination: {
                      paginationModel: {
                        pageSize: 25,
                      },
                    },
                  }}
                  slotProps={{
                    toolbar: { printOptions: { disableToolbarButton: true } },
                  }}
                />
              ) : (
                <NoData />
              )}
            </Grid>
          </Grid>
        </VaporPage.Section>
      ) : null}
    </VaporPage>
  );
  //#endregion
};
