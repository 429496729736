//#region Imports
import React, { useState, useEffect, Fragment, useRef } from "react";
import sharedDatas from "../../businessLogic/sharedDatas";

//oneFront SDK
import {
  useSnackbar,
  useQuery,
  useMutation,
  useGet,
  useTranslation,
} from "@onefront/react-sdk";

import VaporPage from "@vapor/react-custom/VaporPage";
import VaporToolbar from "@vapor/react-custom/VaporToolbar";
import VaporTag from "@vapor/react-custom/VaporTag";

//Vapor Components
import Grid from "@vapor/react-material/Grid";
import Stack from "@vapor/react-material/Stack";
import IconButton from "@vapor/react-material/IconButton";
import Checkbox from "@vapor/react-material/Checkbox";
import Divider from "@vapor/react-material/Divider";
import Button from "@vapor/react-material/Button";
import TextField from "@vapor/react-material/TextField";
import Autocomplete from "@vapor/react-material/Autocomplete";
import Switch from "@vapor/react-material/Switch";
import FormGroup from "@vapor/react-material/FormGroup";
import FormControlLabel from "@vapor/react-material/FormControlLabel";
import AccordionSummary from "@vapor/react-material/AccordionSummary";
import AccordionDetails from "@vapor/react-material/AccordionDetails";
import TableContainer from "@vapor/react-material/TableContainer";
import Table from "@vapor/react-material/Table";
import TableBody from "@vapor/react-material/TableBody";
import TableRow from "@vapor/react-material/TableRow";
import TableCell from "@vapor/react-material/TableCell";
import Paper from "@vapor/react-material/Paper";
import Box from "@vapor/react-material/Box";

import ExtendedTypography from "@vapor/react-extended/ExtendedTypography";
import ExtendedAccordion from "@vapor/react-extended/ExtendedAccordion";

//Vapor Date Pickers
import { AdapterDateFns } from "@vapor/react-x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@vapor/react-x-date-pickers/LocalizationProvider";
import { DatePicker } from "@vapor/react-x-date-pickers/DatePicker";
import { it } from "date-fns/locale";

//Icons
import {
  AddOutlined as AddIcon,
  ModeEdit as ModeEditIcon,
  Warning as WarningIcon,
  Delete as DeleteIcon,
  SaveOutlined as SaveIcon,
  CloseOutlined as CancelIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";

//Custom components
import { Loading } from "../Loading";
import { NotAuth } from "../NotAuth";
import { NoData } from "../NoData";
import { ModalDelete } from "../ModalDelete";

//Constants, Api and Data Models
import { bsaPrestazione } from "../../businessLogic/constants";
import {
  isDateValid,
  getLocalStorageSelectedEntity,
  removeLocalStorageSelectedEntity,
  isSameDate
} from "../../businessLogic/bl";
import {
  userPermissionModel,
  prestazioneModel,
  prestazionePagamentoModel,
  baseTipoPrestazioneModel,
  baseScopoPrestazioneModel,
  baseNaturaPrestazioneModel,
  baseOrigineFondiModel,
  basePaeseModel,
  baseProvinciaModel,
  baseComuneModel,
  baseMezzoPagamentoModel,
  baseValutaModel,
  userModel,
  anagraficaModel,
  baseTipoAnagraficaModel,
} from "../../businessLogic/models";

import {
  query_users_byCompanyId,
  query_base_all,
  query_base_app_setting,
  query_base_tipo_prestazione,
  query_base_comune,
  query_base_valuta,
  query_prestazioni_byFascicolo,
  query_anagrafiche_byFascicolo,
  query_base_valuta_byISO,
} from "../../businessLogic/query";

import {
  mutation_add_log,
  mutation_add_audit,
  mutation_add_prestazione,
  mutation_update_prestazione,
  mutation_delete_prestazione,
  mutation_add_prestazione_users,
  mutation_delete_prestazione_user,
  mutation_add_prestazione_pagamento,
  mutation_update_prestazione_pagamento,
  mutation_delete_prestazione_pagamento,
  mutation_update_fascicolo,
} from "../../businessLogic/mutation";

//Other components
import { v4 as uuidv4 } from "uuid";
import AutoNumeric from "autonumeric";
//#endregion

export const PrestazioneForm = ({
  fascicolo,
  onRischio,
  reloadStatusFascicolo,
}) => {
  const tenantId = sharedDatas.getPropertyByName("tenantId");
  const aziendaId = sharedDatas.getPropertyByName("aziendaId");
  const loggedUserId = sharedDatas.getPropertyByName("userId");
  const version = sharedDatas.getPropertyByName("version")
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const prestFromNavigation = getLocalStorageSelectedEntity();

  //#region Query
  const apiUsersByCompanyId = useQuery(
    query_users_byCompanyId,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );

  const apiBaseAll = useQuery(
    query_base_all,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );

  const apiBaseAppSetting = useQuery(
    query_base_app_setting,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseTipoPrestazione = useQuery(
    query_base_tipo_prestazione,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseComune = useQuery(
    query_base_comune,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseValuta = useQuery(
    query_base_valuta,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseValutaByIso = useQuery(
    query_base_valuta_byISO,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiPrestazioniByFascicolo = useQuery(
    query_prestazioni_byFascicolo,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAnagraficaByFascicolo = useQuery(
    query_anagrafiche_byFascicolo,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  //#endregion

  //#region Mutation
  const apiAddLog = useMutation(mutation_add_log, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddAudit = useMutation(mutation_add_audit, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddPrestazione = useMutation(mutation_add_prestazione, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiUpdatePrestazione = useMutation(mutation_update_prestazione, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiDeletePrestazione = useMutation(mutation_delete_prestazione, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddPrestazioneUsers = useMutation(mutation_add_prestazione_users, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiDeletePrestazioneUser = useMutation(
    mutation_delete_prestazione_user,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAddPrestazionePagamento = useMutation(
    mutation_add_prestazione_pagamento,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiUpdatePrestazionePagamento = useMutation(
    mutation_update_prestazione_pagamento,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiDeletePrestazionePagamento = useMutation(
    mutation_delete_prestazione_pagamento,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiUpdateFascicolo = useMutation(mutation_update_fascicolo, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  //#endregion

  //#region UseStates
  const [backDropOpen, setBackDropOpen] = useState(true); //Loader utilizzato durante il caricamento
  const [isDataLoaded, setIsDataLoaded] = useState(false); //Indica lo stato di caricamento del result Api
  const [userPermissions, setUserPermissions] = useState(
    new userPermissionModel(
      0,
      aziendaId,
      bsaPrestazione,
      false,
      false,
      false,
      false
    )
  );

  //Anagrafica cliente
  const [anagraficaFascicolo, setAnagraficaFascicolo] = useState();
  const [anagIdentDate, setAnagIdentDate] = useState(null);
  //Prestazione
  const [showPrestazioneForm, setShowPrestazioneForm] = useState(false);
  const [currentPrestazioniList, setCurrentPrestazioniList] = useState([]); //Contiene le prestazioni correnti
  const [currentPrestazione, setCurrentPrestazione] = useState(
    new prestazioneModel(0, "N")
  ); //Contiene la prestazione corrente
  const [currentPrestazioneOriginal, setCurrentPrestazioneOriginal] = useState(
    new prestazioneModel(0, "N")
  ); //Used for Audit
  const [currentPrestazioneTipoKeyS, setCurrentPrestazioneTipoKeyS] =
    useState("Normale"); //N: Normale, M: Multipla, F: Frazionata
  const [isPaeseIta, setIsPaeseIta] = useState(true);
  const [currentPrestazioneChbVal, setCurrentPrestazioneChbVal] = useState({
    isPrivate: false,
    isEsoneroObbAst: false,
  });

  //Mezzi di pagamento
  const [currentPrestazionePagamentiList, setCurrentPrestazionePagamentiList] =
    useState([]);
  const [currentPrestazionePagamento, setCurrentPrestazionePagamento] =
    useState(
      new prestazionePagamentoModel(
        0,
        undefined,
        null,
        0,
        0,
        0,
        undefined,
        undefined,
        null,
        null,
        "",
        "",
        "",
        new Date(),
        loggedUserId
      )
    ); //Contiene i pagamenti relativi alla prestazione corrente
  const [
    currentPrestazionePagamentoOriginal,
    setCurrentPrestazionePagamentoOriginal,
  ] = useState(
    new prestazionePagamentoModel(
      0,
      undefined,
      null,
      0,
      0,
      0,
      undefined,
      undefined,
      null,
      null,
      "",
      "",
      "",
      new Date(),
      loggedUserId
    )
  ); //Used for Audit
  const [pagamentoImportoEuro, setPagamentoImportoEuro] = useState(0);
  const [sogliaPagamentoContanti, setSogliaPagamentoContanti] = useState(0);

  //Professionisti
  const [utentiList, setUtentiList] = useState([]);

  //Tabelle di base
  const [baseTipoPrestazioneList, setBaseTipoPrestazioneList] = useState([]);
  const [baseScopoPrestazioneList, setBaseScopoPrestazioneList] = useState([]);
  const [baseNaturaPrestazioneList, setBaseNaturaPrestazioneList] = useState(
    []
  );
  const [baseOrigineFondiList, setBaseOrigineFondiList] = useState([]);
  const [basePaeseList, setBasePaeseList] = useState([]);
  const [baseProvinciaList, setBaseProvinciaList] = useState([]);
  const [baseComuneList, setBaseComuneList] = useState([]);
  const [baseValutaList, setBaseValutaList] = useState([]);
  const [valutaByISO,setValutaByIso] = useState([])
  const [baseMezzoPagamentoList, setBaseMezzoPagamentoList] = useState([]);

  const [showDeleteModal, setShowDeleteModal] = useState(false); //Modal per la conferma di eliminazione di un record
  const [idToPerform, setIdToPerform] = useState(0); //Contiene l'id del record da eliminare
  const [typeToPerform, setTypeToPerform] = useState("P"); //Contiene il tipo di record da eliminare. P: Prestazione, MP: Mezzo di pagamento

  //Controlli form
  const [canIshowMezziPag, setCanIShowMezziPag] = useState(
    currentPrestazionePagamento.id !== 0
  );
  const [showMezziPag, setShowMezziPag] = useState(false);
  const [isValutaEstera, setIsValutaEstera] = useState(false);
  const [valutaCambio, setValutaCambio] = useState(0);
  //#endregion

  const tsApiAntiRic = useGet("aml:ts-anti-ric:api://", { lazy: true });

  //Autonumeric
  const txtImportoRef = useRef();
  // eslint-disable-next-line no-unused-vars
  let txtImportoAutoNum = null;
  useEffect(() => {
    if (txtImportoRef.current !== undefined && txtImportoRef.current !== null) {
      const txtImportoEl = txtImportoRef.current.children[1].children[0];
      // eslint-disable-next-line react-hooks/exhaustive-deps
      txtImportoAutoNum = new AutoNumeric(txtImportoEl, {
        decimalCharacter: ",",
        digitGroupSeparator: ".",
        decimalPlaces: 2,
      });
    }
  });

  useEffect(() => {
    loadDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataLoaded]);

  //#region Load methods
  async function loadDatas() {
    try {
      if (!isDataLoaded) {
        setBackDropOpen(true);

        /**************************************/
        //Carico i permessi relativi all'utente
        setUserPermissions(
          sharedDatas
            .getPropertyByName("userPermissions")
            .filter((x) => x.area === bsaPrestazione)[0]
        );

        /**************************************/
        //Carico il Tenant Type
        var tenantTypeId =
          sharedDatas.getPropertyByName("userModel").tenantTipoId;

        //Carico le tabelle di base
        //base_app_setting
        const appSettResponse = await apiBaseAppSetting.fetch({
          key: "soglia_pagamento_contanti",
        });
        if (appSettResponse.data.data.base_app_setting.length > 0) {
          setSogliaPagamentoContanti(
            parseInt(appSettResponse.data.data.base_app_setting[0].Value)
          );
        }

        const tbBaseAllResponse = await apiBaseAll.fetch({
          tenantId: tenantId,
        });
        await loadBaseTipoPrestazione(tenantTypeId);

        //base_scopo_prestazione
        const scopoPresItems = [];
        tbBaseAllResponse.data.data.base_scopo_prestazione.map((item) =>
          scopoPresItems.push(
            new baseScopoPrestazioneModel(
              item.Id,
              item.Key,
              item.Value,
              item.Order,
              item.IsSystem
            )
          )
        );
        setBaseScopoPrestazioneList(scopoPresItems);

        //base_natura_prestazione
        const naturaPrestItems = [];
        tbBaseAllResponse.data.data.base_natura_prestazione.map((item) =>
          naturaPrestItems.push(
            new baseNaturaPrestazioneModel(
              item.Id,
              item.Key,
              item.Value,
              item.Order,
              item.IsSystem
            )
          )
        );
        setBaseNaturaPrestazioneList(naturaPrestItems);

        //base_origine_fondi
        const origFondItems = [];
        tbBaseAllResponse.data.data.base_origine_fondi.map((item) =>
          origFondItems.push(
            new baseOrigineFondiModel(
              item.Id,
              item.Key,
              item.Value,
              item.Order,
              item.IsSystem
            )
          )
        );
        setBaseOrigineFondiList(origFondItems);

        //base_paese
        const paeseItems = [];
        tbBaseAllResponse.data.data.base_paese.map((item) =>
          paeseItems.push(
            new basePaeseModel(
              item.Id,
              item.Nome,
              item.Identificativo,
              item.IsRischio
            )
          )
        );
        setBasePaeseList(paeseItems);

        //base_provincia
        const provItems = [];
        tbBaseAllResponse.data.data.base_provincia.map((item) =>
          provItems.push(
            new baseProvinciaModel(item.Id, item.Nome, item.Sigla, item.Rischio)
          )
        );
        setBaseProvinciaList(provItems);

        //base_valuta
        const valutaResponse = await apiBaseValuta.fetch();
        const valutaItems = [];
        valutaResponse.data.data.base_valuta.map((item) =>
          valutaItems.push(
            new baseValutaModel(
              item.Id,
              item.Country,
              item.Currency,
              item.IsoCode,
              item.UicCode,
              item.EurRate,
              item.UsdRate,
              item.RefDate
            )
          )
        );

        /*base_tipo_anagrafica*/
        const arrTipoAnagItems = [];
        tbBaseAllResponse.data.data.base_tipo_anagrafica.map((item) =>
          arrTipoAnagItems.push(
            new baseTipoAnagraficaModel(
              item.Id,
              item.Key,
              item.Value,
              item.Order,
              item.IsSystem
            )
          )
        );

        //Ricostruisco l'array rimuovendo i duplicati e indicando l'euro come prima voce
        const valutaItemsCleared = [];

        //L'euro va come prima voce
        const euro = valutaItems.filter((item) => item.isoCode === "EUR")[0];
        valutaItemsCleared.push(euro);

        valutaItems.forEach((item, index) => {
          if (item.isoCode !== euro.isoCode) {
            const curItem = valutaItemsCleared.filter(
              (currency) => currency.isoCode === item.isoCode
            );
            if (curItem.length <= 0) {
              valutaItemsCleared.push(item);
            }
          }
        });

        setBaseValutaList(valutaItemsCleared);

        //base_mezzo_pagamento
        const mezzoPagItems = [];
        tbBaseAllResponse.data.data.base_mezzo_pagamento.map((item) =>
          mezzoPagItems.push(
            new baseMezzoPagamentoModel(
              item.Id,
              item.Key,
              item.Value,
              item.Order,
              item.IsSystem
            )
          )
        );
        setBaseMezzoPagamentoList(mezzoPagItems);

        /**************************************/
        //Carico gli utenti
        const usersResponse = await apiUsersByCompanyId.fetch({
          tenantId: tenantId,
          aziendaId: aziendaId,
        });
        const arrUsers = [];
        usersResponse.data.data.user_tenant.map((item) =>
          arrUsers.push(
            new userModel(
              item.UserId,
              item.TenantId,
              item.user.Username,
              item.user.Email,
              item.user.Name,
              item.user.Surname,
              item.user.Name + " " + item.user.Surname,
              item.Type,
              item.user.Locale,
              item.TenantTipoId,
              item.user.IsRespAntiRic,
              item.base_tipo_tenant.Value,
              item.user.IsActive,
              item.user.IsDeleted,
              item.user.IsBanned,
              item.user.CreatedOn,
              item.user.Cf
            )
          )
        );
        setUtentiList(arrUsers);

        //gli utenti originali li confronto con i professionisti indicati nel fascicolo
        //così da presentare l'elenco di questi ultimi
        const newArrUsers = arrUsers;

        //prestazioni
        if (fascicolo.id > 0) {
          const prestFascResponse = await apiPrestazioniByFascicolo.fetch({
            fascicoloId: fascicolo.id,
          });
          if (prestFascResponse.data.errors !== undefined) {
            enqueueSnackbar(t("error.generale"), { variant: "error" });
          } else {
            const arrItems = [];
            prestFascResponse.data.data.prestazione.forEach((item) => {
              let itemModel = new prestazioneModel(
                item.Id,
                item.TipoKey,
                item.PrestazioneId,
                item.base_tipo_prestazione.Nome,
                item.base_tipo_prestazione.Rischio,
                item.base_tipo_prestazione.Regole,
                item.StartDate,
                item.EndDate,
                item.PaeseId,
                item.ProvinciaId,
                item.Provincia,
                item.CittaId,
                item.Citta,
                item.ScopoId,
                item.NaturaId,
                item.Descrizione,
                item.IsPrivate,
                item.CreatedOn,
                item.ModifiedOn,
                [],
                [],
                item.AstensioneMotivo,
                item.IsEsoneroObbAst,
                "",
                "",
                item.rischios,
                item.base_tipo_prestazione.BaseTenantTipoId,
                item.CreatedBy,
                item.ModifiedBy
              );

              const arrItemsUsers = [];
              item.prestazione_users.forEach((itemU) => {
                newArrUsers.forEach((item3) => {
                  if (itemU.UserId === item3.userId) {
                    arrItemsUsers.push(item3);
                  }
                });
              });

              const arrItemsPagamenti = [];
              item.prestazione_pagamentos.forEach((itemP) => {
                arrItemsPagamenti.push(
                  new prestazionePagamentoModel(
                    itemP.Id,
                    itemP.ValutaId,
                    itemP.ValutaData,
                    itemP.ValutaCambio,
                    itemP.ValutaImporto,
                    itemP.ImportoEuro,
                    itemP.ModalitaPagamentoId,
                    itemP.OrigineId,
                    itemP.CreatedOn,
                    itemP.ModifiedOn,
                    itemP.base_valutum.IsoCode,
                    itemP.base_mezzo_pagamento.Value,
                    itemP.base_origine_fondi.Value,
                    itemP.Data,
                    itemP.CreatedBy,
                    itemP.ModifiedBy
                  )
                );
              });

              itemModel.professionisti = arrItemsUsers;
              itemModel.pagamenti = arrItemsPagamenti;
              arrItems.push(itemModel);
            });

            setCurrentPrestazioniList(arrItems);

            const scopoItemId = scopoPresItems.filter(
              (x) => x.key === "base_scopo_prestazione_1"
            )[0].id;
            const naturaItemId = naturaPrestItems.filter(
              (x) => x.key === "base_natura_prestazione_3"
            )[0].id;
            let paeseId = paeseItems.filter(
              (x) => x.identificativo === "ITA"
            )[0].id;
            let provinciaId = undefined;
            let provincia = "";
            let cittaId = undefined;
            let citta = "";

            //Recupero l'anagrafica cliente del fascicolo per ottenere paese, provincia e città di default
            const anagResponse = await apiAnagraficaByFascicolo.fetch({
              fascicoloId: fascicolo.id,
            });
            if (anagResponse.data.errors !== undefined) {
            } else {
              for (
                var i = 0;
                i < anagResponse.data.data.anagrafica.length;
                i++
              ) {
                const item = anagResponse.data.data.anagrafica[i];

                let itemModel = new anagraficaModel(
                  item.Id,
                  item.ParentId,
                  item.IsPg,
                  item.Cf,
                  item.Nome,
                  item.Cognome,
                  item.GenereId,
                  item.NascitaLuogo,
                  item.NascitaData,
                  item.Professione,
                  item.Denominazione,
                  item.PIva,
                  item.ReaComune,
                  item.ReaN,
                  item.AddrPaeseId,
                  item.AddrProvinciaId,
                  item.AddrProvincia,
                  item.AddrComuneId,
                  item.AddrComune,
                  item.AddrIndirizzo,
                  item.AddrN,
                  item.AddrCap,
                  item.Addr2PaeseId,
                  item.Addr2ProvinciaId,
                  item.Addr2Provincia,
                  item.Addr2ComuneId,
                  item.Addr2Comune,
                  item.Addr2Indirizzo,
                  item.Addr2N,
                  item.Addr2Cap,
                  item.IsPep,
                  item.IsNotPepMoreThanYear,
                  item.VersionN,
                  item.VersionDate,
                  item.IsDeleted,
                  [],
                  [],
                  item.PepCarica,
                  item.CreatedBy,
                  item.ModifiedBy,
                  []
                );

                for (let n = 0; n < item.anagrafica_fascicolos.length; n++) {
                  const anagFasc = item.anagrafica_fascicolos[n];
                  const tipoAnagKey = arrTipoAnagItems.filter(
                    (bta) => bta.id === anagFasc.base_tipo_anagrafica.Id
                  )[0].key;
                  if (
                    tipoAnagKey === "tipo_anagrafica_1" ||
                    tipoAnagKey === "tipo_anagrafica_2"
                  ) {
                    setAnagraficaFascicolo(itemModel);

                    paeseId = itemModel.addrPaeseId;
                    provinciaId = itemModel.addrProvinciaId;
                    provincia = itemModel.addrProvincia;
                    cittaId = itemModel.addrComuneId;
                    citta = itemModel.addrComune;

                    if (paeseId !== null && paeseId > 0) {
                      const paese = paeseItems.filter(
                        // eslint-disable-next-line no-loop-func
                        (item) => item.id === paeseId
                      )[0];
                      if (paese.identificativo === "ITA") {
                        if (
                          provinciaId !== null &&
                          provinciaId !== undefined &&
                          provinciaId > 0
                        ) {
                          const provincia = provItems.filter(
                            // eslint-disable-next-line no-loop-func
                            (item) => item.id === provinciaId
                          )[0];
                          loadBaseComune(provincia.sigla);
                        }
                      }

                      setIsPaeseIta(paese.identificativo === "ITA");
                    }
                  }
                }
              }
            }

            const prestFromNavigationResult = await prestFromNavigation;
            let prestFromNavigationResultJson = null;
            if (prestFromNavigationResult !== null) {
              prestFromNavigationResultJson = JSON.parse(
                prestFromNavigationResult
              );
            }

            if (
              prestFromNavigationResultJson !== null &&
              prestFromNavigationResultJson.entityType === "prestazione"
            ) {
              const itemToSelect =
                arrItems.filter(
                  (x) => x.id === prestFromNavigationResultJson.entityId
                ).length > 0
                  ? arrItems.filter(
                      (x) => x.id === prestFromNavigationResultJson.entityId
                    )[0]
                  : null;
              await removeLocalStorageSelectedEntity();
              await selectPrestazione(
                itemToSelect,
                paeseItems,
                provItems,
                valutaItems
              );
            } else {
              const professionistiArr = [];
              professionistiArr.push(
                sharedDatas.getPropertyByName("userModel")
              );
              let anagCliente = null;
              anagResponse.data.data.anagrafica.forEach((anag) => {
                if (
                  anag.anagrafica_fascicolos.filter(
                    (af) => af.TipoAnagId === 1 || af.TipoAnagId === 2
                  ).length > 0
                )
                  anagCliente = anag;
              });
              const newItem = new prestazioneModel(
                0,
                "N",
                undefined,
                "",
                "",
                "",
                new Date(
                  anagCliente.anagrafica_identificaziones[0].IdentifiedOn
                ),
                null,
                paeseId,
                provinciaId,
                provincia,
                cittaId,
                citta,
                scopoItemId,
                naturaItemId,
                "",
                false,
                undefined,
                undefined,
                professionistiArr,
                [],
                "",
                false,
                "",
                "",
                [],
                0,
                loggedUserId
              );
              setCurrentPrestazione(newItem);
              setCurrentPrestazioneOriginal(newItem);
              setAnagIdentDate(
                anagCliente.anagrafica_identificaziones[0].IdentifiedOn
              );
            }
          }
        }
      }
    } catch (e) {
      AddLog(e.message,e.stack,"LoadDatas")      
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
      setIsDataLoaded(true);
    }
  }
  const loadBaseTipoPrestazione = async (tenantTypeId) => {
    try {
      let prefixPrest = "";
      if (tenantTypeId === 1) {
        prefixPrest = "COMM";
      } else if (tenantTypeId === 2) {
        prefixPrest = "CONS";
      } else if (tenantTypeId === 3) {
        prefixPrest = "AVV";
      } else if (tenantTypeId === 4) {
        prefixPrest = "NOT";
      }

      const tipoPrestResponse = await apiBaseTipoPrestazione.fetch({
        tenantType: tenantTypeId,
      });
      const tipoPrestItems = [];
      tipoPrestResponse.data.data.base_tipo_prestazione.map((item) =>
        tipoPrestItems.push(
          new baseTipoPrestazioneModel(
            item.Id,
            prefixPrest + " - " + item.Nome,
            item.Rischio,
            item.Regole
          )
        )
      );
      setBaseTipoPrestazioneList(tipoPrestItems);
    } catch (e) {
      AddLog(e.message + " TenantType: " + tenantTypeId,e.stack,"LoadBaseTipoPrestazione")      
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    }
  };
  const loadBaseComune = async (provincia) => {
    try {
      const comuneResponse = await apiBaseComune.fetch({
        provincia: provincia,
      });
      const arrItems = [];
      comuneResponse.data.data.base_comune.map((item) =>
        arrItems.push(
          new baseComuneModel(item.Id, item.Codice, item.Provincia, item.Nome)
        )
      );
      setBaseComuneList(arrItems);
    } catch (e) {
      AddLog(e.message + " Provincia: " + provincia,e.stack,"LoadBaseComune")      
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    }
  };
  //#endregion

  const AddLog = (message, stack, method) => {
    apiAddLog.fetch({
      tenantId: tenantId,
      aziendaId: aziendaId,
      level: "ERROR",
      message: message,
      stack: stack,
      area: "PrestazioneForm",
      method: method,
      version: version,
    });
  }  
  //Salvataggio degli inputs Prestazione
  const evaluateTipoPrestazione = (professionisti, pagamenti) => {
    let itemToReturn = "N";

    if (professionisti !== undefined) {
      if (professionisti.length === 1) {
        setCurrentPrestazioneTipoKeyS("Normale");
        itemToReturn = "N";
      } else if (professionisti.length > 1) {
        setCurrentPrestazioneTipoKeyS("Multipla");
        itemToReturn = "M";
      }
    } else {
      setCurrentPrestazioneTipoKeyS("Normale");
    }

    if (pagamenti !== undefined) {
      let totale = 0;

      pagamenti.forEach((item) => {
        totale = totale + item.importo;
      });
      if (pagamenti.length > 1) {
        setCurrentPrestazioneTipoKeyS("Frazionata");
        itemToReturn = "F";
      }
    }

    return itemToReturn;
  };

  //#region Handler
  const handleChange = (event, value) => {
    const { id } = event.target;

    if (id.indexOf("ddlPrestazione-option") !== -1 || id === "ddlModIdent") {
      currentPrestazione.prestazioneId = value.id;
    } else if (
      id.indexOf("ddlUtenti-option") !== -1 ||
      id === "ddlUtenti" ||
      id === "chbDdlUtenti"
    ) {
      currentPrestazione.professionisti = value;
    } else if (id.indexOf("ddlScopo-option") !== -1 || id === "ddlScopo") {
      currentPrestazione.scopoId = value.id;
    } else if (id.indexOf("ddlNatura-option") !== -1 || id === "ddlNatura") {
      currentPrestazione.naturaId = value.id;
    } else if (id.indexOf("ddlPaese-option") !== -1 || id === "ddlPaese") {
      currentPrestazione.paeseId = value.id;

      //Recupero le province
      var country = basePaeseList.filter(
        (item) => item.id === currentPrestazione.paeseId
      )[0];
      if (country.identificativo === "ITA") {
        currentPrestazione.provincia = "";
        currentPrestazione.citta = "";
      } else {
        currentPrestazione.provinciaId = null;
        currentPrestazione.cittaId = null;
      }

      setIsPaeseIta(country.identificativo === "ITA");

      //Visualizzo il rischio
      if (country.isRischio) {
        enqueueSnackbar(t("anagrafica.message.paeseRischio"), {
          variant: "error",
        });
      }
    } else if (
      id.indexOf("ddlProvincia-option") !== -1 ||
      id === "ddlProvincia"
    ) {
      currentPrestazione.provinciaId = value.id;

      //Recupero le città
      var provincia = baseProvinciaList.filter(
        (item) => item.id === currentPrestazione.provinciaId
      )[0];
      loadBaseComune(provincia.sigla);

      //Visualizzo il rischio
      switch (provincia.rischio) {
        case 1: {
          enqueueSnackbar(t("anagrafica.message.provinciaRischio1"), {
            variant: "success",
          });
          break;
        }
        case 2: {
          enqueueSnackbar(t("anagrafica.message.provinciaRischio2"), {
            variant: "warning",
          });
          break;
        }
        case 3: {
          enqueueSnackbar(t("anagrafica.message.provinciaRischio3"), {
            variant: "warning",
          });
          break;
        }
        case 4: {
          enqueueSnackbar(t("anagrafica.message.provinciaRischio4"), {
            variant: "error",
          });
          break;
        }
        default: {
          break;
        }
      }
    } else if (id === "txtProvincia") {
      currentPrestazione.provinciaId = null;
      currentPrestazione.provincia = event.target.value;
    } else if (id.indexOf("ddlComune-option") !== -1 || id === "ddlComune") {
      currentPrestazione.cittaId = value.id;
    } else if (id === "txtComune") {
      currentPrestazione.cittaId = null;
      currentPrestazione.citta = event.target.value;
    } else if (id === "txtDescrizione") {
      currentPrestazione.descrizione = event.target.value;
    } else if (id === "chkPrivate") {
      currentPrestazione.isPrivate = event.target.checked;
      setCurrentPrestazioneChbVal({ isPrivate: event.target.checked });
    } else if (id === "chkAstIsEsonero") {
      currentPrestazione.isEsoneroObbAst = event.target.checked;
      if (event.target.checked === false) {
        currentPrestazione.astensioneMotivo = "";
      }
      setCurrentPrestazioneChbVal({ isEsoneroObbAst: event.target.checked });
    } else if (id === "txtAstMotivo") {
      currentPrestazione.astensioneMotivo = event.target.value;
    }

    currentPrestazione.tipoKey = evaluateTipoPrestazione(
      currentPrestazione.professionisti,
      currentPrestazione.pagamenti
    );

    setCurrentPrestazione(currentPrestazione);
  };
  const handleStartDate = (date) => {
    if (date !== null && isDateValid(date)) {
      const newDate = new Date(date);
      currentPrestazione.dataStart = newDate;
      setCurrentPrestazione((prevState) => ({
        ...prevState,
        dataStart: newDate,
      }));

      if (currentPrestazionePagamento.id === 0) {
        setCurrentPrestazionePagamento((prevState) => ({
          ...prevState,
          data: newDate,
        }));
      }

      const now = new Date();
      const msBetweenDates = Math.abs(date.getTime() - now.getTime());
      const daysBetweenDates = msBetweenDates / (24 * 60 * 60 * 1000);
      if (daysBetweenDates >= 30) {
        enqueueSnackbar(t("prestazione.error.dateLessThan30Days"), {
          variant: "warning",
        });
      }
    }
  };
  const handleEndDate = (date) => {
    if (date !== null && isDateValid(date)) {
      if (
        date.setHours(0, 0, 0, 0) >=
        new Date(currentPrestazione.dataStart).setHours(0, 0, 0, 0)
      ) {
        currentPrestazione.dataEnd = date;
        setCurrentPrestazione((prevState) => ({ ...prevState, dataEnd: date }));
      } else {
        enqueueSnackbar(
          "La data della cessazione deve essere superiore alla data di inizio",
          { variant: "error" }
        );
      }
    } else {
      currentPrestazione.dataEnd = null;
      setCurrentPrestazione((prevState) => ({ ...prevState, dataEnd: date }));
    }
  };
  //#endregion

  //CRUD della prestazione
  const emptyPrestazioneModel = () => {
    const scopoItemId = baseScopoPrestazioneList.filter(
      (x) => x.key === "base_scopo_prestazione_1"
    )[0].id;
    const naturaItemId = baseNaturaPrestazioneList.filter(
      (x) => x.key === "base_natura_prestazione_3"
    )[0].id;
    let paeseId = basePaeseList.filter((x) => x.identificativo === "ITA")[0].id;

    let provinciaId = undefined;
    let provincia = "";
    let cittaId = undefined;
    let citta = "";

    try {
      paeseId = anagraficaFascicolo.addrPaeseId;
      provinciaId = anagraficaFascicolo.addrProvinciaId;
      provincia = anagraficaFascicolo.addrProvincia;
      cittaId = anagraficaFascicolo.addrComuneId;
      citta = anagraficaFascicolo.addrComune;

      if (paeseId !== null) {
        const paese = basePaeseList.filter((item) => item.id === paeseId)[0];
        if (paese.identificativo === "ITA") {
          if (
            provinciaId !== null &&
            provinciaId !== undefined &&
            provinciaId > 0
          ) {
            const provincia = baseProvinciaList.filter(
              (item) => item.id === provinciaId
            )[0];
            loadBaseComune(provincia.sigla);
          }
        }

        setIsPaeseIta(paese.identificativo === "ITA");
      }
    } catch (e) {
      AddLog(e.message,e.stack,"EmptyPrestazioneModel")      
    }

    const professionistiArr = [];
    professionistiArr.push(sharedDatas.getPropertyByName("userModel"));

    const newItem = new prestazioneModel(
      0,
      "N",
      undefined,
      "",
      "",
      "",
      new Date(anagIdentDate),
      null,
      paeseId,
      provinciaId,
      provincia,
      cittaId,
      citta,
      scopoItemId,
      naturaItemId,
      "",
      false,
      undefined,
      undefined,
      professionistiArr,
      [],
      "",
      false,
      "",
      "",
      [],
      0,
      loggedUserId
    );
    return newItem;
  };

  //#region Handlers
  const handleAddPrestClick = async () => {
    setCurrentPrestazione(emptyPrestazioneModel());
    setCurrentPrestazioneOriginal(emptyPrestazioneModel());
    setCurrentPrestazioneTipoKeyS("Normale");
    setCurrentPrestazioneChbVal({ isPrivate: false, isEsoneroObbAst: false });
    setCurrentPrestazionePagamento(emptyPagamentoModel(null, baseValutaList));
    setCurrentPrestazionePagamentoOriginal(
      emptyPagamentoModel(null, baseValutaList)
    );
    setCurrentPrestazionePagamentiList([]);
    setShowMezziPag(false);
    setCanIShowMezziPag(false);
    setShowPrestazioneForm(true);

    var tenantTypeId = sharedDatas.getPropertyByName("userModel").tenantTipoId;
    await loadBaseTipoPrestazione(tenantTypeId);
  };
  const handleCancelClick = async () => {
    setCurrentPrestazione(emptyPrestazioneModel());
    setCurrentPrestazioneOriginal(emptyPrestazioneModel());
    setCurrentPrestazioneTipoKeyS("Normale");
    setCurrentPrestazioneChbVal({ isPrivate: false, isEsoneroObbAst: false });
    setCurrentPrestazionePagamento(emptyPagamentoModel(null, baseValutaList));
    setCurrentPrestazionePagamentoOriginal(
      emptyPagamentoModel(null, baseValutaList)
    );
    setCurrentPrestazionePagamentiList([]);
    setShowMezziPag(false);
    setCanIShowMezziPag(false);
    setShowPrestazioneForm(false);

    var tenantTypeId = sharedDatas.getPropertyByName("userModel").tenantTipoId;
    await loadBaseTipoPrestazione(tenantTypeId);
  };
  const handleSelectedPrestClick = async (prestazione) => {
    try {
      await selectPrestazione(
        prestazione,
        basePaeseList,
        baseProvinciaList,
        baseValutaList
      );
    } catch (e) {
      AddLog(e.message + " SelectedPrestazione: " + prestazione,e.stack,"HandleSelectPrestClick")
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
    }
  };
  const selectPrestazione = async (prestazione, paesi, province, valute) => {
    try {
      setBackDropOpen(true);
      setCurrentPrestazione(prestazione);
      setCurrentPrestazioneOriginal(prestazione);

      setCurrentPrestazionePagamento(emptyPagamentoModel(prestazione, valute));

      if (prestazione.paeseId !== null) {
        const paese = paesi.filter(
          (item) => item.id === prestazione.paeseId
        )[0];
        if (paese.identificativo === "ITA") {
          if (
            prestazione.provinciaId !== null &&
            prestazione.provinciaId !== undefined &&
            prestazione.provinciaId > 0
          ) {
            const provincia = province.filter(
              (item) => item.id === prestazione.provinciaId
            )[0];
            loadBaseComune(provincia.sigla);
          }
        }

        setIsPaeseIta(paese.identificativo === "ITA");
      }

      evaluateTipoPrestazione(
        prestazione.professionisti,
        prestazione.pagamenti
      );
      setCurrentPrestazionePagamentiList(prestazione.pagamenti);
      setCurrentPrestazioneChbVal({
        isPrivate: prestazione.isPrivate,
        isEsoneroObbAst: prestazione.isEsoneroObbAst,
      });
      setCanIShowMezziPag(true);
      setShowMezziPag(prestazione.pagamenti.length > 0);
      setShowPrestazioneForm(true);
      await loadBaseTipoPrestazione(prestazione.tipoTenantId);
    } catch (e) {
      AddLog(e.message + " Prestazione: "+ prestazione, e.stack,"SelectPrestazione")
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
    }
  };
  const handleRischioPrestClick = (prestazione) => {
    onRischio(prestazione);
  };
  const handleDeletePrestClick = (prestazione) => {
    setIdToPerform(prestazione.id);
    setTypeToPerform("P");
    setShowDeleteModal(true);
  };
  const handleSaveClick = async () => {
    try {
      if (
        currentPrestazione.prestazioneId !== undefined &&
        currentPrestazione.prestazioneId !== null &&
        currentPrestazione.professionisti !== undefined &&
        currentPrestazione.professionisti.length > 0 &&
        currentPrestazione.dataStart !== undefined &&
        currentPrestazione.dataStart !== null &&
        currentPrestazione.scopoId !== undefined &&
        currentPrestazione.scopoId !== null &&
        currentPrestazione.naturaId !== undefined &&
        currentPrestazione.naturaId !== null &&
        currentPrestazione.paeseId !== undefined &&
        currentPrestazione.paeseId !== null
      ) {
        setBackDropOpen(true);

        //Se sto creando la prestazione
        if (currentPrestazione.id === 0) {
          const newDateStartD = new Date(currentPrestazione.dataStart);
          const newDateStart = new Date(
            Date.UTC(
              newDateStartD.getFullYear(),
              newDateStartD.getMonth(),
              newDateStartD.getDate(),
              newDateStartD.getHours(),
              newDateStartD.getMinutes(),
              newDateStartD.getSeconds()
            )
          );

          let newDateEnd = null;
          if (currentPrestazione.dataEnd !== null) {
            const newDateEndD = new Date(currentPrestazione.dataEnd);
            newDateEnd = new Date(
              Date.UTC(
                newDateEndD.getFullYear(),
                newDateEndD.getMonth(),
                newDateEndD.getDate(),
                newDateEndD.getHours(),
                newDateEndD.getMinutes(),
                newDateEndD.getSeconds()
              )
            );
          }

          const addPresResponse = await apiAddPrestazione.fetch({
            fascicoloId: fascicolo.id,
            tipoKey: currentPrestazione.tipoKey,
            prestazioneId: currentPrestazione.prestazioneId,
            naturaId: currentPrestazione.naturaId,
            scopoId: currentPrestazione.scopoId,
            startDate: newDateStart,
            endDate: newDateEnd,
            paeseId: currentPrestazione.paeseId,
            provinciaId: currentPrestazione.provinciaId,
            provincia: currentPrestazione.provincia,
            cittaId: currentPrestazione.cittaId,
            citta: currentPrestazione.citta,
            descrizione: currentPrestazione.descrizione,
            isPrivate: currentPrestazione.isPrivate,
            astensioneMotivo: currentPrestazione.astensioneMotivo,
            isEsoneroObbAst: currentPrestazione.isEsoneroObbAst,
          });
          if (addPresResponse.data.errors !== undefined) {
            enqueueSnackbar(t("error.generale"), { variant: "error" });
          } else {
            const result = addPresResponse.data.data.insert_prestazione_one;
            let prestazioneResultModel = new prestazioneModel(
              result.Id,
              result.TipoKey,
              result.PrestazioneId,
              result.base_tipo_prestazione.Nome,
              result.base_tipo_prestazione.Rischio,
              result.base_tipo_prestazione.Regole,
              result.StartDate,
              result.EndDate,
              result.PaeseId,
              result.ProvinciaId,
              result.Provincia,
              result.CittaId,
              result.Citta,
              result.ScopoId,
              result.NaturaId,
              result.Descrizione,
              result.IsPrivate,
              result.CreatedOn,
              result.ModifiedOn,
              [],
              [],
              result.AstensioneMotivo,
              result.IsEsoneroObbAst,
              "",
              "",
              result.rischios,
              result.base_tipo_prestazione.BaseTenantTipoId,
              result.CreatedBy,
              result.ModifiedBy
            );

            //Aggiungo la Audit per tracciare l'azione
            const newValues = prestazioneResultModel;
            apiAddAudit.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
              area: bsaPrestazione,
              operation: "Create",
              entityType: "prestazione",
              entityId: result.Id.toString(),
              oldValues: "",
              newValues: JSON.stringify(newValues),
            });

            //Aggiungo i professionisti appartenenti alla prestazione
            let professionisti = [];
            currentPrestazione.professionisti.map((item) =>
              professionisti.push({
                PrestazioneId: prestazioneResultModel.id,
                UserId: item.userId,
              })
            );

            const addPresUsersResponse = await apiAddPrestazioneUsers.fetch({
              objects: professionisti,
            });
            if (addPresUsersResponse.data.errors !== undefined) {
              enqueueSnackbar(t("error.generale"), { variant: "error" });
            } else {
              //Aggiorno l'elenco delle prestazioni
              const arrItemsUsers = [];
              professionisti.forEach((itemU) => {
                utentiList.forEach((item2) => {
                  if (itemU.UserId === item2.userId) {
                    arrItemsUsers.push(item2);
                  }
                });
              });
              prestazioneResultModel.professionisti = arrItemsUsers;

              let prestazioni = Object.assign([], currentPrestazioniList);
              prestazioni.push(prestazioneResultModel);
              prestazioni = prestazioni.sort(
                (a, b) =>
                  new Date(...b.dataStart.split("/").reverse()) -
                  new Date(...a.dataStart.split("/").reverse())
              );
              setCurrentPrestazioniList(prestazioni);
              setCurrentPrestazione(prestazioneResultModel);
              setCurrentPrestazioneOriginal(prestazioneResultModel);
              setCurrentPrestazionePagamento(
                emptyPagamentoModel(prestazioneResultModel, baseValutaList)
              );
              setCurrentPrestazionePagamentoOriginal(
                emptyPagamentoModel(prestazioneResultModel, baseValutaList)
              );
              setCurrentPrestazionePagamentiList([]);
              setCanIShowMezziPag(true);

              enqueueSnackbar(t("message.success"), { variant: "success" });
            }
          }
        }

        //Se la sto modificando
        else {
          const newDateStartD = new Date(currentPrestazione.dataStart);
          const newDateStart = new Date(
            Date.UTC(
              newDateStartD.getFullYear(),
              newDateStartD.getMonth(),
              newDateStartD.getDate(),
              newDateStartD.getHours(),
              newDateStartD.getMinutes(),
              newDateStartD.getSeconds()
            )
          );

          let newDateEnd = null;
          if (currentPrestazione.dataEnd !== null) {
            const newDateEndD = new Date(currentPrestazione.dataEnd);
            newDateEnd = new Date(
              Date.UTC(
                newDateEndD.getFullYear(),
                newDateEndD.getMonth(),
                newDateEndD.getDate(),
                newDateEndD.getHours(),
                newDateEndD.getMinutes(),
                newDateEndD.getSeconds()
              )
            );
          }

          const updatePresResponse = await apiUpdatePrestazione.fetch({
            id: currentPrestazione.id,
            tipoKey: currentPrestazione.tipoKey,
            prestazioneId: currentPrestazione.prestazioneId,
            naturaId: currentPrestazione.naturaId,
            scopoId: currentPrestazione.scopoId,
            startDate: newDateStart,
            endDate: newDateEnd,
            paeseId: currentPrestazione.paeseId,
            provinciaId: currentPrestazione.provinciaId,
            provincia: currentPrestazione.provincia,
            cittaId: currentPrestazione.cittaId,
            citta: currentPrestazione.citta,
            descrizione: currentPrestazione.descrizione,
            isPrivate: currentPrestazione.isPrivate,
            astensioneMotivo: currentPrestazione.astensioneMotivo,
            isEsoneroObbAst: currentPrestazione.isEsoneroObbAst,
          });
          if (updatePresResponse.data.errors !== undefined) {
            enqueueSnackbar(t("error.generale"), { variant: "error" });
          } else {
            const result =
              updatePresResponse.data.data.update_prestazione.returning[0];
            let prestazioneResultModel = new prestazioneModel(
              result.Id,
              result.TipoKey,
              result.PrestazioneId,
              result.base_tipo_prestazione.Nome,
              result.base_tipo_prestazione.Rischio,
              result.base_tipo_prestazione.Regole,
              result.StartDate,
              result.EndDate,
              result.PaeseId,
              result.ProvinciaId,
              result.Provincia,
              result.CittaId,
              result.Citta,
              result.ScopoId,
              result.NaturaId,
              result.Descrizione,
              result.IsPrivate,
              result.CreatedOn,
              result.ModifiedOn,
              [],
              [],
              result.AstensioneMotivo,
              result.IsEsoneroObbAst,
              "",
              "",
              result.rischios,
              result.base_tipo_prestazione.BaseTenantTipoId,
              result.CreatedBy,
              result.ModifiedBy
            );

            //Aggiungo la Audit per tracciare l'azione
            const newValues = prestazioneResultModel;
            apiAddAudit.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
              area: bsaPrestazione,
              operation: "Update",
              entityType: "prestazione",
              entityId: currentPrestazione.id.toString(),
              oldValues: JSON.stringify(currentPrestazioneOriginal),
              newValues: JSON.stringify(newValues),
            });

            //Rimuovo i professionisti appartenenti al fascicolo
            const delPresUsersResponse = await apiDeletePrestazioneUser.fetch({
              prestazioneId: currentPrestazione.id,
            });
            if (delPresUsersResponse.data.errors !== undefined) {
              enqueueSnackbar(t("error.generale"), { variant: "error" });
            } else {
              //e li aggiungo di nuovo
              let professionisti = [];
              currentPrestazione.professionisti.map((item) =>
                professionisti.push({
                  PrestazioneId: currentPrestazione.id,
                  UserId: item.userId,
                })
              );

              const addPresUsersResponse = await apiAddPrestazioneUsers.fetch({
                objects: professionisti,
              });
              if (addPresUsersResponse.data.errors !== undefined) {
                enqueueSnackbar(t("error.generale"), { variant: "error" });
              } else {
                //Aggiorno l'elenco delle prestazioni
                const arrItemsUsers = [];
                professionisti.forEach((itemU) => {
                  utentiList.forEach((item2) => {
                    if (itemU.UserId === item2.userId) {
                      arrItemsUsers.push(item2);
                    }
                  });
                });
                prestazioneResultModel.professionisti = arrItemsUsers;
                prestazioneResultModel.pagamenti = currentPrestazione.pagamenti;

                let prestazioni = Object.assign([], currentPrestazioniList);
                const index = prestazioni.findIndex(
                  (item) => item.id === prestazioneResultModel.id
                );
                prestazioni[index] = prestazioneResultModel;
                prestazioni = prestazioni.sort(
                  (a, b) =>
                    new Date(...b.dataStart.split("/").reverse()) -
                    new Date(...a.dataStart.split("/").reverse())
                );

                setCurrentPrestazioniList(prestazioni);
                setCurrentPrestazione(prestazioneResultModel);
                setCurrentPrestazioneOriginal(prestazioneResultModel);
                setCurrentPrestazionePagamento(
                  emptyPagamentoModel(prestazioneResultModel, baseValutaList)
                );
                setCurrentPrestazionePagamentoOriginal(
                  emptyPagamentoModel(prestazioneResultModel, baseValutaList)
                );

                enqueueSnackbar(t("message.success"), { variant: "success" });
              }
            }

            setCurrentPrestazioneOriginal(currentPrestazione);
            setCanIShowMezziPag(true);
          }
        }

        //Aggiorno la data di ultima modifica del fascicolo
        await apiUpdateFascicolo.fetch({
          id: fascicolo.id,
          name: fascicolo.name,
          date: fascicolo.date,
          isPrivate: fascicolo.isPrivate,
        });
      } else {
        enqueueSnackbar(t("error.missingFields"), { variant: "error" });
      }
    } catch (e) {
      AddLog(e.message + "Prestazione: "+ currentPrestazione,e.stack,"HandleSaveClick")      
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
      reloadStatusFascicolo();
    }
  };

  //Salvataggio degli inputs Mezzi di pagamento
  const handlePagamentoDate = (date) => {
    if (date !== null && isDateValid(date)) {
      const newDate = new Date(date);
      currentPrestazionePagamento.data = newDate;
      setCurrentPrestazionePagamento((prevState) => ({
        ...prevState,
        data: newDate,
      }));
    }
  };
  const evaluateImporto = (tassoCambio) => {
    if (
      tassoCambio !== undefined &&
      tassoCambio > 0
    ) {
      let importo = currentPrestazionePagamento.valutaImporto;

      //Se la valuta è EUR non effettuo nessun calcolo di cambio
      if (currentPrestazionePagamento.valutaCambio === 1) {
        importo =
          currentPrestazionePagamento.valutaImporto *
          (tassoCambio);
      } else {
        importo =
          (currentPrestazionePagamento.valutaImporto *
            (1/(tassoCambio / 10000)));
      }

      return importo;
    }
  };
  const evaluateAmountThresold = () => {
    if (
      currentPrestazionePagamento.modalitaPagamentoId !== undefined &&
      currentPrestazionePagamento.modalitaPagamentoId > 0
    ) {
      const modalita = baseMezzoPagamentoList.filter(
        (item) => item.id === currentPrestazionePagamento.modalitaPagamentoId
      )[0];

      if (
        modalita.key === "base_mezzo_pagamento_1" &&
        currentPrestazionePagamento.importo > sogliaPagamentoContanti
      ) {
        enqueueSnackbar(
          t("prestazione.mezzoPagamento.error.singleAmountMoreThanThreshold"),
          { variant: "error" }
        );
      }

      let amountContantiSum = 0;
      currentPrestazione.pagamenti.forEach((item) => {
        const modalita2 = baseMezzoPagamentoList.filter(
          (baseMP) => baseMP.id === item.modalitaPagamentoId
        )[0];
        if (modalita2.key === "base_mezzo_pagamento_1") {
          amountContantiSum = amountContantiSum + item.importo;
        }
      });

      if (amountContantiSum > sogliaPagamentoContanti) {
        enqueueSnackbar(
          t("prestazione.mezzoPagamento.error.sumAmountMoreThanThreshold"),
          { variant: "error" }
        );
      }
    }
  };
  const handleChangePagamento = async (event, value) => {
    const { id } = event.target

    if (id.indexOf("ddlValuta-option") !== -1 || id === "ddlValuta") {
      const valutaItem = baseValutaList.filter(
        (item) => item.id === value.id
      )[0];
      setIsValutaEstera(valutaItem.isoCode !== "EUR");

      currentPrestazionePagamento.valutaId = value.id;
      currentPrestazionePagamento.valutaCambioData = currentPrestazione.dataStart;
      currentPrestazionePagamento.valutaCambio = valutaItem.eurRate;
      currentPrestazionePagamento.importo = evaluateImporto(currentPrestazionePagamento.valutaCambio);
      setValutaCambio(
        valutaItem.isoCode === "EUR" ? 1 : valutaItem.eurRate / 10000
      );
      setPagamentoImportoEuro(currentPrestazionePagamento.importo);
      evaluateAmountThresold();
      const isoResponse = await apiBaseValutaByIso.fetch({
        isoCode : valutaItem.isoCode
      })
      setValutaByIso(isoResponse.data.data.base_valuta)
    } else if (id === "txtValutaCambio") {
      const cambio = event.target.value
      currentPrestazionePagamento.valutaCambio = cambio;
      currentPrestazionePagamento.importo = evaluateImporto(currentPrestazionePagamento.valutaCambio);
    } else if (id === "txtImporto") {
      const importoValuta = event.target.value;
      const importoMigliaia = importoValuta;

      currentPrestazionePagamento.valutaImporto = parseFloat(
        importoMigliaia.replace(".", "").replace(",", ".")
      );
      currentPrestazionePagamento.importo = evaluateImporto(currentPrestazionePagamento.valutaCambio);
    } else if (
      id.indexOf("ddlMezzoPagamento-option") !== -1 ||
      id === "ddlMezzoPagamento"
    ) {
      currentPrestazionePagamento.modalitaPagamentoId = value.id;
      evaluateAmountThresold();
    } else if (
      id.indexOf("ddlOrigineFondi-option") !== -1 ||
      id === "ddlOrigineFondi"
    ) {
      currentPrestazionePagamento.origineId = value.id;
    } else if (id === "chbShowMezziPag") {
      setShowMezziPag(event.target.checked);
    }

    setCurrentPrestazionePagamento(currentPrestazionePagamento);
  };
  const handleBlurImportoPagamento = (event, value) => {
    const { id } = event.target;

    if (id === "txtImporto") {
      setPagamentoImportoEuro(currentPrestazionePagamento.importo);
      evaluateTipoPrestazione();
      evaluateAmountThresold();
    }
  };
  const handleKeyPressImportoPagamento = (event) => {
    if (event.key === "Enter") {
      event.target.blur();
    }
  };
  const handleBlurValutaCambio = (event, value) => {
    const { id } = event.target;

    if (id === "txtValutaCambio" && event.target.value !== "") {
      const cambio = event.target.value.replace(",",".")
      setValutaCambio(Number(cambio));
      let importo = evaluateImporto(Number(cambio)*10000)
      setPagamentoImportoEuro(importo);
      evaluateTipoPrestazione();
      evaluateAmountThresold();
    }
  };
  const handleValutaCambioDate = (date) => {
    const newDate = new Date(date);
    currentPrestazionePagamento.valutaCambioData = newDate;   
    let cambioAtDate = valutaByISO.filter(x=> isSameDate(new Date(x.RefDate),newDate))[0] 
    let cambio = cambioAtDate !== undefined ? cambioAtDate.EurRate : 0
    if(cambio !== 0){
      const valutaItem = baseValutaList.filter(
        (item) => item.id === currentPrestazionePagamento.valutaId
      )[0];
      setValutaCambio(valutaItem.isoCode === "EUR" ? 1 : cambio / 10000)            
    } else {
      setValutaCambio(cambio)
    }
    setCurrentPrestazionePagamento((prevState) => ({
      ...prevState,
      valutaCambioData: newDate,
      valutaCambio : cambio
    }));

    if(cambio > 0){
      let importo = evaluateImporto(cambio)
      setPagamentoImportoEuro(importo);
      evaluateAmountThresold();
    }
  };
  //#endregion

  //CRUD del Mezzo di Pagamento
  const emptyPagamentoModel = (prestazione, valute) => {
    const baseValutaItem = valute.filter((x) => x.isoCode === "EUR")[0];
    const baseValutaEurId = baseValutaItem.id;
    const baseValutaCambioData = baseValutaItem.refDate;
    const baseValutaCambio = baseValutaItem.eurRate;
    setIsValutaEstera(false);
    setValutaCambio(1);

    let dataPagamento = new Date();
    if (prestazione !== null && prestazione.id > 0) {
      dataPagamento = prestazione.dataStart;
    }

    const newItem = new prestazionePagamentoModel(
      0,
      baseValutaEurId,
      baseValutaCambioData,
      baseValutaCambio,
      0,
      0,
      undefined,
      undefined,
      null,
      null,
      "",
      "",
      "",
      dataPagamento,
      loggedUserId
    );
    return newItem;
  };

  //#region Handler Pagamento
  const handleCancelPagamentoClick = () => {
    setCurrentPrestazionePagamento(emptyPagamentoModel(null, baseValutaList));
    setCurrentPrestazionePagamentoOriginal(
      emptyPagamentoModel(null, baseValutaList)
    );
    setPagamentoImportoEuro(0);
    setValutaCambio(0);
    setShowMezziPag(currentPrestazionePagamentiList.length > 0);
  };
  const handleSavePagamentoClick = async () => {
    try {
      if (
        currentPrestazionePagamento.valutaId !== undefined &&
        currentPrestazionePagamento.valutaId > 0 &&
        currentPrestazionePagamento.importo !== undefined &&
        currentPrestazionePagamento.modalitaPagamentoId !== undefined &&
        currentPrestazionePagamento.modalitaPagamentoId > 0 &&
        currentPrestazionePagamento.origineId !== undefined &&
        currentPrestazionePagamento.origineId > 0
      ) {
        //Se sto creando un mezzo di pagamento
        if (currentPrestazionePagamento.id === 0) {
          const newDateD = new Date(currentPrestazionePagamento.data);
          const newDate = new Date(
            Date.UTC(
              newDateD.getFullYear(),
              newDateD.getMonth(),
              newDateD.getDate(),
              newDateD.getHours(),
              newDateD.getMinutes(),
              newDateD.getSeconds()
            )
          );

          const addPresPagResponse = await apiAddPrestazionePagamento.fetch({
            prestazioneId: currentPrestazione.id,
            valutaId: currentPrestazionePagamento.valutaId,
            valutaData: currentPrestazionePagamento.valutaCambioData,
            valutaCambio: currentPrestazionePagamento.valutaCambio,
            valutaImporto: currentPrestazionePagamento.valutaImporto,
            modalitaPagamentoId:
              currentPrestazionePagamento.modalitaPagamentoId,
            origineId: currentPrestazionePagamento.origineId,
            importoEuro: currentPrestazionePagamento.importo,
            data: newDate,
          });
          if (addPresPagResponse.data.errors !== undefined) {
            enqueueSnackbar(t("error.generale"), { variant: "error" });
          } else {
            const result =
              addPresPagResponse.data.data.insert_prestazione_pagamento_one;
            const prestazionePagamentoResultModel =
              new prestazionePagamentoModel(
                result.Id,
                result.ValutaId,
                result.ValutaData,
                result.ValutaCambio,
                result.ValutaImporto,
                result.ImportoEuro,
                result.ModalitaPagamentoId,
                result.OrigineId,
                result.CreatedOn,
                result.ModifiedOn,
                result.base_valutum.IsoCode,
                result.base_mezzo_pagamento.Value,
                result.base_origine_fondi.Value,
                result.Data,
                result.CreatedBy,
                result.ModifiedBy
              );

            //Aggiungo la Audit per tracciare l'azione
            const newValues = prestazionePagamentoResultModel;
            apiAddAudit.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
              area: bsaPrestazione,
              operation: "Create",
              entityType: "mezzo_pagamento",
              entityId: result.Id.toString(),
              oldValues: "",
              newValues: JSON.stringify(newValues),
            });

            const pagamenti = Object.assign([], currentPrestazione.pagamenti);
            pagamenti.push(prestazionePagamentoResultModel);
            currentPrestazione.pagamenti = pagamenti;
            setCurrentPrestazione(currentPrestazione);
            setCurrentPrestazioneOriginal(currentPrestazione);
            setCurrentPrestazionePagamento(
              emptyPagamentoModel(currentPrestazione, baseValutaList)
            );
            setCurrentPrestazionePagamentoOriginal(
              emptyPagamentoModel(currentPrestazione, baseValutaList)
            );
            setPagamentoImportoEuro(0);
            setValutaCambio(0);
            setCurrentPrestazionePagamentiList(pagamenti);
            evaluateTipoPrestazione(
              currentPrestazione.professionisti,
              pagamenti
            );

            enqueueSnackbar(t("message.success"), { variant: "success" });
          }
        }

        //Se lo sto modificando
        else {
          const newDateD = new Date(currentPrestazionePagamento.data);
          const newDate = new Date(
            Date.UTC(
              newDateD.getFullYear(),
              newDateD.getMonth(),
              newDateD.getDate(),
              newDateD.getHours(),
              newDateD.getMinutes(),
              newDateD.getSeconds()
            )
          );

          const updatePresPagResponse =
            await apiUpdatePrestazionePagamento.fetch({
              id: currentPrestazionePagamento.id,
              valutaId: currentPrestazionePagamento.valutaId,
              valutaData: currentPrestazionePagamento.valutaCambioData,
              valutaCambio: currentPrestazionePagamento.valutaCambio,
              valutaImporto: currentPrestazionePagamento.valutaImporto,
              modalitaPagamentoId:
                currentPrestazionePagamento.modalitaPagamentoId,
              origineId: currentPrestazionePagamento.origineId,
              importoEuro: currentPrestazionePagamento.importo,
              data: newDate,
            });
          if (updatePresPagResponse.data.errors !== undefined) {
            enqueueSnackbar(t("error.generale"), { variant: "error" });
          } else {
            const result =
              updatePresPagResponse.data.data.update_prestazione_pagamento
                .returning[0];
            const prestazionePagamentoResultModel =
              new prestazionePagamentoModel(
                result.Id,
                result.ValutaId,
                result.ValutaData,
                result.ValutaCambio,
                result.ValutaImporto,
                result.ImportoEuro,
                result.ModalitaPagamentoId,
                result.OrigineId,
                result.CreatedOn,
                result.ModifiedOn,
                result.base_valutum.IsoCode,
                result.base_mezzo_pagamento.Value,
                result.base_origine_fondi.Value,
                result.Data,
                result.CreatedBy,
                result.ModifiedBy
              );

            //Aggiungo la Audit per tracciare l'azione
            const newValues = prestazionePagamentoResultModel;
            apiAddAudit.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
              area: bsaPrestazione,
              operation: "Update",
              entityType: "mezzo_pagamento",
              entityId: currentPrestazionePagamento.id.toString(),
              oldValues: JSON.stringify(currentPrestazionePagamentoOriginal),
              newValues: JSON.stringify(newValues),
            });

            const pagamenti = Object.assign([], currentPrestazione.pagamenti);
            const index = pagamenti.findIndex(
              (item) => item.id === prestazionePagamentoResultModel.id
            );
            pagamenti[index] = prestazionePagamentoResultModel;
            currentPrestazione.pagamenti = pagamenti;

            setCurrentPrestazione(currentPrestazione);
            setCurrentPrestazioneOriginal(currentPrestazione);
            setCurrentPrestazionePagamento(
              emptyPagamentoModel(currentPrestazione, baseValutaList)
            );
            setCurrentPrestazionePagamentoOriginal(
              emptyPagamentoModel(currentPrestazione, baseValutaList)
            );
            setPagamentoImportoEuro(0);
            setValutaCambio(0);
            setCurrentPrestazionePagamentiList(pagamenti);
            evaluateTipoPrestazione(
              currentPrestazione.professionisti,
              pagamenti
            );

            enqueueSnackbar(t("message.success"), { variant: "success" });
          }
        }

        //Aggiorno la data di ultima modifica del fascicolo
        await apiUpdateFascicolo.fetch({
          id: fascicolo.id,
          name: fascicolo.name,
          date: fascicolo.date,
          isPrivate: fascicolo.isPrivate,
        });
      } else {
        enqueueSnackbar(t("error.missingFields"), { variant: "error" });
      }
    } catch (e) {
      AddLog(e.message + " Pagamento: " + currentPrestazionePagamento, e.stack, "HandleSavePagamentoClick")      
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
      reloadStatusFascicolo();
    }
  };
  const handleSelectedPagamentoClick = (item) => {
    const valutaItem = baseValutaList.filter(
      (item2) => item2.id === item.valutaId
    )[0];
    currentPrestazionePagamento.valutaCambioData = valutaItem.refDate;
    currentPrestazionePagamento.valutaCambio = valutaItem.eurRate;
    setValutaCambio(
      valutaItem.isoCode === "EUR" ? 1 : valutaItem.eurRate / 10000
    );

    setCurrentPrestazionePagamento(item);
    setCurrentPrestazionePagamentoOriginal(item);
    setPagamentoImportoEuro(item.importo);
  };
  const handleDeletePagamentoClick = (item) => {
    setIdToPerform(item.id);
    setTypeToPerform("MP");
    setShowDeleteModal(true);
  };
  //#endregion

  //#region Modal Delete
  const handleModalDeleteClose = () => {
    setIdToPerform(0);
    setTypeToPerform("P");
    setShowDeleteModal(false);
  };
  const handleModalDeleteOkClick = async () => {
    try {
      if (idToPerform > 0) {
        setBackDropOpen(true);

        if (typeToPerform === "P") {
          const delPreResponse = await apiDeletePrestazione.fetch({
            id: idToPerform,
          });
          if (delPreResponse.data.errors !== undefined) {
            enqueueSnackbar(t("error.generale"), { variant: "error" });
          } else {
            //Aggiungo la Audit per tracciare l'azione
            const itemById = JSON.stringify(
              currentPrestazioniList.filter(
                (item) => item.id === idToPerform
              )[0]
            );
            apiAddAudit.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
              area: bsaPrestazione,
              operation: "Delete",
              entityType: "prestazione",
              entityId: idToPerform.toString(),
              oldValues: itemById,
              newValues: "",
            });

            //Aggiorno l'elenco delle prestazioni
            const prestazioni = Object.assign([], currentPrestazioniList);
            const newPrestazioni = prestazioni.filter(
              (item) => item.id !== idToPerform
            );
            setCurrentPrestazioniList(newPrestazioni);
            setCurrentPrestazione(emptyPrestazioneModel());
            setCurrentPrestazioneOriginal(emptyPrestazioneModel());
            setCurrentPrestazionePagamento(
              emptyPagamentoModel(null, baseValutaList)
            );
            setCurrentPrestazionePagamentoOriginal(
              emptyPagamentoModel(null, baseValutaList)
            );
            setCurrentPrestazioneChbVal({ isPrivate: false });
            setCurrentPrestazionePagamentiList([]);
            setShowMezziPag(false);
            setCanIShowMezziPag(false);
            setShowPrestazioneForm(false);

            enqueueSnackbar(t("message.success"), { variant: "success" });
          }
        } else if (typeToPerform === "MP") {
          const delPresPagResponse = await apiDeletePrestazionePagamento.fetch({
            id: idToPerform,
          });
          if (delPresPagResponse.data.errors !== undefined) {
            enqueueSnackbar(t("error.generale"), { variant: "error" });
          } else {
            //Aggiungo la Audit per tracciare l'azione
            const itemById = JSON.stringify(
              currentPrestazione.pagamenti.filter(
                (item) => item.id === idToPerform
              )[0]
            );
            apiAddAudit.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
              area: bsaPrestazione,
              operation: "Delete",
              entityType: "mezzo_pagamento",
              entityId: idToPerform.toString(),
              oldValues: itemById,
              newValues: "",
            });

            //Aggiorno l'elenco dei mezzi di pagamento per la prestazione corrente
            const pagamenti = Object.assign([], currentPrestazione.pagamenti);
            const newPagamenti = pagamenti.filter(
              (item) => item.id !== idToPerform
            );
            currentPrestazione.pagamenti = newPagamenti;
            setCurrentPrestazione(currentPrestazione);
            setCurrentPrestazionePagamento(
              emptyPagamentoModel(currentPrestazione, baseValutaList)
            );
            setCurrentPrestazionePagamentoOriginal(
              emptyPagamentoModel(currentPrestazione, baseValutaList)
            );
            setCurrentPrestazionePagamentiList(newPagamenti);
            setPagamentoImportoEuro(0);
            setValutaCambio(0);
            evaluateTipoPrestazione(
              currentPrestazione.professionisti,
              newPagamenti
            );
            setShowMezziPag(newPagamenti.length > 0);

            enqueueSnackbar(t("message.success"), { variant: "success" });
          }
        }

        //Aggiorno la data di ultima modifica del fascicolo
        await apiUpdateFascicolo.fetch({
          id: fascicolo.id,
          name: fascicolo.name,
          date: fascicolo.date,
          isPrivate: fascicolo.isPrivate,
        });
      }
    } catch (e) {
      AddLog(e.message+ "Prestazione Id: " +idToPerform,e.stack,"HandleModalDeleteOkClick")
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setIdToPerform(0);
      setTypeToPerform("P");
      setShowDeleteModal(false);
      setBackDropOpen(false);
      reloadStatusFascicolo();
    }
  };
  //#endregion

  //EuroConference
  const handleEuroConfClick = async (itemId) => {
    try {
      setBackDropOpen(true);

      const jsonToEnc = {
        id: itemId,
        search: "",
        userfullname: sharedDatas.getPropertyByName("userModel").username,
        context: "TS420",
        from: "TS Antiriciclaggio",
        userid: loggedUserId,
        teamsystemid: sharedDatas.getPropertyByName("userModel").email,
      };

      const cryptedContentResp = await tsApiAntiRic.fetch({
        url:
          "aml:ts-anti-ric:api://euroconf/getEncParam?valueToEnc=" +
          JSON.stringify(jsonToEnc),
      });

      window.open(
        "https://portale.ecevolution.it/ssoEvolution?encParam=" +
          cryptedContentResp.data,
        "_blank",
        "noopener,noreferrer"
      );
    } catch (e) {
      AddLog(e.message,e.stack,"HandleEuroConfClick")      
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
    }
  };

  //#region Return graphics
  return (
    <Fragment>
      <VaporPage.Section>
        <Loading open={backDropOpen} />

        {isDataLoaded && !userPermissions.canRead ? <NotAuth /> : null}

        {showDeleteModal && idToPerform > 0 && typeToPerform !== "" ? (
          <ModalDelete
            show={showDeleteModal}
            onClose={handleModalDeleteClose}
            onOk={handleModalDeleteOkClick}
          ></ModalDelete>
        ) : null}

        {isDataLoaded && userPermissions.canRead ? (
          <Grid container>
            <Grid item xs={12}>
              <Stack
                direction="row"
                spacing={2}
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Stack direction="column" spacing={2} sx={{ width: "25%" }}>
                  {userPermissions.canCreate ? (
                    <Button
                      variant="contained"
                      size="small"
                      endIcon={<AddIcon />}
                      onClick={handleAddPrestClick}
                    >
                      {t("prestazione.titoloAdd")}
                    </Button>
                  ) : null}
                  {currentPrestazioniList.length > 0 ? (
                    <TableContainer component={Paper}>
                      <Table size="small" sx={{ mt: 2 }}>
                        <TableBody>
                          {currentPrestazioniList.map((item) => (
                            <TableRow
                              key={uuidv4()}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                              hover
                              selected={currentPrestazione.id === item.id}
                            >
                              <TableCell
                                key={uuidv4()}
                                component="th"
                                scope="row"
                              >
                                <Stack
                                  direction="row"
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  <Stack direction="column">
                                    <ExtendedTypography
                                      variant="titleSmall"
                                      color="primary.main"
                                    >
                                      {new Date(
                                        item.dataStart
                                      ).toLocaleDateString()}
                                    </ExtendedTypography>
                                    <ExtendedTypography
                                      variant="body"
                                      color="text.secondary"
                                    >
                                      {item.prestazioneNome}
                                    </ExtendedTypography>
                                  </Stack>
                                  <Box sx={{ flexGrow: 1 }} />
                                  <Stack direction="row">
                                    {(!item.isPrivate ||
                                      item.professionisti
                                        .map((prof) => prof.userId)
                                        .includes(loggedUserId)) && (
                                      <IconButton
                                        edge="end"
                                        aria-label="select"
                                        color="primary"
                                        onClick={() =>
                                          handleSelectedPrestClick(item)
                                        }
                                        sx={{ mr: "1px !important" }}
                                      >
                                        <ModeEditIcon fontSize="small" />
                                      </IconButton>
                                    )}
                                    {/* <IconButton edge="end" aria-label="rischio" color="primary" onClick={() => handleRischioPrestClick(item)} sx={{ mr: "1px !important" }}><WarningIcon fontSize="small" /></IconButton> */}
                                    {(!item.isPrivate ||
                                      item.professionisti
                                        .map((prof) => prof.userId)
                                        .includes(loggedUserId)) && (
                                      <IconButton
                                        edge="end"
                                        aria-label="rischio"
                                        color="primary"
                                        onClick={() =>
                                          handleRischioPrestClick(item)
                                        }
                                        sx={{ mr: "1px !important" }}
                                      >
                                        <WarningIcon fontSize="small" />
                                      </IconButton>
                                    )}

                                    {userPermissions.canDelete &&
                                    (!item.isPrivate ||
                                      item.professionisti
                                        .map((prof) => prof.userId)
                                        .includes(loggedUserId)) ? (
                                      <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        color="error"
                                        onClick={() =>
                                          handleDeletePrestClick(item)
                                        }
                                        sx={{ mr: "1px !important" }}
                                      >
                                        <DeleteIcon fontSize="small" />
                                      </IconButton>
                                    ) : null}
                                  </Stack>
                                </Stack>
                                <Divider
                                  variant="standard"
                                  sx={{ mt: 1, mb: 1 }}
                                />

                                {item.professionisti.map((itemP) => (
                                  <Stack
                                    key={uuidv4()}
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="left"
                                  >
                                    <ExtendedTypography
                                      variant="body"
                                      color="text.secondary"
                                    >
                                      {t("prestazione.professionista")}{" "}
                                      {itemP.completeName}
                                    </ExtendedTypography>
                                    <ExtendedTypography
                                      variant="body"
                                      color="text.secondary"
                                    >
                                      {t("prestazione.valutazioneRischi")}{" "}
                                      {item.rischi.filter(
                                        (x) => x.ProvidedBy === itemP.userId
                                      ).length
                                        ? "Sì"
                                        : "No"}
                                    </ExtendedTypography>
                                    <ExtendedTypography
                                      variant="body"
                                      color="text.secondary"
                                    >
                                      {t("prestazione.prossimaVerifica")}
                                      {item.rischi.filter(
                                        (x) => x.ProvidedBy === itemP.userId
                                      ).length > 0
                                        ? " " +
                                          new Date(
                                            item.rischi.filter(
                                              (x) =>
                                                x.ProvidedBy === itemP.userId
                                            )[0].DataControlloCostante
                                          ).toLocaleDateString()
                                        : " -"}
                                    </ExtendedTypography>
                                    {item.professionisti.length > 1 ? (
                                      <Divider
                                        variant="standard"
                                        sx={{ mt: 1, mb: 1 }}
                                      />
                                    ) : null}
                                    {/* <Box sx={{ flexGrow: 1 }} />
                                                                        <IconButton edge="end" aria-label="rischio" color="primary" onClick={() => handleRischioPrestClick(item)} sx={{ mr: "1px !important" }}><WarningIcon fontSize="small" /></IconButton> */}
                                  </Stack>
                                ))}

                                {/* <Stack direction="row" justifyContent="center" alignItems="center">
                                                                    <ExtendedTypography variant="body" color="text.secondary">
                                                                        Valutazione rischi presente: {item.rischi.length ? "Sì" : "No"}
                                                                    </ExtendedTypography>
                                                                    <Box sx={{ flexGrow: 1 }} />
                                                                    <IconButton edge="end" aria-label="rischio" color="primary" onClick={() => handleRischioPrestClick(item)} sx={{ mr: "1px !important" }}><WarningIcon fontSize="small" /></IconButton>
                                                                </Stack>

                                                                {item.rischi.length > 0 ? (
                                                                    <ExtendedTypography variant="body" color="text.secondary">
                                                                        Prossima verifica: {new Date(item.rischi[0].DataControlloCostante).toLocaleDateString()}
                                                                    </ExtendedTypography>
                                                                ) : null} */}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <NoData size="subtitle" />
                  )}
                </Stack>
                <Stack
                  direction="column"
                  spacing={2}
                  sx={{
                    width: "50%",
                    ...(!canIshowMezziPag && { width: "75%" }),
                  }}
                >
                  {showPrestazioneForm ? (
                    <Fragment>
                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="left"
                        alignItems="center"
                      >
                        <ExtendedTypography variant="subtitle2">
                          {t("prestazione.form.tipo")}:{" "}
                        </ExtendedTypography>
                        <VaporTag
                          variant="ghost"
                          type="blueSapphire"
                          label={currentPrestazioneTipoKeyS}
                        />
                        <Box sx={{ flexGrow: 1 }} />
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                id="chkPrivate"
                                checked={currentPrestazioneChbVal.isPrivate}
                                onChange={handleChange}
                              />
                            }
                            label={t("prestazione.form.privata")}
                          />
                        </FormGroup>
                      </Stack>

                      <Stack direction="row" sx={{ mt: "2px !important" }}>
                        <Button
                          variant="text"
                          size="small"
                          startIcon={
                            <img
                              src="/img/EcLogo.png"
                              width={20}
                              alt="EcLogo"
                            />
                          }
                          sx={{ width: "fit-content !important" }}
                          onClick={() => handleEuroConfClick(17239)}
                        >
                          {t("prestazione.adeguataVerifica")}
                        </Button>
                      </Stack>

                      <Stack direction="row" spacing={2}>
                        <Autocomplete
                          id="ddlUtenti"
                          key={uuidv4()}
                          size="small"
                          fullWidth
                          multiple
                          options={utentiList}
                          sx={{ width: "100%" }}
                          getOptionLabel={(option) => option.completeName}
                          isOptionEqualToValue={(option, value) =>
                            option.userId === value.userId
                          }
                          defaultValue={currentPrestazione.professionisti}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                id="chbDdlUtenti"
                                icon={
                                  <CheckBoxOutlineBlankIcon fontSize="small" />
                                }
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.completeName}
                            </li>
                          )}
                          getOptionDisabled={(option) =>
                            currentPrestazione.rischi.length > 0 &&
                            currentPrestazione.rischi.filter(
                              (x) => x.ProvidedBy === option.userId
                            ).length > 0
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("prestazione.form.erogataDa")}
                              required
                              onKeyDown={(event) => {
                                event.stopPropagation();
                              }}
                            />
                          )}
                          renderTags={(params) =>
                            params.map((item) => (
                              <VaporTag
                                key={uuidv4()}
                                variant="standard"
                                type="blueSapphire"
                                label={item.completeName}
                                sx={{ mr: 1 }}
                              />
                            ))
                          }
                          onChange={(event, newValue, reason) => {
                            handleChange(event, newValue);
                            if (event.code !== "Backspace") {
                            }
                          }}
                        />

                        <Autocomplete
                          id="ddlPrestazione"
                          key={uuidv4()}
                          size="small"
                          fullWidth
                          options={baseTipoPrestazioneList}
                          sx={{ width: "100%" }}
                          isOptionEqualToValue={(option, value) =>
                            currentPrestazione.prestazioneId === undefined
                              ? option.id === value.id
                              : option.id === currentPrestazione.prestazioneId
                          }
                          getOptionLabel={(option) =>
                            option.nome                            
                          }
                          defaultValue={
                            currentPrestazione.prestazioneId !== undefined
                              ? baseTipoPrestazioneList.filter(
                                  (item) =>
                                    item.id === currentPrestazione.prestazioneId
                                )[0]
                              : null
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("prestazione.form.prestazione")}
                              required
                            />
                          )}
                          onChange={handleChange}
                        />
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          adapterLocale={it}
                        >
                          <DatePicker
                            id="dpStartDate"
                            name="dpStartDate"
                            label={t("prestazione.form.dataInizio")}
                            closeOnSelect
                            format="dd/MM/yyyy"
                            mask="__/__/____"
                            maxDate={
                              currentPrestazione.rischi.length > 0 &&
                              isDateValid(currentPrestazione.rischi[0].Date)
                                ? new Date(currentPrestazione.rischi[0].Date)
                                : new Date()
                            }
                            minDate={
                              isDateValid(anagraficaFascicolo.versionDate)
                                ? new Date(anagraficaFascicolo.versionDate)
                                : new Date()
                            }
                            value={
                              isDateValid(currentPrestazione.dataStart)
                                ? new Date(currentPrestazione.dataStart)
                                : null
                            }
                            onChange={handleStartDate}
                            slotProps={{
                              textField: {
                                required: true,
                                placeholder: "dd/MM/yyyy",
                                size: "small",
                                fullWidth: true,
                              },
                            }}
                          />
                        </LocalizationProvider>

                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          adapterLocale={it}
                        >
                          <DatePicker
                            id="dpEndDate"
                            name="dpEndDate"
                            label={t("prestazione.form.dataCessazione")}
                            closeOnSelect
                            format="dd/MM/yyyy"
                            mask="__/__/____"
                            minDate={
                              isDateValid(currentPrestazione.dataStart)
                                ? new Date(currentPrestazione.dataStart)
                                : null
                            }
                            value={
                              isDateValid(currentPrestazione.dataEnd)
                                ? new Date(currentPrestazione.dataEnd)
                                : null
                            }
                            onChange={handleEndDate}
                            slotProps={{
                              textField: {
                                placeholder: "dd/MM/yyyy",
                                size: "small",
                                fullWidth: true,
                              },
                            }}
                          />
                        </LocalizationProvider>

                        <Autocomplete
                          id="ddlScopo"
                          key={uuidv4()}
                          size="small"
                          fullWidth
                          options={baseScopoPrestazioneList}
                          sx={{ width: "100%" }}
                          isOptionEqualToValue={(option, value) =>
                            currentPrestazione.scopoId === undefined
                              ? option.id === value.id
                              : option.id === currentPrestazione.scopoId
                          }
                          getOptionLabel={(option) => option.value}
                          defaultValue={
                            currentPrestazione.scopoId !== undefined
                              ? baseScopoPrestazioneList.filter(
                                  (item) =>
                                    item.id === currentPrestazione.scopoId
                                )[0]
                              : null
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("prestazione.form.scopoRapporto")}
                              required
                            />
                          )}
                          onChange={handleChange}
                        />

                        <Autocomplete
                          id="ddlNatura"
                          key={uuidv4()}
                          size="small"
                          fullWidth
                          options={baseNaturaPrestazioneList}
                          sx={{ width: "100%" }}
                          isOptionEqualToValue={(option, value) =>
                            currentPrestazione.naturaId === undefined
                              ? option.id === value.id
                              : option.id === currentPrestazione.naturaId
                          }
                          getOptionLabel={(option) => option.value}
                          defaultValue={
                            currentPrestazione.naturaId !== undefined
                              ? baseNaturaPrestazioneList.filter(
                                  (item) =>
                                    item.id === currentPrestazione.naturaId
                                )[0]
                              : null
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("prestazione.form.naturaRapporto")}
                              required
                            />
                          )}
                          onChange={handleChange}
                        />
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <Autocomplete
                          id="ddlPaese"
                          key={uuidv4()}
                          size="small"
                          fullWidth
                          options={basePaeseList}
                          sx={{ width: "100%" }}
                          isOptionEqualToValue={(option, value) =>
                            currentPrestazione.paeseId === undefined
                              ? option.id === value.id
                              : option.id === currentPrestazione.paeseId
                          }
                          getOptionLabel={(option) => option.nome}
                          defaultValue={
                            currentPrestazione.paeseId !== undefined
                              ? basePaeseList.filter(
                                  (item) =>
                                    item.id === currentPrestazione.paeseId
                                )[0]
                              : null
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("prestazione.form.nazione")}
                              required
                            />
                          )}
                          onChange={handleChange}
                        />

                        {isPaeseIta ? (
                          <Autocomplete
                            id="ddlProvincia"
                            key={uuidv4()}
                            size="small"
                            fullWidth
                            options={baseProvinciaList}
                            sx={{ width: "100%" }}
                            isOptionEqualToValue={(option, value) =>
                              currentPrestazione.provinciaId === undefined
                                ? option.id === value.id
                                : option.id === currentPrestazione.provinciaId
                            }
                            getOptionLabel={(option) => option.nome}
                            defaultValue={
                              currentPrestazione.provinciaId !== undefined
                                ? baseProvinciaList.filter(
                                    (item) =>
                                      item.id === currentPrestazione.provinciaId
                                  )[0]
                                : null
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t("prestazione.form.provincia")}
                              />
                            )}
                            onChange={handleChange}
                          />
                        ) : null}

                        {!isPaeseIta ? (
                          <TextField
                            id="txtProvincia"
                            key={uuidv4()}
                            label={t("prestazione.form.provincia")}
                            size="small"
                            defaultValue={currentPrestazione.provincia}
                            fullWidth
                            onChange={handleChange}
                            inputProps={{ autocomplete: "nope" }}
                          />
                        ) : null}

                        {isPaeseIta ? (
                          <Autocomplete
                            id="ddlComune"
                            key={uuidv4()}
                            size="small"
                            fullWidth
                            options={baseComuneList}
                            sx={{ width: "100%" }}
                            isOptionEqualToValue={(option, value) =>
                              currentPrestazione.cittaId === undefined
                                ? option.id === value.id
                                : option.id === currentPrestazione.cittaId
                            }
                            getOptionLabel={(option) => option.nome}
                            defaultValue={
                              currentPrestazione.cittaId !== undefined
                                ? baseComuneList.filter(
                                    (item) =>
                                      item.id === currentPrestazione.cittaId
                                  )[0]
                                : null
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t("prestazione.form.comune")}
                              />
                            )}
                            onChange={handleChange}
                          />
                        ) : null}

                        {!isPaeseIta ? (
                          <TextField
                            id="txtComune"
                            key={uuidv4()}
                            label={t("prestazione.form.comune")}
                            size="small"
                            defaultValue={currentPrestazione.citta}
                            fullWidth
                            onChange={handleChange}
                            inputProps={{ autocomplete: "nope" }}
                          />
                        ) : null}
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <TextField
                          id="txtDescrizione"
                          key={uuidv4()}
                          label={t("prestazione.form.descrizione")}
                          size="small"
                          defaultValue={currentPrestazione.descrizione}
                          fullWidth
                          multiline
                          rows={2}
                          onChange={handleChange}
                          inputProps={{ autocomplete: "nope" }}
                        />
                      </Stack>

                      <Stack direction="column" spacing={2}>
                        <ExtendedAccordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <ExtendedTypography
                              variant="titleSmall"
                              color="primary.main"
                            >
                              {t("prestazione.astensione")}
                            </ExtendedTypography>
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              pl: "15px !important",
                              pr: "15px !important",
                            }}
                          >
                            <Grid container>
                              <Grid item xs={12}>
                                <Button
                                  variant="text"
                                  size="small"
                                  startIcon={
                                    <img
                                      src="/img/EcLogo.png"
                                      width={20}
                                      alt="EcoLogo"
                                    />
                                  }
                                  sx={{ width: "fit-content !important" }}
                                  onClick={() => handleEuroConfClick(17235)}
                                >
                                  {t("prestazione.oblighi")}
                                </Button>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        id="chkAstIsEsonero"
                                        checked={
                                          currentPrestazioneChbVal.isEsoneroObbAst
                                        }
                                        onChange={handleChange}
                                      />
                                    }
                                    label="Astensione dall’esecuzione della prestazione"
                                  />
                                </FormGroup>
                              </Grid>
                              {currentPrestazioneChbVal.isEsoneroObbAst ? (
                                <Grid item xs={12}>
                                  <TextField
                                    id="txtAstMotivo"
                                    key={uuidv4()}
                                    label="Eventuale motivazione di astensione"
                                    size="small"
                                    defaultValue={
                                      currentPrestazione.astensioneMotivo
                                    }
                                    fullWidth
                                    multiline
                                    rows={2}
                                    onChange={handleChange}
                                    inputProps={{ autocomplete: "nope" }}
                                  />
                                </Grid>
                              ) : null}
                            </Grid>
                          </AccordionDetails>
                        </ExtendedAccordion>
                      </Stack>
                    </Fragment>
                  ) : null}
                </Stack>

                {canIshowMezziPag ? (
                  <Stack direction="column" spacing={2} sx={{ width: "25%" }}>
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <ExtendedTypography
                        variant="titleSmall"
                        color="primary.main"
                      >
                        {t("prestazione.mezzoPagamento.titoloList")}
                      </ExtendedTypography>
                      <Box flexGrow="1" />
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              id="chbShowMezziPag"
                              checked={showMezziPag}
                              onChange={handleChangePagamento}
                            />
                          }
                          label=""
                          disabled={currentPrestazione.id === 0}
                        />
                      </FormGroup>
                    </Stack>
                    <Divider variant="standard" />

                    {showMezziPag ? (
                      <Grid container>
                        <Grid item xs={12}>
                          <Stack direction="column" spacing={2}>
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                              adapterLocale={it}
                            >
                              <DatePicker
                                id="dpPagamentoDate"
                                name="dpPagamentoDate"
                                label={t("autovalutazione.form.data")}
                                closeOnSelect
                                format="dd/MM/yyyy"
                                mask="__/__/____"
                                value={
                                  isDateValid(currentPrestazionePagamento.data)
                                    ? new Date(currentPrestazionePagamento.data)
                                    : null
                                }
                                onChange={handlePagamentoDate}
                                slotProps={{
                                  textField: {
                                    required: true,
                                    placeholder: "dd/MM/yyyy",
                                    size: "small",
                                    fullWidth: true,
                                  },
                                }}
                              />
                            </LocalizationProvider>

                            <Stack
                              direction="row"
                              spacing={2}
                              visibility={
                                !userPermissions.canCreate ? "hidden" : "block"
                              }
                            >
                              <Grid item xs={4}>
                                <Autocomplete
                                  id="ddlValuta"
                                  key={uuidv4()}
                                  size="small"
                                  fullWidth
                                  options={baseValutaList}
                                  sx={{ width: "100%" }}
                                  isOptionEqualToValue={(option, value) =>
                                    currentPrestazionePagamento.valutaId ===
                                    undefined
                                      ? option.id === value.id
                                      : option.id ===
                                        currentPrestazionePagamento.valutaId
                                  }
                                  getOptionLabel={(option) => option.isoCode}
                                  defaultValue={
                                    currentPrestazionePagamento.valutaId !==
                                    undefined
                                      ? baseValutaList.filter(
                                          (item) =>
                                            item.id ===
                                            currentPrestazionePagamento.valutaId
                                        )[0]
                                      : null
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={t(
                                        "prestazione.mezzoPagamento.form.valuta"
                                      )}
                                      required
                                    />
                                  )}
                                  disabled={currentPrestazione.id === 0}
                                  onChange={handleChangePagamento}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  id="txtImporto"
                                  key={uuidv4()}
                                  ref={txtImportoRef}
                                  label={t(
                                    "prestazione.mezzoPagamento.form.importo"
                                  )}
                                  defaultValue={currentPrestazionePagamento.valutaImporto.toLocaleString(
                                    "it-IT",
                                    {
                                      maximumFractionDigits: 2,
                                      minimumFractionDigits: 2,
                                    }
                                  )}
                                  size="small"
                                  fullWidth
                                  required
                                  disabled={currentPrestazione.id === 0}
                                  onChange={handleChangePagamento}
                                  onBlur={handleBlurImportoPagamento}
                                  onKeyPress={(e) =>
                                    handleKeyPressImportoPagamento(e)
                                  }
                                  inputProps={{ autocomplete: "nope" }}
                                />
                                {/* <VaporCurrencyField id="txtImporto" label={t("prestazione.mezzoPagamento.form.importo")} defaultValue={currentPrestazionePagamento.valutaImporto} size="small" fullWidth required disabled={currentPrestazione.id === 0} onChange={handleChangePagamento} onBlur={handleBlurImportoPagamento} inputProps={{ autocomplete: "nope" }} decimalCharacter="," digitGroupSeparator="." decimalPlaces={2} textAlign="left" /> */}
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  id="txtImportoEur"
                                  key={uuidv4()}
                                  label={t(
                                    "prestazione.mezzoPagamento.form.importoEuro"
                                  )}
                                  defaultValue={pagamentoImportoEuro.toLocaleString(
                                    "it-IT",
                                    {
                                      maximumFractionDigits: 2,
                                      minimumFractionDigits: 2,
                                    }
                                  )}
                                  size="small"
                                  fullWidth
                                  required={isValutaEstera}
                                  disabled={true}
                                  inputProps={{ autocomplete: "nope" }}
                                />
                              </Grid>
                            </Stack>

                            {baseValutaList.filter(
                              (x) =>
                                x.id === currentPrestazionePagamento.valutaId
                            )[0].isoCode !== "EUR" ? (
                              <Stack
                                direction="row"
                                spacing={2}
                                visibility={
                                  baseValutaList.filter(
                                    (x) =>
                                      x.id ===
                                      currentPrestazionePagamento.valutaId
                                  )[0].isoCode !== "EUR"
                                }
                              >
                                <Grid item xs={6}>
                                  <TextField
                                    id="txtValutaCambio"
                                    key={uuidv4()}
                                    label={t(
                                      "prestazione.mezzoPagamento.form.cambio"
                                    )}
                                    defaultValue={valutaCambio.toLocaleString(
                                      "it-IT",
                                      {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                      }
                                    )}
                                    size="small"
                                    fullWidth
                                    required
                                    disabled={valutaCambio !== 0}
                                    onChange={handleChangePagamento}
                                    onBlur={handleBlurValutaCambio}
                                    inputProps={{ autocomplete: "nope" }}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    adapterLocale={it}
                                  >
                                    <DatePicker
                                      id="dpValutaCambioData"
                                      name="dpValutaCambioData"
                                      label={t(
                                        "prestazione.mezzoPagamento.form.dataCambio"
                                      )}
                                      closeOnSelect
                                      format="dd/MM/yyyy"
                                      mask="__/__/____"
                                      maxDate={new Date()}
                                      value={
                                        isDateValid(
                                          currentPrestazionePagamento.valutaCambioData
                                        )
                                          ? new Date(
                                              currentPrestazionePagamento.valutaCambioData
                                            )
                                          : null
                                      }
                                      required
                                      disabled={currentPrestazione.id === 0}
                                      onChange={handleValutaCambioDate}
                                      slotProps={{
                                        textField: {
                                          required: true,
                                          placeholder: "dd/MM/yyyy",
                                          size: "small",
                                          fullWidth: true,
                                        },
                                      }}
                                    />
                                  </LocalizationProvider>
                                </Grid>
                              </Stack>
                            ) : null}

                            <Stack direction="row" spacing={2}>
                              <Grid item xs={6}>
                                <Autocomplete
                                  id="ddlMezzoPagamento"
                                  key={uuidv4()}
                                  size="small"
                                  fullWidth
                                  options={baseMezzoPagamentoList}
                                  sx={{ width: "100%" }}
                                  isOptionEqualToValue={(option, value) =>
                                    currentPrestazionePagamento.modalitaPagamentoId ===
                                    undefined
                                      ? option.id === value.id
                                      : option.id ===
                                        currentPrestazionePagamento.modalitaPagamentoId
                                  }
                                  getOptionLabel={(option) => option.value}
                                  defaultValue={
                                    currentPrestazionePagamento.modalitaPagamentoId !==
                                    undefined
                                      ? baseMezzoPagamentoList.filter(
                                          (item) =>
                                            item.id ===
                                            currentPrestazionePagamento.modalitaPagamentoId
                                        )[0]
                                      : null
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={t(
                                        "prestazione.mezzoPagamento.form.mezzoPagamento"
                                      )}
                                      required
                                    />
                                  )}
                                  disabled={currentPrestazione.id === 0}
                                  onChange={handleChangePagamento}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <Autocomplete
                                  id="ddlOrigineFondi"
                                  key={uuidv4()}
                                  size="small"
                                  fullWidth
                                  options={baseOrigineFondiList}
                                  sx={{ width: "100%" }}
                                  isOptionEqualToValue={(option, value) =>
                                    currentPrestazionePagamento.origineId ===
                                    undefined
                                      ? option.id === value.id
                                      : option.id ===
                                        currentPrestazionePagamento.origineId
                                  }
                                  getOptionLabel={(option) => option.value}
                                  defaultValue={
                                    currentPrestazionePagamento.origineId !==
                                    undefined
                                      ? baseOrigineFondiList.filter(
                                          (item) =>
                                            item.id ===
                                            currentPrestazionePagamento.origineId
                                        )[0]
                                      : null
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={t(
                                        "prestazione.mezzoPagamento.form.origineFondi"
                                      )}
                                      required
                                    />
                                  )}
                                  disabled={currentPrestazione.id === 0}
                                  onChange={handleChangePagamento}
                                />
                              </Grid>
                            </Stack>
                            {userPermissions.canCreate ? (
                              <Stack
                                direction="row"
                                spacing={1}
                                justifyContent="right"
                                alignItems="right"
                              >
                                <Button
                                  variant="outlined"
                                  color="secondary"
                                  size="small"
                                  disabled={currentPrestazione.id === 0}
                                  onClick={handleCancelPagamentoClick}
                                >
                                  {t("actions.annulla")}
                                </Button>
                                <Button
                                  variant="contained"
                                  size="small"
                                  disabled={currentPrestazione.id === 0}
                                  onClick={handleSavePagamentoClick}
                                >
                                  {t("actions.salva")}
                                </Button>
                              </Stack>
                            ) : null}

                            <ExtendedTypography
                              variant="titleSmall"
                              color="primary.main"
                            >
                              {t("fascicolo.fascicoloAdd.elenco")}
                            </ExtendedTypography>
                            <Divider variant="standard" sx={{ mt: 1, mb: 1 }} />

                            <Stack direction="column" spacing={2}>
                              {currentPrestazionePagamentiList.length > 0 ? (
                                <TableContainer component={Paper}>
                                  <Table size="small">
                                    <TableBody>
                                      {currentPrestazionePagamentiList.map(
                                        (item) => (
                                          <TableRow
                                            key={uuidv4()}
                                            sx={{
                                              "&:last-child td, &:last-child th":
                                                { border: 0 },
                                            }}
                                            hover
                                            selected={
                                              currentPrestazione.id === item.id
                                            }
                                          >
                                            <TableCell
                                              key={uuidv4()}
                                              component="th"
                                              scope="row"
                                            >
                                              <Stack
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                              >
                                                <Stack direction="column">
                                                  <Stack
                                                    direction="row"
                                                    spacing={1}
                                                  >
                                                    <ExtendedTypography
                                                      variant="titleSmall"
                                                      color="primary.main"
                                                    >
                                                      {"€ " +
                                                        item.importo.toLocaleString(
                                                          "it-IT",
                                                          {
                                                            maximumFractionDigits: 2,
                                                            minimumFractionDigits: 2,
                                                          }
                                                        )}
                                                    </ExtendedTypography>
                                                  </Stack>

                                                  <ExtendedTypography
                                                    variant="body"
                                                    color="text.secondary"
                                                  >
                                                    {t("prestazione.importo")}{" "}
                                                    {item.valutaS +
                                                      " " +
                                                      item.valutaImporto.toLocaleString(
                                                        "it-IT",
                                                        {
                                                          maximumFractionDigits: 2,
                                                          minimumFractionDigits: 2,
                                                        }
                                                      )}{" "}
                                                    {t("prestazione.conCambio")}{" "}
                                                    {item.valutaS === "EUR"
                                                      ? (1).toLocaleString(
                                                          "it-IT",
                                                          {
                                                            maximumFractionDigits: 2,
                                                            minimumFractionDigits: 2,
                                                          }
                                                        )
                                                      : (
                                                          item.valutaCambio /
                                                          10000
                                                        ).toLocaleString(
                                                          "it-IT",
                                                          {
                                                            maximumFractionDigits: 2,
                                                            minimumFractionDigits: 2,
                                                          }
                                                        )}{" "}
                                                    {t(
                                                      "prestazione.allaDataDel"
                                                    )}{" "}
                                                    {new Date(
                                                      item.valutaCambioData
                                                    ).toLocaleDateString()}
                                                  </ExtendedTypography>
                                                  <ExtendedTypography
                                                    variant="body"
                                                    color="text.secondary"
                                                  >
                                                    {t(
                                                      "prestazione.pagadtoData"
                                                    )}{" "}
                                                    {new Date(
                                                      item.data
                                                    ).toLocaleDateString()}
                                                  </ExtendedTypography>
                                                  <ExtendedTypography
                                                    variant="body"
                                                    color="text.secondary"
                                                  >
                                                    {t(
                                                      "prestazione.pagataMezzo"
                                                    )}{" "}
                                                    {item.modalitaPagamentoS}
                                                  </ExtendedTypography>
                                                  <ExtendedTypography
                                                    variant="body"
                                                    color="text.secondary"
                                                  >
                                                    {t()} {item.origineS}
                                                  </ExtendedTypography>
                                                </Stack>
                                                <Box sx={{ flexGrow: 1 }} />
                                                <Stack direction="row">
                                                  <IconButton
                                                    color="primary"
                                                    disabled={
                                                      !userPermissions.canUpdate
                                                    }
                                                    onClick={() =>
                                                      handleSelectedPagamentoClick(
                                                        item
                                                      )
                                                    }
                                                  >
                                                    <ModeEditIcon fontSize="small" />
                                                  </IconButton>
                                                  <IconButton
                                                    color="error"
                                                    disabled={
                                                      !userPermissions.canDelete
                                                    }
                                                    onClick={() =>
                                                      handleDeletePagamentoClick(
                                                        item
                                                      )
                                                    }
                                                  >
                                                    <DeleteIcon fontSize="small" />
                                                  </IconButton>
                                                </Stack>
                                              </Stack>
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              ) : (
                                <NoData size="subtitle" />
                              )}
                            </Stack>
                          </Stack>
                        </Grid>
                      </Grid>
                    ) : null}
                  </Stack>
                ) : null}
              </Stack>
            </Grid>
          </Grid>
        ) : null}
      </VaporPage.Section>
      {isDataLoaded && userPermissions.canCreate && showPrestazioneForm ? (
        <VaporToolbar
          variant="regular"
          size="medium"
          withoutAppBar={false}
          contentRight={
            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                color="secondary"
                size="medium"
                endIcon={<CancelIcon />}
                onClick={handleCancelClick}
              >
                {t("actions.annulla")}
              </Button>
              <Button
                variant="contained"
                size="medium"
                endIcon={<SaveIcon />}
                onClick={handleSaveClick}
              >
                {currentPrestazione.id === 0
                  ? t("actions.salva")
                  : t("actions.aggiorna")}
              </Button>
            </Stack>
          }
        ></VaporToolbar>
      ) : null}
    </Fragment>
  );
  //#endregion
};
