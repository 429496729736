import { NavContent } from "./NavContent";
import { Dashboard } from "./Dashboard";
//import { AppTitle } from "./AppTitle";
import { AppTitleRight } from "./AppTitleRight";
import { pathHome, anchorOrigin, autoHideDuration, maxSnack } from "../../businessLogic/constants";
import { SnackbarProvider } from "notistack";

export const dashboard = () => [
  {
    target: "$ONE_TOOLBAR_CONTENT_RIGHT",
    priority: 1000,
    handler: { 
      component: AppTitleRight,
      props: {
        basePath: pathHome
      }
    }
  },
  {
    target: "$ONE_LAYOUT_NAV_CONTENT",
    handler: {
      component: NavContent,
      props: {
        basePath: pathHome
      }
    }
  },
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      exact: true,
      path: pathHome,
      element: <SnackbarProvider anchorOrigin={anchorOrigin} autoHideDuration={autoHideDuration} maxSnack={maxSnack}><Dashboard basePath={pathHome} /></SnackbarProvider>
    }
  }
];
