export class tenantModel {
  constructor(
    tenantId,
    ownerId,
    name,
    identifier,
    isReadOnly,
    isDisabled,
    isDeleted,
    statusDate,
    vatNumber,
    addrStreetName,
    addrStreetNumber,
    addrCity,
    addrProvince,
    addrZipCode,
    addrCountry,
    addrFull,
    classifier
  ) {
    this.tenantId = tenantId;
    this.ownerId = ownerId;
    this.name = name;
    this.identifier = identifier;
    this.isReadOnly = isReadOnly;
    this.isDisabled = isDisabled;
    this.isDeleted = isDeleted;
    this.statusDate = statusDate;
    this.vatNumber = vatNumber;
    this.addrStreetName = addrStreetName;
    this.addrStreetNumber = addrStreetNumber;
    this.addrCity = addrCity;
    this.addrProvince = addrProvince;
    this.addrZipCode = addrZipCode;
    this.addrCountry = addrCountry;
    this.addrFull = addrFull;
    this.classifier = classifier;
  }
}

export class aziendaModel {
  constructor(
    id,
    tenantId,
    denominazione,
    cf,
    pIva,
    addrPaeseId,
    addrProvinciaId,
    addrProvincia,
    addrComuneId,
    addrComune,
    addrIndirizzo,
    addrN,
    addrCap,
    createdOn,
    modifiedOn,
    createdBy,
    modifiedBy,
    meteringSlotId
  ) {
    this.id = id;
    this.tenantId = tenantId;
    this.denominazione = denominazione;
    this.cf = cf;
    this.pIva = pIva;
    this.addrPaeseId = addrPaeseId;
    this.addrProvinciaId = addrProvinciaId;
    this.addrProvincia = addrProvincia;
    this.addrComuneId = addrComuneId;
    this.addrComune = addrComune;
    this.addrIndirizzo = addrIndirizzo;
    this.addrN = addrN;
    this.addrCap = addrCap;
    this.createdOn = createdOn;
    this.modifiedOn = modifiedOn;
    this.createdBy = createdBy;
    this.modifiedBy = modifiedBy;
    this.meteringSlotId = meteringSlotId;
  }
}

export class userModel {
  constructor(
    userId,
    tenantId,
    username,
    email,
    name,
    surname,
    completeName,
    type,
    locale,
    tenantTipoId,
    isRespAntiRic,
    tenantTipoS,
    isActive,
    isDeleted,
    isBanned,
    createdOn,
    cf,
    lastVersion
  ) {
    this.userId = userId;
    this.tenantId = tenantId;
    this.username = username;
    this.email = email;
    this.name = name;
    this.surname = surname;
    this.completeName = completeName;
    this.type = type;
    this.locale = locale;
    this.tenantTipoId = tenantTipoId;
    this.isRespAntiRic = isRespAntiRic;
    this.tenantTipoS = tenantTipoS;
    this.isActive = isActive;
    this.isDeleted = isDeleted;
    this.isBanned = isBanned;
    this.createdOn = createdOn;
    this.cf = cf;
    this.lastVersion = lastVersion;
  }
}

export class auditModel {
  constructor(
    id,
    userId,
    area,
    operation,
    operationDate,
    entityType,
    entityId,
    oldValues,
    newValues
  ) {
    this.id = id;
    this.userId = userId;
    this.area = area;
    this.operation = operation;
    this.operationDate = operationDate;
    this.entityType = entityType;
    this.entityId = entityId;
    this.oldValues = oldValues;
    this.newValues = newValues;
  }
}

export class releaseNotesModel {
  constructor(id, version, date, pdf) {
    this.id = id;
    this.version = version;
    this.date = date;
    this.pdf = pdf;
  }
}

export class baseAppSettingModel {
  constructor(id, key, value) {
    this.id = id;
    this.key = key;
    this.value = value;
  }
}

export class baseGeneraleModel {
  constructor(id, category, key, value, description, order) {
    this.id = id;
    this.category = category;
    this.key = key;
    this.value = value;
    this.description = description;
    this.order = order;
  }
}

export class basePaeseModel {
  constructor(id, nome, identificativo, isRischio) {
    this.id = id;
    this.nome = nome;
    this.identificativo = identificativo;
    this.isRischio = isRischio;
  }
}

export class baseProvinciaModel {
  constructor(id, nome, sigla, rischio) {
    this.id = id;
    this.nome = nome;
    this.sigla = sigla;
    this.rischio = rischio;
  }
}

export class baseComuneModel {
  constructor(id, codice, provincia, nome, codBelfiore) {
    this.id = id;
    this.codice = codice;
    this.provincia = provincia;
    this.nome = nome;
    this.codBelfiore = codBelfiore;
  }
}

export class baseValutaModel {
  constructor(
    id,
    country,
    currency,
    isoCode,
    uicCode,
    eurRate,
    usdRate,
    refDate
  ) {
    this.id = id;
    this.country = country;
    this.currency = currency;
    this.isoCode = isoCode;
    this.uicCode = uicCode;
    this.eurRate = eurRate;
    this.usdRate = usdRate;
    this.refDate = refDate;
  }
}

export class baseTipoAnagraficaModel {
  constructor(id, key, value, order, isSystem) {
    this.id = id;
    this.key = key;
    this.value = value;
    this.order = order;
    this.isSystem = isSystem;
  }
}

export class baseTipoDocumentoModel {
  constructor(
    id,
    key,
    value,
    order,
    isSystem,
    personType,
    documentTypeId,
    validityMonths,
    validityOffsetDays,
    nextBirthDay
  ) {
    this.id = id;
    this.key = key;
    this.value = value;
    this.order = order;
    this.isSystem = isSystem;
    this.personType = personType;
    this.documentTypeId = documentTypeId;
    this.validityMonths = validityMonths;
    this.validityOffsetDays = validityOffsetDays;
    this.nextBirthDay = nextBirthDay;
  }
}

export class baseTipoIdentificazioneModel {
  constructor(id, key, value, order, isSystem) {
    this.id = id;
    this.key = key;
    this.value = value;
    this.order = order;
    this.isSystem = isSystem;
  }
}

export class baseTipoPersonalitaModel {
  constructor(id, key, value, order, isSystem) {
    this.id = id;
    this.key = key;
    this.value = value;
    this.order = order;
    this.isSystem = isSystem;
  }
}

export class baseTenantTipoModel {
  constructor(id, key, value, order) {
    this.id = id;
    this.key = key;
    this.value = value;
    this.order = order;
  }
}

export class baseTipoPartecipazioneModel {
  constructor(id, key, nome, descrizione) {
    this.id = id;
    this.key = key;
    this.nome = nome;
    this.descrizione = descrizione;
  }
}

export class baseTipoPrestazioneModel {
  constructor(id, nome, rischio, regole) {
    this.id = id;
    this.nome = nome;
    this.rischio = rischio;
    this.regole = regole;
  }
}

export class baseMezzoPagamentoModel {
  constructor(id, key, value, order, isSystem) {
    this.id = id;
    this.key = key;
    this.value = value;
    this.order = order;
    this.isSystem = isSystem;
  }
}

export class baseNaturaPrestazioneModel {
  constructor(id, key, value, order, isSystem) {
    this.id = id;
    this.key = key;
    this.value = value;
    this.order = order;
    this.isSystem = isSystem;
  }
}

export class baseOrigineFondiModel {
  constructor(id, key, value, order, isSystem) {
    this.id = id;
    this.key = key;
    this.value = value;
    this.order = order;
    this.isSystem = isSystem;
  }
}

export class baseNaturaRapportoModel {
  constructor(id, key, value, order, isSystem) {
    this.id = id;
    this.key = key;
    this.value = value;
    this.order = order;
    this.isSystem = isSystem;
  }
}

export class baseScopoPrestazioneModel {
  constructor(id, key, value, order, isSystem) {
    this.id = id;
    this.key = key;
    this.value = value;
    this.order = order;
    this.isSystem = isSystem;
  }
}

export class baseGenereModel {
  constructor(id, key, value, order, isSystem) {
    this.id = id;
    this.key = key;
    this.value = value;
    this.order = order;
    this.isSystem = isSystem;
  }
}

export class baseSecurityAreaModel {
  constructor(id, key, value, description, order) {
    this.id = id;
    this.key = key;
    this.value = value;
    this.description = description;
    this.order = order;
  }
}

export class baseRischioGradoModel {
  constructor(
    id,
    tipoRischio,
    gradoRischio,
    adeguataVerifica,
    valoreRischioDa,
    valoreRischioA,
    colore,
    offset
  ) {
    this.id = id;
    this.tipoRischio = tipoRischio;
    this.gradoRischio = gradoRischio;
    this.adeguataVerifica = adeguataVerifica;
    this.valoreRischioDa = valoreRischioDa;
    this.valoreRischioA = valoreRischioA;
    this.colore = colore;
    this.offset = offset;
  }
}

export class baseIndicatoreAnomaliaModel {
  constructor(id, parentId, section, point, subPoint, description, isVisible) {
    this.id = id;
    this.parentId = parentId;
    this.section = section;
    this.point = point;
    this.subPoint = subPoint;
    this.description = description;
    this.isVisible = isVisible;
  }
}

export class bancaDatiModel {
  constructor(
    id,
    repository,
    identifier,
    type,
    name,
    surname,
    bornDate,
    infos,
    date
  ) {
    this.id = id;
    this.repository = repository;
    this.identifier = identifier;
    this.type = type;
    this.name = name;
    this.surname = surname;
    this.bornDate = bornDate;
    this.infos = infos;
    this.date = date;
  }
}

export class fascicoloModel {
  constructor(
    id,
    tenantId,
    aziendaId,
    name,
    date,
    isPrivate,
    createdOn,
    modifiedOn,
    professionisti,
    status,
    statusReasons,
    createdBy,
    modifiedBy
  ) {
    this.id = id;
    this.tenantId = tenantId;
    this.aziendaId = aziendaId;
    this.name = name;
    this.date = date;
    this.isPrivate = isPrivate;
    this.createdOn = createdOn;
    this.modifiedOn = modifiedOn;
    this.professionisti = professionisti;
    this.status = status;
    this.statusReasons = statusReasons;
    this.createdBy = createdBy;
    this.modifiedBy = modifiedBy;
  }
}

export class anagraficaModel {
  constructor(
    id,
    parentId,
    isPg,
    cf,
    nome,
    cognome,
    genereId,
    nascitaLuogo,
    nascitaData,
    professione,
    denominazione,
    pIva,
    reaComune,
    reaN,
    addrPaeseId,
    addrProvinciaId,
    addrProvincia,
    addrComuneId,
    addrComune,
    addrIndirizzo,
    addrN,
    addrCap,
    addr2PaeseId,
    addr2ProvinciaId,
    addr2Provincia,
    addr2ComuneId,
    addr2Comune,
    addr2Indirizzo,
    addr2N,
    addr2Cap,
    isPep,
    isNotPepMoreThanYear,
    versionN,
    versionDate,
    isDeleted,
    identificazioni,
    allegati,
    pepCarica,
    pepRuolo,
    createdBy,
    modifiedBy,
    anagFascicoli,
    workspaceId,
    amlId,
    office1Id,
    office2Id
  ) {
    this.id = id;
    this.parentId = parentId;
    this.isPg = isPg;
    this.cf = cf;
    this.nome = nome;
    this.cognome = cognome;
    this.genereId = genereId;
    this.nascitaLuogo = nascitaLuogo;
    this.nascitaData = nascitaData;
    this.professione = professione;
    this.denominazione = denominazione;
    this.pIva = pIva;
    this.reaComune = reaComune;
    this.reaN = reaN;
    this.addrPaeseId = addrPaeseId;
    this.addrProvinciaId = addrProvinciaId;
    this.addrProvincia = addrProvincia;
    this.addrComuneId = addrComuneId;
    this.addrComune = addrComune;
    this.addrIndirizzo = addrIndirizzo;
    this.addrN = addrN;
    this.addrCap = addrCap;
    this.addr2PaeseId = addr2PaeseId;
    this.addr2ProvinciaId = addr2ProvinciaId;
    this.addr2Provincia = addr2Provincia;
    this.addr2ComuneId = addr2ComuneId;
    this.addr2Comune = addr2Comune;
    this.addr2Indirizzo = addr2Indirizzo;
    this.addr2N = addr2N;
    this.addr2Cap = addr2Cap;
    this.isPep = isPep;
    this.isNotPepMoreThanYear = isNotPepMoreThanYear;
    this.versionN = versionN;
    this.versionDate = versionDate;
    this.isDeleted = isDeleted;
    this.identificazioni = identificazioni;
    this.allegati = allegati;
    this.pepCarica = pepCarica;
    this.pepRuolo = pepRuolo;
    this.createdBy = createdBy;
    this.modifiedBy = modifiedBy;
    this.anagFascicoli = anagFascicoli;
    this.workspaceId = workspaceId;
    this.amlId = amlId;
    this.office1Id = office1Id;
    this.office2Id = office2Id;
  }
}

export class anagraficaFascicoloModel {
  constructor(
    id,
    anagraficaId,
    fascicoloId,
    tipoAnagId,
    tipoPartId,
    tipoPartDesc,
    dataFine,
    tipoRappId,
    tipoRappDesc
  ) {
    this.id = id;
    this.anagraficaId = anagraficaId;
    this.fascicoloId = fascicoloId;
    this.tipoAnagId = tipoAnagId;
    this.tipoPartId = tipoPartId;
    this.tipoPartDesc = tipoPartDesc;
    this.dataFine = dataFine;
    this.tipoRappId = tipoRappId;
    this.tipoRappDesc = tipoRappDesc;
  }
}

export class anagraficaIdentificazioneModel {
  constructor(
    id,
    modalitaIdentId,
    docTipoId,
    docN,
    docRilDa,
    docRilData,
    docScad,
    apNome,
    apCf,
    identifiedByUser,
    identifiedOn,
    createdOn,
    modifiedOn,
    createdBy,
    modifiedBy
  ) {
    this.id = id;
    this.modalitaIdentId = modalitaIdentId;
    this.docTipoId = docTipoId;
    this.docN = docN;
    this.docRilDa = docRilDa;
    this.docRilData = docRilData;
    this.docScad = docScad;
    this.apNome = apNome;
    this.apCf = apCf;
    this.identifiedByUser = identifiedByUser;
    this.identifiedOn = identifiedOn;
    this.createdOn = createdOn;
    this.modifiedOn = modifiedOn;
    this.createdBy = createdBy;
    this.modifiedBy = modifiedBy;
  }
}

export class anagraficaBancaDatiModel {
  constructor(id, anagraficaId, repository, infos, createdOn, createdBy) {
    this.id = id;
    this.anagraficaId = anagraficaId;
    this.repository = repository;
    this.infos = infos;
    this.createdOn = createdOn;
    this.createdBy = createdBy;
  }
}

export class allegatoModel {
  constructor(
    id,
    tenantId,
    entityType,
    entityId,
    fileName,
    fileType,
    fileUrl,
    displayedName,
    createdOn,
    modifiedOn,
    createdBy,
    modifiedBy
  ) {
    this.id = id;
    this.tenantId = tenantId;
    this.entityType = entityType;
    this.entityId = entityId;
    this.fileName = fileName;
    this.fileType = fileType;
    this.fileUrl = fileUrl;
    this.displayedName = displayedName;
    this.createdOn = createdOn;
    this.modifiedOn = modifiedOn;
    this.createdBy = createdBy;
    this.modifiedBy = modifiedBy;
  }
}

export class prestazioneModel {
  constructor(
    id,
    tipoKey,
    prestazioneId,
    prestazioneNome,
    prestazioneRischio,
    prestazioneRegole,
    dataStart,
    dataEnd,
    paeseId,
    provinciaId,
    provincia,
    cittaId,
    citta,
    scopoId,
    naturaId,
    descrizione,
    isPrivate,
    createdOn,
    modifiedOn,
    professionisti,
    pagamenti,
    astensioneMotivo,
    isEsoneroObbAst,
    scopoS,
    naturaS,
    rischi,
    tipoTenantId,
    createdBy,
    modifiedBy
  ) {
    this.id = id;
    this.tipoKey = tipoKey;
    this.prestazioneId = prestazioneId;
    this.prestazioneNome = prestazioneNome;
    this.prestazioneRischio = prestazioneRischio;
    this.prestazioneRegole = prestazioneRegole;
    this.dataStart = dataStart;
    this.dataEnd = dataEnd;
    this.paeseId = paeseId;
    this.provinciaId = provinciaId;
    this.provincia = provincia;
    this.cittaId = cittaId;
    this.citta = citta;
    this.scopoId = scopoId;
    this.naturaId = naturaId;
    this.descrizione = descrizione;
    this.isPrivate = isPrivate;
    this.createdOn = createdOn;
    this.modifiedOn = modifiedOn;
    this.professionisti = professionisti;
    this.pagamenti = pagamenti;
    this.astensioneMotivo = astensioneMotivo;
    this.isEsoneroObbAst = isEsoneroObbAst;
    this.scopoS = scopoS;
    this.naturaS = naturaS;
    this.rischi = rischi;
    this.tipoTenantId = tipoTenantId;
    this.createdBy = createdBy;
    this.modifiedBy = modifiedBy;
  }
}

export class prestazioneUserModel {
  constructor(prestazioneId, userId) {
    this.prestazioneId = prestazioneId;
    this.userId = userId;
  }
}

export class prestazionePagamentoModel {
  constructor(
    id,
    valutaId,
    valutaCambioData,
    valutaCambio,
    valutaImporto,
    importo,
    modalitaPagamentoId,
    origineId,
    createdOn,
    modifiedOn,
    valutaS,
    modalitaPagamentoS,
    origineS,
    data,
    createdBy,
    modifiedBy
  ) {
    this.id = id;
    this.valutaId = valutaId;
    this.valutaCambioData = valutaCambioData;
    this.valutaCambio = valutaCambio;
    this.valutaImporto = valutaImporto;
    this.importo = importo;
    this.modalitaPagamentoId = modalitaPagamentoId;
    this.origineId = origineId;
    this.createdOn = createdOn;
    this.modifiedOn = modifiedOn;
    this.valutaS = valutaS;
    this.modalitaPagamentoS = modalitaPagamentoS;
    this.origineS = origineS;
    this.data = data;
    this.createdBy = createdBy;
    this.modifiedBy = modifiedBy;
  }
}

export class rischioModel {
  constructor(
    id,
    date,
    prestazione,
    sp_a1_livello,
    sp_a1_1,
    sp_a1_2,
    sp_a1_3,
    sp_a1_4,
    sp_a1_5,
    sp_a1_6,
    sp_a1_7,
    sp_a1_altro,
    sp_a1_altro_note,
    sp_a2_livello,
    sp_a2_1,
    sp_a2_2,
    sp_a2_3,
    sp_a2_altro,
    sp_a2_altro_note,
    sp_a3_livello,
    sp_a3_1,
    sp_a3_2,
    sp_a3_3,
    sp_a3_4,
    sp_a3_altro,
    sp_a3_altro_note,
    sp_a4_livello,
    sp_a4_1,
    sp_a4_2,
    sp_a4_3,
    sp_a4_4,
    sp_a4_altro,
    sp_a4_altro_note,
    sp_a_subTotal,
    sp_b1_livello,
    sp_b1_1,
    sp_b1_2,
    sp_b1_3,
    sp_b1_altro,
    sp_b1_altro_note,
    sp_b2_livello,
    sp_b2_1,
    sp_b2_2,
    sp_b2_3,
    sp_b2_4,
    sp_b2_altro,
    sp_b2_altro_note,
    sp_b3_livello,
    sp_b3_1,
    sp_b3_2,
    sp_b3_3,
    sp_b3_altro,
    sp_b3_altro_note,
    sp_b4_livello,
    sp_b4_1,
    sp_b4_2,
    sp_b4_3,
    sp_b4_altro,
    sp_b4_altro_note,
    sp_b5_livello,
    sp_b5_1,
    sp_b5_2,
    sp_b5_3,
    sp_b5_altro,
    sp_b5_altro_note,
    sp_b6_livello,
    sp_b6_1,
    sp_b6_2,
    sp_b6_3,
    sp_b6_4,
    sp_b6_altro,
    sp_b6_altro_note,
    sp_b_subTotal,
    sp_c1_1_livello,
    sp_c1_2_livello,
    sp_c1_3_livello,
    sp_c_subTotal,
    ef_ri_total,
    ef_sp_total,
    ef_total,
    ef_adeguata_livello,
    hasTabellaB,
    providedBy,
    createdOn,
    modifiedOn,
    ef_sp_totalS,
    ef_totalS,
    ef_aef_adeguata_livelloS,
    dataControlloCostante,
    createdBy,
    modifiedBy
  ) {
    this.id = id;
    this.date = date;
    this.prestazione = prestazione;
    this.sp_a1_livello = sp_a1_livello;
    this.sp_a1_1 = sp_a1_1;
    this.sp_a1_2 = sp_a1_2;
    this.sp_a1_3 = sp_a1_3;
    this.sp_a1_4 = sp_a1_4;
    this.sp_a1_5 = sp_a1_5;
    this.sp_a1_6 = sp_a1_6;
    this.sp_a1_7 = sp_a1_7;
    this.sp_a1_altro = sp_a1_altro;
    this.sp_a1_altro_note = sp_a1_altro_note;
    this.sp_a2_livello = sp_a2_livello;
    this.sp_a2_1 = sp_a2_1;
    this.sp_a2_2 = sp_a2_2;
    this.sp_a2_3 = sp_a2_3;
    this.sp_a2_altro = sp_a2_altro;
    this.sp_a2_altro_note = sp_a2_altro_note;
    this.sp_a3_livello = sp_a3_livello;
    this.sp_a3_1 = sp_a3_1;
    this.sp_a3_2 = sp_a3_2;
    this.sp_a3_3 = sp_a3_3;
    this.sp_a3_4 = sp_a3_4;
    this.sp_a3_altro = sp_a3_altro;
    this.sp_a3_altro_note = sp_a3_altro_note;
    this.sp_a4_livello = sp_a4_livello;
    this.sp_a4_1 = sp_a4_1;
    this.sp_a4_2 = sp_a4_2;
    this.sp_a4_3 = sp_a4_3;
    this.sp_a4_4 = sp_a4_4;
    this.sp_a4_altro = sp_a4_altro;
    this.sp_a4_altro_note = sp_a4_altro_note;
    this.sp_a_subTotal = sp_a_subTotal;
    this.sp_b1_livello = sp_b1_livello;
    this.sp_b1_1 = sp_b1_1;
    this.sp_b1_2 = sp_b1_2;
    this.sp_b1_3 = sp_b1_3;
    this.sp_b1_altro = sp_b1_altro;
    this.sp_b1_altro_note = sp_b1_altro_note;
    this.sp_b2_livello = sp_b2_livello;
    this.sp_b2_1 = sp_b2_1;
    this.sp_b2_2 = sp_b2_2;
    this.sp_b2_3 = sp_b2_3;
    this.sp_b2_4 = sp_b2_4;
    this.sp_b2_altro = sp_b2_altro;
    this.sp_b2_altro_note = sp_b2_altro_note;
    this.sp_b3_livello = sp_b3_livello;
    this.sp_b3_1 = sp_b3_1;
    this.sp_b3_2 = sp_b3_2;
    this.sp_b3_3 = sp_b3_3;
    this.sp_b3_altro = sp_b3_altro;
    this.sp_b3_altro_note = sp_b3_altro_note;
    this.sp_b4_livello = sp_b4_livello;
    this.sp_b4_1 = sp_b4_1;
    this.sp_b4_2 = sp_b4_2;
    this.sp_b4_3 = sp_b4_3;
    this.sp_b4_altro = sp_b4_altro;
    this.sp_b4_altro_note = sp_b4_altro_note;
    this.sp_b5_livello = sp_b5_livello;
    this.sp_b5_1 = sp_b5_1;
    this.sp_b5_2 = sp_b5_2;
    this.sp_b5_3 = sp_b5_3;
    this.sp_b5_altro = sp_b5_altro;
    this.sp_b5_altro_note = sp_b5_altro_note;
    this.sp_b6_livello = sp_b6_livello;
    this.sp_b6_1 = sp_b6_1;
    this.sp_b6_2 = sp_b6_2;
    this.sp_b6_3 = sp_b6_3;
    this.sp_b6_4 = sp_b6_4;
    this.sp_b6_altro = sp_b6_altro;
    this.sp_b6_altro_note = sp_b6_altro_note;
    this.sp_b_subTotal = sp_b_subTotal;
    this.sp_c1_1_livello = sp_c1_1_livello;
    this.sp_c1_2_livello = sp_c1_2_livello;
    this.sp_c1_3_livello = sp_c1_3_livello;
    this.sp_c_subTotal = sp_c_subTotal;
    this.ef_ri_total = ef_ri_total;
    this.ef_sp_total = ef_sp_total;
    this.ef_total = ef_total;
    this.ef_adeguata_livello = ef_adeguata_livello;
    this.hasTabellaB = hasTabellaB;
    this.providedBy = providedBy;
    this.createdOn = createdOn;
    this.modifiedOn = modifiedOn;
    this.ef_sp_totalS = ef_sp_totalS;
    this.ef_totalS = ef_totalS;
    this.ef_aef_adeguata_livelloS = ef_aef_adeguata_livelloS;
    this.dataControlloCostante = dataControlloCostante;
    this.createdBy = createdBy;
    this.modifiedBy = modifiedBy;
  }
}

export class sosModel {
  constructor(
    id,
    date,
    motivo,
    note,
    receiptId,
    reportedByUser,
    createdOn,
    modifiedOn,
    createdBy,
    modifiedBy
  ) {
    this.id = id;
    this.date = date;
    this.motivo = motivo;
    this.note = note;
    this.receiptId = receiptId;
    this.reportedByUser = reportedByUser;
    this.createdOn = createdOn;
    this.modifiedOn = modifiedOn;
    this.createdBy = createdBy;
    this.modifiedBy = modifiedBy;
  }
}

export class autoValutazioneModel {
  constructor(
    id,
    tenantId,
    aziendaId,
    tenantTipoId,
    date,
    ri1,
    ri2,
    ri3,
    ri4,
    lv1,
    lv2,
    lv3,
    lv4,
    riTotale,
    lvTotale,
    rrTotale,
    livelloRischio,
    providedBy,
    note,
    createdOn,
    modifiedOn,
    createdBy,
    modifiedBy
  ) {
    this.id = id;
    this.tenantId = tenantId;
    this.aziendaId = aziendaId;
    this.tenantTipoId = tenantTipoId;
    this.date = date;
    this.ri1 = ri1;
    this.ri2 = ri2;
    this.ri3 = ri3;
    this.ri4 = ri4;
    this.lv1 = lv1;
    this.lv2 = lv2;
    this.lv3 = lv3;
    this.lv4 = lv4;
    this.riTotale = riTotale;
    this.lvTotale = lvTotale;
    this.rrTotale = rrTotale;
    this.livelloRischio = livelloRischio;
    this.providedBy = providedBy;
    this.note = note;
    this.createdOn = createdOn;
    this.modifiedOn = modifiedOn;
    this.createdBy = createdBy;
    this.modifiedBy = modifiedBy;
  }
}

export class mezzoPagamentoModel {
  constructor(id, modalitaKey, modalita, importo, valutaKey, valuta) {
    this.id = id;
    this.modalitaKey = modalitaKey;
    this.modalita = modalita;
    this.importo = importo;
    this.valutaKey = valutaKey;
    this.valuta = valuta;
  }
}

export class normativaModel {
  constructor(
    id,
    parentId,
    title,
    subTitle,
    description,
    titoloN,
    capoN,
    sezioneN,
    articoloN,
    orderN
  ) {
    this.id = id;
    this.parentId = parentId;
    this.title = title;
    this.subTitle = subTitle;
    this.description = description;
    this.titoloN = titoloN;
    this.capoN = capoN;
    this.sezioneN = sezioneN;
    this.articoloN = articoloN;
    this.orderN = orderN;
  }
}

export class userPermissionModel {
  constructor(id, aziendaId, area, canRead, canCreate, canUpdate, canDelete) {
    this.id = id;
    this.aziendaId = aziendaId;
    this.area = area;
    this.canRead = canRead;
    this.canCreate = canCreate;
    this.canUpdate = canUpdate;
    this.canDelete = canDelete;
  }
}

export class notificationModel {
  constructor(
    id,
    tenantId,
    aziendaId,
    userId,
    type,
    payload,
    isRead,
    readOn,
    readByUser,
    createdOn
  ) {
    this.id = id;
    this.tenantId = tenantId;
    this.aziendaId = aziendaId;
    this.userId = userId;
    this.type = type;
    this.payload = payload;
    this.isRead = isRead;
    this.readOn = readOn;
    this.readByUser = readByUser;
    this.createdOn = createdOn;
  }
}

export class importMappingModel {
  constructor(
    id,
    tenantId,
    aziendaId,
    type,
    mappedValue,
    value,
    createdOn,
    modifiedOn,
    createdBy,
    modifiedBy
  ) {
    this.id = id;
    this.tenantId = tenantId;
    this.aziendaId = aziendaId;
    this.type = type;
    this.mappedValue = mappedValue;
    this.value = value;
    this.createdOn = createdOn;
    this.modifiedOn = modifiedOn;
    this.createdBy = createdBy;
    this.modifiedBy = modifiedBy;
  }
}

export class indicatoreAnomaliaModel {
  constructor(
    id,
    date,
    providedBy,
    result,
    note,
    createdOn,
    modifiedOn,
    createdBy,
    modifiedBy
  ) {
    this.id = id;
    this.date = date;
    this.providedBy = providedBy;
    this.result = result;
    this.note = note;
    this.createdOn = createdOn;
    this.modifiedOn = modifiedOn;
    this.createdBy = createdBy;
    this.modifiedBy = modifiedBy;
  }
}
