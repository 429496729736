//#region Imports
import React, { useState, useEffect } from "react";
import sharedDatas from "../../businessLogic/sharedDatas";
import { useNavigate } from "react-router-dom";

//oneFront SDK
import {
  useSnackbar,
  useQuery,
  useMutation,
  useTranslation,
} from "@onefront/react-sdk";

import VaporPage from "@vapor/react-custom/VaporPage";
import VaporTag from "@vapor/react-custom/VaporTag";

//Vapor Components
import Grid from "@vapor/react-material/Grid";
import Stack from "@vapor/react-material/Stack";
import IconButton from "@vapor/react-material/IconButton";
import Button from "@vapor/react-material/Button";
import Tooltip from "@vapor/react-material/Tooltip";

//Icons
import {
  Add as AddIcon,
  ArrowForward as DetailIcon,
  DeleteOutlineOutlined as DeleteIcon,
} from "@mui/icons-material";

//Custom styles
import {
  chipGreen,
  chipYellow,
  chipOrange,
  chipRed,
  divGreen,
  divOrange,
} from "../../businessLogic/styles";

import {
  DataGridPro,
  GridToolbar,
  itIT,
} from "@mui/x-data-grid-pro";

//Custom components
import { Loading } from "../../components/Loading";
import { NotAuth } from "../../components/NotAuth";
import { NoData } from "../../components/NoData";
import { ModalDelete } from "../../components/ModalDelete";

//Constants, Api and Data Models
import {
  bsaFascicolo,
  pathFascicoloHome,
  pathFascicoloNew,
} from "../../businessLogic/constants";
import {
  setLocalStorageSelectedFascicolo,
  getFascicoloStatus,
} from "../../businessLogic/bl";
import { pathFascicoloAdd } from "../../businessLogic/constants";
import {
  userPermissionModel,
  basePaeseModel,
  baseProvinciaModel,
} from "../../businessLogic/models";
import {
  query_fascicolo_byTenant,
  query_base_paese,
  query_base_provincia,
} from "../../businessLogic/query";
import {
  mutation_add_log,
  mutation_add_audit,
  mutation_delete_fascicolo,
  mutation_add_lastActivity_byUserId,
  mutation_delete_lastActivity,
} from "../../businessLogic/mutation";
//#endregion

export const FascicoloHome = ({ basePath }) => {
  const tenantId = sharedDatas.getPropertyByName("tenantId");
  const aziendaId = sharedDatas.getPropertyByName("aziendaId");
  const loggedUserId = sharedDatas.getPropertyByName("userId");
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  //#region Query
  const apiBasePaese = useQuery(
    query_base_paese,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseProvincia = useQuery(
    query_base_provincia,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiFascicoli = useQuery(
    query_fascicolo_byTenant,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  //#endregion

  //#region Mutation
  const apiAddLog = useMutation(mutation_add_log, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddAudit = useMutation(mutation_add_audit, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddLastActivity = useMutation(mutation_add_lastActivity_byUserId, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiDeleteLastActivity = useMutation(mutation_delete_lastActivity, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiDeleteFascicolo = useMutation(mutation_delete_fascicolo, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  //#endregion

  //#region UseStates
  const [backDropOpen, setBackDropOpen] = useState(true); //Loader utilizzato durante il caricamento
  const [isDataLoaded, setIsDataLoaded] = useState(false); //Indica lo stato di caricamento del result Api
  const [isTenantEnabled, setIsTenantEnabled] = useState(false);
  const [userPermissions, setUserPermissions] = useState(
    new userPermissionModel(
      0,
      aziendaId,
      bsaFascicolo,
      false,
      false,
      false,
      false
    )
  );
  const [dataItems, setDataItems] = useState([]); //Contiene i records
  const [dataColumns, setDataColumns] = useState([]); //Contiene le columns
  const [dataRows, setDataRows] = useState([]); //Contiene le rows
  const [showDeleteModal, setShowDeleteModal] = useState(false); //Modal per la conferma di eliminazione di un record
  const [idToPerform, setIdToPerform] = useState(0); //Contiene l'id del record da eliminare
  //#endregion

  useEffect(() => {
    loadDatas();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataLoaded]);
  
  async function loadDatas() {
    try {
      if (
        sharedDatas.getPropertyByName("tenantId") === "" ||
        sharedDatas.getPropertyByName("tenantModel") === null ||
        sharedDatas.getPropertyByName("aziendaId") === 0 ||
        sharedDatas.getPropertyByName("aziendaModelList") === null ||
        sharedDatas.getPropertyByName("userId") === "" ||
        sharedDatas.getPropertyByName("userModel") === null
      ) {
        navigate("/");
      } else {
        setBackDropOpen(false);

        if (!isDataLoaded) {
          //Last Activity
          await apiDeleteLastActivity.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            userId: loggedUserId,
          });
          await apiAddLastActivity.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            path: pathFascicoloHome,
            entityId: "",
            entityName: "",
          });

          /**************************************/
          //Carico le informazioni relative al Tenant
          const tenantInfos = sharedDatas.getPropertyByName("tenantModel");
          const isEnabled =
            !tenantInfos.isReadOnly &&
            !tenantInfos.isDisabled &&
            !tenantInfos.isDeleted;
          setIsTenantEnabled(isEnabled);

          /**************************************/
          //Carico i permessi relativi all'utente
          const userPerms = sharedDatas
            .getPropertyByName("userPermissions")
            .filter((x) => x.area === bsaFascicolo)[0];
          setUserPermissions(userPerms);

          //Recupero le countries
          const tbPaeseResponse = await apiBasePaese.fetch();
          let arrPaeseItems = [];
          tbPaeseResponse.data.data.base_paese.map((item) =>
            arrPaeseItems.push(
              new basePaeseModel(
                item.Id,
                item.Nome,
                item.Identificativo,
                item.IsRischio
              )
            )
          );

          //Recupero le province
          const tbProvinceResponse = await apiBaseProvincia.fetch();
          let arrProvinceItems = [];
          tbProvinceResponse.data.data.base_provincia.map((item) =>
            arrProvinceItems.push(
              new baseProvinciaModel(
                item.Id,
                item.Nome,
                item.Sigla,
                item.Rischio
              )
            )
          );

          /**************************************/
          //Carico i fascicoli
          setDataColumns([
            { field: "id", headerName: "Id", type: "number", flex: 0.2 },
            {
              field: "status",
              headerName: "Stato",
              type: "string",
              flex: 0.2,
              renderCell: (params) => renderStatusFascicolo(params.row),
              valueGetter: (params) => renderStatusFascicoloS(params.row),
            },
            {
              field: "name",
              headerName: "Intestazione",
              type: "string",
              flex: 1,
              editable: false,
            },
            {
              field: "isPrivate",
              headerName: t("fascicolo.form.privato"),
              type: "string",
              flex: 0.3,
              editable: false,
            },
            {
              field: "rischioAreaGeo",
              headerName: "Rischio area geografica cliente",
              type: "string",
              flex: 0.4,
              renderCell: (params) => renderLivelloRischio(params.row),
              valueGetter: (params) => renderLivelloRischioS(params.row),
            },
            {
              field: "createdOn",
              headerName: t("fascicolo.form.creatoIl"),
              type: "date",
              flex: 0.2,
              valueFormatter: (params) => {
                if (params.value == null) {
                  return "";
                }
                return new Date(params.value).toLocaleDateString();
              },
            },
            {
              field: "modifiedOn",
              headerName: t("fascicolo.form.modificatoIl"),
              type: "dateTime",
              flex: 0.2,
              valueFormatter: (params) => {
                if (params.value == null) {
                  return "";
                }
                return new Date(params.value).toLocaleDateString();
              },
            },
            {
              field: "actions",
              headerName: "",
              flex: 0.2,
              filterable: false,
              renderCell: (params) =>
                renderDetailsButton(userPerms, params.row),
            },
          ]);

          const fascicoliResponse = await apiFascicoli.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            userId: loggedUserId,
          });
          const arrItems = [];

          for (
            var i = 0;
            i < fascicoliResponse.data.data.fascicolo.length;
            i++
          ) {
            const item = fascicoliResponse.data.data.fascicolo[i];
            const status = await getFascicoloStatus(item);
            let statusReasons = "";
            if (!status.status) {
              for (let i = 0; i < status.reasons.length; i++) {
                statusReasons = statusReasons + status.reasons[i] + "\n";
              }
            }

            //Recupero l'ultima versione
            const arrCf = [];
            let rischioAreaGeo = 1;
            for (let i = 0; i < item.anagrafica_fascicolos.length; i++) {
              if (
                item.anagrafica_fascicolos.filter(
                  (x) => x.base_tipo_anagrafica.Key === "tipo_anagrafica_1"
                ) ||
                item.anagrafica_fascicolos.filter(
                  (x) => x.base_tipo_anagrafica.Key === "tipo_anagrafica_2"
                )
              ) {
                const lastVersion = item.anagrafica_fascicolos.filter(
                  (x) => x.Cf === item.anagrafica_fascicolos[i].Cf
                );
                lastVersion.sort((a, b) => b.versionN - a.versionN); //ordino per versionN desc

                const anagItem = lastVersion[0];

                //Rischio area geografica
                if (
                  anagItem.anagrafica.AddrPaeseId !== null &&
                  anagItem.anagrafica.AddrPaeseId > 0
                ) {
                  const country = arrPaeseItems.filter(
                    (x) => x.id === anagItem.anagrafica.AddrPaeseId
                  )[0];
                  if (country.identificativo === "ITA") {
                    if (anagItem.anagrafica.AddrProvinciaId > 0) {
                      const provincia = arrProvinceItems.filter(
                        (x) => x.id === anagItem.anagrafica.AddrProvinciaId
                      )[0];
                      rischioAreaGeo = provincia.rischio;
                    }
                  } else {
                    rischioAreaGeo = 4;
                  }
                }

                arrCf.push(item.Cf);
              }
            }

            const itemModel = {
              id: item.Id,
              status: status.status,
              statusReasons: statusReasons,
              name: item.Name,
              isPrivate: item.IsPrivate ? "Sì" : "No",
              rischioAreaGeo: rischioAreaGeo,
              createdOn: item.CreatedOn,
              modifiedOn: item.ModifiedOn,
            };
            arrItems.push(itemModel);
          }
          setDataItems(arrItems);
          setDataRows(arrItems);
        }
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "FascicoloHome",
        method: "loadDatas",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
      setIsDataLoaded(true);
    }
  }

  //Add New Button
  const handleAddNew = () => {
    // setLocalStorageSelectedFascicolo(0);
    // navigate(pathFascicoloAdd);
    navigate(pathFascicoloNew);
  };

  //#region Grid Button Detail
  const renderStatusFascicolo = (item) => {
    const status = item.status;
    const statusReasons = item.statusReasons;
    let livelloS = "Incompleto";
    let livelloC = chipOrange;

    if(item.status) {
        livelloS = "Completo";
        livelloC = chipGreen;
    } else {
        livelloS = "Incompleto";
        livelloC = chipOrange;
    }

    if (status) {
      return (
        <div style={{ ...(status === false ? divOrange : divGreen) }}>
          {livelloS}
        </div>
      );
    } else {
      return (
        <Tooltip
          title={
            <span style={{ whiteSpace: "pre-line" }}>{statusReasons}</span>
          }
          arrow
          placement='right'
        >
          <div style={{ ...(status === false ? divOrange : divGreen) }}>
            {livelloS}
          </div>
        </Tooltip>
      );
    }
  };
  const renderStatusFascicoloS = (item) => {
    let livelloS = "Incompleto";

    switch (item.status) {
      case true: {
        livelloS = "Completo";
        break;
      }
      case false: {
        livelloS = "Incompleto";
        break;
      }
      default: {
        break;
      }
    }

    return livelloS;
  };
  const renderLivelloRischio = (item) => {
    let livelloS = t("valutazione.form.rischioInerenteLivello1");
    let livelloC = chipGreen;

    switch (item.rischioAreaGeo) {
      case 1: {
        livelloS = t("valutazione.form.rischioInerenteLivello1");
        livelloC = chipGreen;
        break;
      }
      case 2: {
        livelloS = t("valutazione.form.rischioInerenteLivello2");
        livelloC = chipYellow;
        break;
      }
      case 3: {
        livelloS = t("valutazione.form.rischioInerenteLivello3");
        livelloC = chipOrange;
        break;
      }
      case 4: {
        livelloS = t("valutazione.form.rischioInerenteLivello4");
        livelloC = chipRed;
        break;
      }
      default: {
        break;
      }
    }

    return <VaporTag variant='standard' label={livelloS} sx={livelloC} />;
  };
  const renderLivelloRischioS = (item) => {
    let livelloS = t("valutazione.form.rischioInerenteLivello1");

    switch (item.rischioAreaGeo) {
      case 1: {
        livelloS = t("valutazione.form.rischioInerenteLivello1");
        break;
      }
      case 2: {
        livelloS = t("valutazione.form.rischioInerenteLivello2");
        break;
      }
      case 3: {
        livelloS = t("valutazione.form.rischioInerenteLivello3");
        break;
      }
      case 4: {
        livelloS = t("valutazione.form.rischioInerenteLivello4");
        break;
      }
      default: {
        break;
      }
    }

    return livelloS;
  };
  const renderDetailsButton = (userPerms, item) => {
    return (
      <Stack direction='row' spacing={0}>
        {userPerms.canDelete ? (
          <IconButton
            color='error'
            onClick={(e) => handleGridBtnDeleteClick(e, item)}
          >
            <DeleteIcon />
          </IconButton>
        ) : null}
        {userPerms.canRead ? (
          <IconButton
            color='primary'
            onClick={(e) => handleGridBtnDetailClick(e, item)}
          >
            <DetailIcon />
          </IconButton>
        ) : null}
      </Stack>
    );
  };
  const handleGridBtnDetailClick = async (event, item) => {
    event.stopPropagation();
    setLocalStorageSelectedFascicolo(item.id);
    navigate(pathFascicoloAdd);
  };
  const handleGridBtnDeleteClick = (event, item) => {
    event.stopPropagation();
    setIdToPerform(item.id);
    setShowDeleteModal(true);
  };
  //#endregion

  //#region Modal Delete
  const handleModalDeleteClose = () => {
    setIdToPerform(0);
    setShowDeleteModal(false);
  };
  const handleModalDeleteOkClick = async () => {
    try {
      //Eliminazione singola
      if (idToPerform > 0) {
        setBackDropOpen(true);

        await apiDeleteFascicolo
          .fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            id: parseInt(idToPerform),
          })
          .then((response) => {
            if (response.data.errors !== undefined) {
              enqueueSnackbar(t("error.generale"), { variant: "error" });
            } else {
              //Aggiungo la Audit per tracciare l'azione
              const itemById = JSON.stringify(
                dataItems.filter((item) => item.id === parseInt(idToPerform))[0]
              );
              apiAddAudit.fetch({
                tenantId: tenantId,
                aziendaId: aziendaId,
                area: bsaFascicolo,
                operation: "Delete",
                entityType: "fascicolo",
                entityId: idToPerform.toString(),
                oldValues: itemById,
                newValues: "",
              });

              //Resetto le variabili
              setIdToPerform(0);
              setShowDeleteModal(false);
              setIsDataLoaded(false);
              //loadDatas();
              enqueueSnackbar(t("message.success"), { variant: "success" });
            }
          })
          .catch((e) => {
            apiAddLog.fetch({
              tenantId: sharedDatas.getPropertyByName("tenantId"),
              aziendaId: sharedDatas.getPropertyByName("aziendaId"),
              level: "ERROR",
              message: e.message,
              stack: e.stack,
              area: "FascicoloHome",
              method: "handleModalDeleteOkClick/apiDeleteFascicolo.fetch",
              version: sharedDatas.getPropertyByName("version"),
            });
          });

        setBackDropOpen(false);
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "FascicoloHome",
        method: "handleModalDeleteOkClick",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    }
  };
  //#endregion

  //#region Reutrning Graphics
  return (
    <VaporPage
      title={t("nav.fascicoli")}
      headerRight={
        <Button
          variant='text'
          size='medium'
          endIcon={<AddIcon />}
          color='primary'
          onClick={handleAddNew}
          disabled={!isTenantEnabled || !userPermissions.canCreate}
          sx={{ mr: 2 }}
        >
          {t("actions.nuovo")}
        </Button>
      }
    >
      <Loading open={backDropOpen} />

      {isDataLoaded && !userPermissions.canRead ? <NotAuth /> : null}

      {showDeleteModal ? (
        <ModalDelete
          show={showDeleteModal}
          onClose={handleModalDeleteClose}
          onOk={handleModalDeleteOkClick}
        ></ModalDelete>
      ) : null}

      {isDataLoaded && userPermissions.canRead ? (
        <VaporPage.Section>
          <Grid container>
            <Grid item xs={12} sx={{ width: "100%" }}>
              {dataRows.length > 0 ? (
                <DataGridPro
                  rows={dataRows}
                  columns={dataColumns}
                  slots={{ toolbar: GridToolbar }}
                  getRowId={(row) => row.id}
                  autoHeight={true}
                  pageSizeOptions={[25, 50, 100]}
                  localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                  }
                  pagination
                  disableRowSelectionOnClick
                  onRowClick={(item, e) =>
                    handleGridBtnDetailClick(e, item.row)
                  }
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        id: false,
                      },
                    },
                    pagination: {
                      paginationModel: {
                        pageSize: 25,
                      },
                    },
                  }}
                  slotProps={{
                    toolbar: { printOptions: { disableToolbarButton: true } },
                  }}
                />
              ) : (
                <NoData />
              )}
            </Grid>
          </Grid>
        </VaporPage.Section>
      ) : null}
    </VaporPage>
  );
  //#endregion
};
