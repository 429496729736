//#region Imports
import React, { useState, useEffect } from "react";
import sharedDatas from "../../../businessLogic/sharedDatas";
import { useNavigate } from "react-router-dom";

//oneFront SDK
import {
  useSnackbar,
  useQuery,
  useMutation,
  useTranslation,
} from "@onefront/react-sdk";

import VaporPage from "@vapor/react-custom/VaporPage";

//Vapor Components
import Grid from "@vapor/react-material/Grid";
import Stack from "@vapor/react-material/Stack";
import Modal from "@vapor/react-material/Modal";
import Box from "@vapor/react-material/Box";
import Divider from "@vapor/react-material/Divider";
import Button from "@vapor/react-material/Button";
import IconButton from "@vapor/react-material/IconButton";
import Table from "@vapor/react-material/Table";
import TableBody from "@vapor/react-material/TableBody";
import TableCell from "@vapor/react-material/TableCell";
import TableContainer from "@vapor/react-material/TableContainer";
import TableHead from "@vapor/react-material/TableHead";
import TableRow from "@vapor/react-material/TableRow";

import ExtendedTypography from "@vapor/react-extended/ExtendedTypography";

import {
  DataGridPro,
  GridToolbar,
  itIT,
} from "@mui/x-data-grid-pro";

//Icons
import { ArrowForward as DetailIcon } from "@mui/icons-material";

//Custom componentes
import { Loading } from "../../../components/Loading";
import { NotAuth } from "../../../components/NotAuth";
import { NoData } from "../../../components/NoData";

//Constants, Api and Data Models
import {
  bsaSettingsAudit,
  pathImpAuditHome,
} from "../../../businessLogic/constants";
import { userPermissionModel, auditModel } from "../../../businessLogic/models";
import { query_audit } from "../../../businessLogic/query";
import {
  mutation_add_log,
  mutation_add_lastActivity_byUserId,
  mutation_delete_lastActivity,
} from "../../../businessLogic/mutation";

//Style
import { modalStyleLarge } from "../../../businessLogic/styles";
//#endregion

export const AuditHome = ({ basePath }) => {
  const tenantId = sharedDatas.getPropertyByName("tenantId");
  const aziendaId = sharedDatas.getPropertyByName("aziendaId");
  const loggedUserId = sharedDatas.getPropertyByName("userId");
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  //Query
  const apiAudit = useQuery(
    query_audit,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );

  //#region Mutation
  const apiAddLog = useMutation(mutation_add_log, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddLastActivity = useMutation(mutation_add_lastActivity_byUserId, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiDeleteLastActivity = useMutation(mutation_delete_lastActivity, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  //#endregion

  //#region Usestates
  const [backDropOpen, setBackDropOpen] = useState(true); //Loader utilizzato durante il caricamento
  const [isDataLoaded, setIsDataLoaded] = useState(false); //Indica lo stato di caricamento del result Api
  const [userPermissions, setUserPermissions] = useState(
    new userPermissionModel(
      0,
      aziendaId,
      bsaSettingsAudit,
      false,
      false,
      false,
      false
    )
  );
  const [, setDataItems] = useState([]); //Contiene i records
  const [dataColumns, setDataColumns] = useState([]); //Contiene le columns
  const [dataRows, setDataRows] = useState([]); //Contiene le rows
  const [showModal, setShowModal] = useState(false);
  const [detailKeys, setDetailKeys] = useState([]);
  const [detailOldValues, setDetailOldValues] = useState(null);
  const [detailNewValues, setDetailNewValues] = useState(null);
  //#endregion

  useEffect(() => {
    loadDatas();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataLoaded]);

  async function loadDatas() {
    try {
      if (
        sharedDatas.getPropertyByName("tenantId") === "" ||
        sharedDatas.getPropertyByName("tenantModel") === null ||
        sharedDatas.getPropertyByName("aziendaId") === 0 ||
        sharedDatas.getPropertyByName("aziendaModelList") === null ||
        sharedDatas.getPropertyByName("userId") === "" ||
        sharedDatas.getPropertyByName("userModel") === null
      ) {
        navigate("/");
      } else {
        setBackDropOpen(true);

        if (!isDataLoaded) {
          //Last Activity
          await apiDeleteLastActivity.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            userId: loggedUserId,
          });
          await apiAddLastActivity.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            path: pathImpAuditHome,
            entityId: "",
            entityName: "",
          });

          /**************************************/
          //Carico i permessi relativi all'utente
          const userPerms = sharedDatas
            .getPropertyByName("userPermissions")
            .filter((x) => x.area === bsaSettingsAudit)[0];
          setUserPermissions(userPerms);

          /**************************************/
          //Carico le Audit
          setDataColumns([
            { field: "id", headerName: "Id", type: "number", flex: 0.2 },
            {
              field: "userId",
              headerName: t("impostazioni.audit.form.user"),
              flex: 0.2,
              editable: false,
            },
            {
              field: "area",
              headerName: t("impostazioni.audit.form.area"),
              flex: 0.2,
              editable: false,
            },
            {
              field: "operation",
              headerName: t("impostazioni.audit.form.operazione"),
              flex: 0.2,
              editable: false,
            },
            {
              field: "operationDate",
              headerName: t("impostazioni.audit.form.data"),
              type: "dateTime",
              flex: 0.2,
              valueFormatter: (params) => {
                if (params.value == null) {
                  return "";
                }
                return new Date(params.value).toLocaleDateString();
              },
            },
            {
              field: "entityType",
              headerName: t("impostazioni.audit.form.entita"),
              flex: 0.3,
              editable: false,
            },
            {
              field: "entityId",
              headerName: t("impostazioni.audit.form.idEntita"),
              type: "number",
              flex: 0.3,
              editable: false,
            },
            {
              field: "actions",
              headerName: "",
              flex: 0.2,
              filterable: false,
              renderCell: (params) =>
                renderDetailsButton(userPerms, params.row),
            },
          ]);

          const auditResponse = await apiAudit.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
          });
          const arrItems = [];
          for (let i = 0; i < auditResponse.data.data.audit.length; i++) {
            const item = auditResponse.data.data.audit[i];
            const itemModel = new auditModel(
              item.Id,
              item.user.Name + " " + item.user.Surname,
              item.Area,
              item.Operation,
              item.OperationDate,
              item.EntityType,
              item.EntityId,
              item.OldValues,
              item.NewValues
            );
            arrItems.push(itemModel);
          }
          setDataItems(arrItems);
          setDataRows(arrItems);
        }
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "AuditHome",
        method: "loadDatas",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
      setIsDataLoaded(true);
    }
  }

  //#region Grid Button Detail
  const renderDetailsButton = (userPerms, item) => {
    return (
      <Stack direction="row" spacing={0}>
        {userPerms.canRead ? (
          <IconButton
            color="primary"
            onClick={(e) => handleGridBtnDetailClick(e, item)}
          >
            <DetailIcon />
          </IconButton>
        ) : null}
      </Stack>
    );
  };
  const handleGridBtnDetailClick = (event, item) => {
    event.stopPropagation();
    loadDetail(item);
  };
  const loadDetail = (item) => {
    const oldValues = item.oldValues;
    const newValues = item.newValues;
    let keys = null;

    let oldValuesObj = null;
    if (oldValues !== undefined && oldValues !== "") {
      oldValuesObj = JSON.parse(oldValues);
      keys = Object.keys(oldValuesObj);
    }

    let newValuesObj = null;
    if (newValues !== undefined && newValues !== "") {
      newValuesObj = JSON.parse(newValues);
      keys = Object.keys(newValuesObj);
    }

    setDetailOldValues(oldValuesObj);
    setDetailNewValues(newValuesObj);

    let arrKeys = [];
    keys.forEach((key) => {
      arrKeys.push(key);
    });
    setDetailKeys(arrKeys);
    setShowModal(true);
  };
  const handleCloseModalClick = () => {
    setShowModal(false);
  };
  //#endregion

  //#region Return Graphics
  return (
    <VaporPage title={t("nav.audit")}>
      <Loading open={backDropOpen} />

      {isDataLoaded && !userPermissions.canRead ? <NotAuth /> : null}

      {isDataLoaded && userPermissions.canRead ? (
        <VaporPage.Section>
          <Modal
            key="addModal"
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            open={showModal}
          >
            <Box sx={modalStyleLarge}>
              <Stack
                direction="column"
                spacing={0}
                justifyContent="left"
                alignItems="left"
              >
                <ExtendedTypography
                  variant="titleSmall"
                  color="primary.main"
                  sx={{ p: 2, pl: 3 }}
                >
                  {t("impostazioni.audit.dettaglio")}
                </ExtendedTypography>
                <Divider orientation="horizontal" flexItem />
              </Stack>
              <Stack
                direction="column"
                spacing={0}
                justifyContent="left"
                alignItems="left"
                sx={{ overflowY: "scroll", height: 500 }}
              >
                <Box sx={{ p: 3 }}>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left" width="30%">
                            {t("impostazioni.audit.proprieta")}
                          </TableCell>
                          <TableCell align="left" width="35%">
                            {t("impostazioni.audit.oldValues")}
                          </TableCell>
                          <TableCell align="left" width="35%">
                            {t("impostazioni.audit.newValues")}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {detailKeys.map((key) => (
                          <TableRow
                            key={key}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            hover
                          >
                            <TableCell align="left" width="30%">
                              {key}
                            </TableCell>
                            <TableCell align="left" width="35%">
                              {detailOldValues !== null &&
                              typeof detailOldValues[key] !== "object"
                                ? detailOldValues[key].toString()
                                : null}
                              {detailOldValues !== null &&
                              typeof detailOldValues[key] === "object"
                                ? JSON.stringify(detailOldValues[key])
                                : null}
                              {detailOldValues !== null &&
                              detailOldValues[key] === null
                                ? "-"
                                : null}
                            </TableCell>
                            <TableCell align="left" width="35%">
                              {detailNewValues !== null &&
                              typeof detailNewValues[key] !== "object"
                                ? detailNewValues[key].toString()
                                : null}
                              {detailNewValues !== null &&
                              typeof detailNewValues[key] === "object"
                                ? JSON.stringify(detailNewValues[key])
                                : null}
                              {detailNewValues !== null &&
                              detailNewValues[key] === null
                                ? "-"
                                : null}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  p: 1,
                  mt: 1,
                  bgcolor: "#F2F5F8",
                  position: "fixed",
                  bottom: 0,
                  width: "100%",
                }}
                justifyContent="right"
                alignItems="right"
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleCloseModalClick}
                >
                  {t("actions.chiudi")}
                </Button>
              </Stack>
            </Box>
          </Modal>

          <Grid container>
            <Grid item xs={12} sx={{ width: "100%" }}>              
              {dataRows.length > 0 ? (
                <DataGridPro
                  rows={dataRows}
                  columns={dataColumns}
                  slots={{ toolbar: GridToolbar }}
                  pageSizeOptions={[25, 50, 100]}
                  localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                  }
                  pagination
                  disableRowSelectionOnClick
                  onRowClick={(item, e) =>
                    handleGridBtnDetailClick(e, item.row)
                  }
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        id: false,
                      },
                    },
                    pagination: {
                      paginationModel: {
                        pageSize: 25,
                      },
                    },
                  }}
                  slotProps={{
                    toolbar: { printOptions: { disableToolbarButton: true } },
                  }}
                />
              ) : (
                <NoData />
              )}
            </Grid>
          </Grid>
        </VaporPage.Section>
      ) : null}
    </VaporPage>
  );
  //#endregion
};
