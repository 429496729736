//#region Imports
import React, { useState, Fragment } from "react";
import sharedDatas from "../../businessLogic/sharedDatas";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "@onefront/react-sdk";

//Vapor Components
import Stack from "@vapor/react-material/Stack";
import Divider from "@vapor/react-material/Divider";
import Menu from "@vapor/react-material/Menu";
import MenuItem from "@vapor/react-material/MenuItem";
import Button from "@vapor/react-material/Button";
import ListItemIcon from "@vapor/react-material/ListItemIcon";
import IconButton from "@vapor/react-material/IconButton";

import VaporTag from "@vapor/react-custom/VaporTag";

import ExtendedTypography from "@vapor/react-extended/ExtendedTypography";

//Icons
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Business as BusinessIcon,
  HelpOutline as HelpOutlineIcon,
  FileDownload as DownloadIcon
} from "@mui/icons-material";

//Constants, Api and Data Models
import { pathHome, pathAziendaHome, pathExportDatiHome } from "../../businessLogic/constants";

//Other components
import postal from "postal";
//#endregion

export const AppTitleRight = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isAziendeLoaded, setIsAziendeLoaded] = useState(false);
  const [isUserLoaded, setIsUserLoaded] = useState(false);
  const [isUserOwner, setIsUserOwner] = useState(false);

  const [isTenantEnabled, setIsTenantEnabled] = useState(true);
  const [userName, setUserName] = useState("");
  const [userType, setUserTypeS] = useState("");

  const [aziendeList, setAziendeList] = useState([]);

  //Rimango in ascolto quando ci sono modifiche al tenant corrente
  // eslint-disable-next-line no-unused-vars
  const enableTenant = postal.subscribe({
    channel: "tenant",
    topic: "tenant.enableTenant",
    callback: function (data, envelope) {
      const enabled = data.enabled;
      setIsTenantEnabled(enabled);
    },
  });

  //Rimango in ascolto quando ci sono modifiche ai dati delle aziende
  // eslint-disable-next-line no-unused-vars
  const updatedAziendeList = postal.subscribe({
    channel: "azienda",
    topic: "azienda.update",
    callback: function (data, envelope) {
      const items = data.aziende;
      setAziendeList(items);
      setIsAziendeLoaded(true);
    },
  });

  //Rimango in ascolto quando ci sono modifiche ai dati dell'utente corrente
  // eslint-disable-next-line no-unused-vars
  const updatedLoggedUser = postal.subscribe({
    channel: "user",
    topic: "user.updatedLoggedUser",
    callback: function (data, envelope) {
      //const item = data.user;
      const item = sharedDatas.getPropertyByName("userModel");
      setIsUserOwner(item.type === "OWNER");

      if (!isUserLoaded) {       
        setUserTypeS(item.tenantTipoS);
        setUserName(item.completeName);
        setIsUserLoaded(true);
      }
    },
  });

  //#region Menù Aziende
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (id) => {
    if (parseInt(id) === 0) {
      navigate(pathAziendaHome);
    } else if (parseInt(id) === -1){
      navigate(pathExportDatiHome)
    } else if (parseInt(id) > 0) {
      sharedDatas.setPropertyValue("aziendaId", parseInt(id));
      navigate(pathHome);
    }

    setAnchorEl(null);
  };
  //#endregion

  //#region Menù Help
  const [anchorElHelp, setAnchorElHelp] = useState(null);
  const openHelp = Boolean(anchorElHelp);
  const handleHelpClick = (event) => {
    setAnchorElHelp(event.currentTarget);
  };
  const handleHelpClose = (id) => {
    if (id === "guide") {
      window.open(
        "https://cs.teamsystem.com/cs3public/showdoc.aspx?IdDoc=63887&User=7279175&IdProdotto=1002082&IdVersione=974",
        "_blank",
        "noopener,noreferrer"
      );
    } else if (id === "importguide") {
      window.open(
        "https://cs.teamsystem.com/cs3public/showdoc.aspx?IdDoc=63890&User=7279175&IdProdotto=1002082&IdVersione=974",
        "_blank",
        "noopener,noreferrer"
      );
    }

    setAnchorElHelp(null);
  };
  //#endregion

  //#region Return Graphics
  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="center"
      alignItems="center"
    >
      {!isTenantEnabled ? (
        <Fragment>
          <VaporTag
            variant="standard"
            type="rustyRed"
            label="La tua licenza è scaduta"
          />
          <Divider orientation="vertical" flexItem />
        </Fragment>
      ) : null}

      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
        divider={<Divider orientation="vertical" flexItem />}
        sx={{ pr: 2 }}
      >
        {isAziendeLoaded ? (
          <Fragment>
            <Button
              id="aziendeMenuButton"
              aria-controls={open ? "aziendeMenu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              disableelevation="true"
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}
              size="small"
            >
              {
                aziendeList.filter(
                  (item) =>
                    item.id === sharedDatas.getPropertyByName("aziendaId")
                )[0].denominazione
              }
            </Button>
            <Menu
              id="aziendeMenu"
              aria-labelledby="aziendeMenuButton"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
              {aziendeList.map((item) => (
                <MenuItem
                  key={item.id}
                  dense
                  onClick={() => handleClose(item.id)}
                  selected={
                    item.id === sharedDatas.getPropertyByName("aziendaId")
                  }
                >
                  <ExtendedTypography variant="body2">
                    {item.denominazione}
                  </ExtendedTypography>
                </MenuItem>
              ))}
              {isUserOwner ? (
                <div>
                  <Divider
                    variant="standard"
                    sx={{ mt: "0px !important", mb: "0px !important" }}
                  />
                  <MenuItem dense onClick={() => handleClose(0)}>
                    <ListItemIcon>
                      <BusinessIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <ExtendedTypography variant="body2">
                      {t("dashboard.appTitleRight.manageSubject")}
                    </ExtendedTypography>
                  </MenuItem>
                  <Divider
                    variant="standard"
                    sx={{ mt: "0px !important", mb: "0px !important" }}
                  />
                  <MenuItem dense onClick={() => handleClose(-1)}>
                    <ListItemIcon>
                      <DownloadIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <ExtendedTypography variant="body2">
                      Esporta dati del soggetto obbligato corrente
                    </ExtendedTypography>
                  </MenuItem>
                </div>
              ) : null}
            </Menu>
          </Fragment>
        ) : null}

        {isUserLoaded ? (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-end"
          >
            <ExtendedTypography
              variant="subtitle2"
              sx={{ lineHeight: 1.2 }}
              noWrap
            >
              {userName}
            </ExtendedTypography>
            <ExtendedTypography variant="subtitle2" noWrap>
              {userType}
            </ExtendedTypography>
          </Stack>
        ) : null}

        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-end"
          sx={{ ml: "0 !important" }}
        >
          <IconButton
            id="helpMenuButton"
            aria-controls={openHelp ? "helpMenu" : undefined}
            aria-haspopup="true"
            aria-expanded={openHelp ? "true" : undefined}
            disableelevation="true"
            onClick={handleHelpClick}
            size="large"
          >
            <HelpOutlineIcon
              fontSize="large"
              sx={{ fontSize: "20px !important", color: "white" }}
            />
          </IconButton>
          <Menu
            id="helpMenu"
            aria-labelledby="helpMenuButton"
            anchorEl={anchorElHelp}
            open={openHelp}
            onClose={handleHelpClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <MenuItem key={0} dense onClick={() => handleHelpClose("guide")}>
              <ExtendedTypography variant="body2">
                {t("dashboard.appTitleRight.downloadGuide")}
              </ExtendedTypography>
            </MenuItem>
            <MenuItem
              key={0}
              dense
              onClick={() => handleHelpClose("importguide")}
            >
              <ExtendedTypography variant="body2">
                {t("dashboard.appTitleRight.downloadImport")}
              </ExtendedTypography>
            </MenuItem>
            <Divider
              variant="standard"
              sx={{ mt: "0px !important", mb: "0px !important" }}
            />
            <MenuItem key={1} dense>
              <ExtendedTypography variant="body2">
                {t("dashboard.appTitleRight.version")}{" "}
                {sharedDatas.getPropertyByName("version")}
              </ExtendedTypography>
            </MenuItem>
          </Menu>
        </Stack>
      </Stack>
    </Stack>
  );
  //#endregion
};
