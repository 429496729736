//#region Imports
import React, { useState, useEffect, Fragment } from "react";
import sharedDatas from "../../businessLogic/sharedDatas";

//oneFront SDK
import {
  useSnackbar,
  useQuery,
  useMutation,
  useGet,
  useTranslation,
} from "@onefront/react-sdk";

import VaporPage from "@vapor/react-custom/VaporPage";
import VaporToolbar from "@vapor/react-custom/VaporToolbar";
import VaporTag from "@vapor/react-custom/VaporTag";

//Vapor Components
import Divider from "@vapor/react-material/Divider";
import Stack from "@vapor/react-material/Stack";
import Checkbox from "@vapor/react-material/Checkbox";
import Slider from "@vapor/react-material/Slider";
import Table from "@vapor/react-material/Table";
import TableBody from "@vapor/react-material/TableBody";
import TableCell from "@vapor/react-material/TableCell";
import TableContainer from "@vapor/react-material/TableContainer";
import TableHead from "@vapor/react-material/TableHead";
import TableRow from "@vapor/react-material/TableRow";
import Paper from "@vapor/react-material/Paper";
import TextField from "@vapor/react-material/TextField";
import Autocomplete from "@vapor/react-material/Autocomplete";
import Button from "@vapor/react-material/Button";
import IconButton from "@vapor/react-material/IconButton";
import Box from "@vapor/react-material/Box";
import FormGroup from "@vapor/react-material/FormGroup";
import FormControlLabel from "@vapor/react-material/FormControlLabel";
import Alert from "@vapor/react-material/Alert";
import Radio from '@mui/material/Radio';


import ExtendedTypography from "@vapor/react-extended/ExtendedTypography";

//Vapor Date Pickers
import { AdapterDateFns } from "@vapor/react-x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@vapor/react-x-date-pickers/LocalizationProvider";
import { DatePicker } from "@vapor/react-x-date-pickers/DatePicker";
import { it } from "date-fns/locale";

//Custom styles
import {
  chipGreen,
  chipYellow,
  chipOrange,
  chipRed,
} from "../../businessLogic/styles";

//Icons
import {
  AddOutlined as AddIcon,
  ModeEdit as ModeEditIcon,
  Delete as DeleteIcon,
  SaveOutlined as SaveIcon,
  CloseOutlined as CancelIcon,
} from "@mui/icons-material";

//Custom components
import { Loading } from "../Loading";
import { NotAuth } from "../NotAuth";
import { NoData } from "../NoData";
import { ModalDelete } from "../ModalDelete";

//Constants, Api and Data Models
import { bsaRischio } from "../../businessLogic/constants";
import {
  isDateValid,
  getLocalStorageSelectedEntity,
  removeLocalStorageSelectedEntity,
} from "../../businessLogic/bl";
import {
  userPermissionModel,
  rischioModel,
  tenantModel,
  userModel,
  anagraficaModel,
  prestazioneModel,
  prestazionePagamentoModel,
  basePaeseModel,
  baseProvinciaModel,
  baseTipoAnagraficaModel,
  baseRischioGradoModel,
} from "../../businessLogic/models";

import {
  query_users_byTenantId,
  query_users_byCompanyId,
  query_rischi_byPrestazione,
  query_anagrafiche_byFascicolo,
  query_prestazioni_byFascicolo,
  query_base_paese,
  query_base_provincia,
  query_base_tipo_anagrafica,
  query_base_rischio_grado_byTenantTipo,
  query_rischio_byId,
} from "../../businessLogic/query";

import {
  mutation_add_log,
  mutation_add_audit,
  mutation_add_rischio,
  mutation_update_rischio,
  mutation_delete_rischio,
  mutation_update_fascicolo,
} from "../../businessLogic/mutation";

//Other components
import { v4 as uuidv4 } from "uuid";
//#endregion

export const RischioForm = ({
  fascicolo,
  prestazione,
  reloadStatusFascicolo,
}) => {
  const tenantId = sharedDatas.getPropertyByName("tenantId");
  const aziendaId = sharedDatas.getPropertyByName("aziendaId");
  const loggedUserId = sharedDatas.getPropertyByName("userId");
  const version = sharedDatas.getPropertyByName("version");
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const rischioFromNavigation = getLocalStorageSelectedEntity();

  //#region Query
  const apiUsersByTenantId = useQuery(
    query_users_byTenantId,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiUsersByCompanyId = useQuery(
    query_users_byCompanyId,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiRischiByPrestazione = useQuery(
    query_rischi_byPrestazione,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiRischioById = useQuery(
    query_rischio_byId,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAnagraficaByFascicolo = useQuery(
    query_anagrafiche_byFascicolo,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiPrestazioniByFascicolo = useQuery(
    query_prestazioni_byFascicolo,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseTipoAnagrafica = useQuery(
    query_base_tipo_anagrafica,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseProvincia = useQuery(
    query_base_provincia,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBasePaese = useQuery(
    query_base_paese,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseRischioGradoByTenantTipo = useQuery(
    query_base_rischio_grado_byTenantTipo,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  //#endregion

  //#region Mutation
  const apiAddLog = useMutation(mutation_add_log, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddAudit = useMutation(mutation_add_audit, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddRischio = useMutation(mutation_add_rischio, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiUpdateRischio = useMutation(mutation_update_rischio, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiDeleteRischio = useMutation(mutation_delete_rischio, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiUpdateFascicolo = useMutation(mutation_update_fascicolo, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  //#endregion

  //#region UseStates
  const [backDropOpen, setBackDropOpen] = useState(true); //Loader utilizzato durante il caricamento
  const [isDataLoaded, setIsDataLoaded] = useState(false); //Indica lo stato di caricamento del result Api
  const [userPermissions, setUserPermissions] = useState(
    new userPermissionModel(
      0,
      aziendaId,
      bsaRischio,
      false,
      false,
      false,
      false
    )
  );
  const [, setTenant] = useState(new tenantModel());
  const [tenantTypeId, setTenantTypeId] = useState(0);
  const [currentPrestazioniList, setCurrentPrestazioniList] = useState([]); //Contiene le prestazioni correnti
  const [currentPrestazione, setCurrentPrestazione] = useState(
    new prestazioneModel(
      0,
      "",
      0,
      "",
      1.0,
      "",
      null,
      null,
      0,
      0,
      "",
      0,
      "",
      0,
      0,
      "",
      false,
      null,
      null,
      [],
      [],
      "",
      false,
      "",
      "",
      [],
      0,
      loggedUserId
    )
  ); //Contiene la prestazione selezionata

  //Tabelle di base
  const [basePaeseList, setBasePaeseList] = useState([]); //base_paese
  const [baseProvinciaList, setBaseProvinciaList] = useState([]); //base_provincia
  const [, setBaseTipoAnagList] = useState([]); //base_tipo_anagrafica
  const [baseRischioGradoList, setBaseRischioGradoList] = useState([]); //Contiene i gradi di rischio per il tipo di tenant

  //Valutazione Rischio corrente
  const [showRischiForm, setShowRischiForm] = useState(false);
  const [currentRischiList, setCurrentRischiList] = useState([]); //Contiene le valutazioni correnti
  const [currentRischio, setCurrentRischio] = useState(new rischioModel(-1)); //Contiene il rischio corrente
  const [currentRischioOriginal, setCurrentRischioOriginal] = useState(
    new rischioModel(0, new Date(), prestazione)
  ); //Used for Audit
  const [sliderVal, setSliderVal] = useState({
    sliderA1: 1,
    sliderA2: 1,
    sliderA3: 1,
    sliderA4: 1,
    sliderB1: 1,
    sliderB2: 1,
    sliderB3: 1,
    sliderB4: 1,
    sliderB5: 1,
    sliderB6: 1,
    sliderC1: 1,
    sliderC2: 1,
    sliderC3: 1,
  });
  const [chbVal, setChbVal] = useState({
    chbA1_1: false,
    chbA1_2: false,
    chbA1_3: false,
    chbA1_4: false,
    chbA1_5: false,
    chbA1_6: false,
    chbA1_7: false,
    chbA1_Altro: false,
    chbA2_1: false,
    chbA2_2: false,
    chbA2_3: false,
    chbA2_Altro: false,
    chbA3_1: false,
    chbA3_2: false,
    chbA3_3: false,
    chbA3_4: false,
    chbA3_Altro: false,
    chbA4_1: false,
    chbA4_2: false,
    chbA4_3: false,
    chbA4_4: false,
    chbA4_Altro: false,
    chbB1_1: false,
    chbB1_2: false,
    chbB1_3: false,
    chbB1_Altro: false,
    chbB2_1: false,
    chbB2_2: false,
    chbB2_3: false,
    chbB2_4: false,
    chbB2_Altro: false,
    chbB3_1: false,
    chbB3_2: false,
    chbB3_3: false,
    chbB3_Altro: false,
    chbB4_1: false,
    chbB4_2: false,
    chbB4_3: false,
    chbB4_Altro: false,
    chbB5_1: false,
    chbB5_2: false,
    chbB5_3: false,
    chbB5_Altro: false,
    chbB6_1: false,
    chbB6_2: false,
    chbB6_3: false,
    chbB6_4: false,
    chbB6_Altro: false,
    chbTabellaB: true,
  });
  const [selectedA1val,setSelectedA1val] = useState("")
  const [selectedA2val,setSelectedA2val] = useState("")
  const [selectedA3val,setSelectedA3val] = useState("")
  const [selectedA4val,setSelectedA4val] = useState("")
  const [selectedB1val,setSelectedB1val] = useState("")
  const [selectedB2val,setSelectedB2val] = useState("")
  const [selectedB3val,setSelectedB3val] = useState("")
  const [selectedB4val,setSelectedB4val] = useState("")
  const [selectedB5val,setSelectedB5val] = useState("")
  const [selectedB6val,setSelectedB6val] = useState("")

  const [livelloRischioAvv,setLivelloRischioAvv] = useState(chipGreen)
  const [labelRischioAvv,setLabelRischioAvv] = useState("Rischio irrilevante")

  const [showDeleteModal, setShowDeleteModal] = useState(false); //Modal per la conferma di eliminazione di un record
  const [idToPerform, setIdToPerform] = useState(0); //Contiene l'id del record da eliminare

  //Professionisti
  const [allUtentiList, setAllUtentiList] = useState([]);
  const [utentiList, setUtentiList] = useState([]);
  const [isUserAllowedToManage, setIsUserAllowedToManage] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");

  //Rischio inerente la prestazione
  const [rischioInerenteLivelloS, setRischioInerenteLivelloS] = useState(
    t("valutazione.form.rischioInerenteLivello1")
  );
  const [rischioInerenteLivelloC, setRischioInerenteLivelloC] =
    useState(chipGreen);

  //Rischio effettivo
  const [rischioEffettivoLivelloS, setRischioEffettivoLivelloS] = useState(
    t("valutazione.form.rischioInerenteLivello1")
  );
  const [rischioEffettivoLivelloC, setRischioEffettivoLivelloC] =
    useState(chipGreen);

  //Rischio area geografica
  const [rischioPaeseCliente, setRischioPaeseCliente] = useState({
    paese: "Italia",
    value: 1,
    rischio: t("valutazione.form.rischioInerenteLivello1"),
    color: chipGreen,
  });
  const [rischioPaesePrest, setRischioPaesePrest] = useState({
    paese: "Italia",
    value: 1,
    rischio: t("valutazione.form.rischioInerenteLivello1"),
    color: chipGreen,
  });
  const [rischioProvinciaCliente, setRischioProvinciaCliente] = useState({
    provincia: "Milano",
    value: 1,
    rischio: t("valutazione.form.rischioInerenteLivello1"),
    color: chipGreen,
  });
  const [rischioProvinciaPrest, setRischioProvinciaPrest] = useState({
    provincia: "Milano",
    value: 1,
    rischio: t("valutazione.form.rischioInerenteLivello1"),
    color: chipGreen,
  });
  const [cliente, setCliente] = useState(null);

  //Subtotali
  const [subTotaleA, setSubTotaleA] = useState(4);
  const [subTotaleB, setSubTotaleB] = useState(6);
  const [, setSubTotaleC] = useState(3);

  //Totali
  const [totRischioInerente, setTotRischioInerente] = useState(0);
  const [totRischioSpecifico, setTotRischioSpecifico] = useState(0);
  const [totRischioSpecificoPonderato, setTotRischioSpecificoPonderato] =
    useState(0);
  const [totRischioEffettivo, setTotRischioEffettivo] = useState(0);

  //Adeguata verifica
  const [, setLivelloAdeguataVerifica] = useState(1);
  const [livelloAdeguataVerificaS, setLivelloAdeguataVerificaS] = useState(
    t("valutazione.form.adeguataVerificaLivello1")
  );
  const [livelloAdeguataVerificaC, setLivelloAdeguataVerificaC] =
    useState(chipGreen);
  const [regoleAdeguataVerifica, setRegoleAdeguataVerifica] = useState("");
  const [contCostAdeguataVerifica, setContCostAdeguataVerifica] = useState(36);
  const [dataContCostAdeguataVerifica, setDataContCostAdeguataVerifica] =
    useState(
      new Date().setMonth(new Date().getMonth() + contCostAdeguataVerifica)
    );

  //#endregion

  const tsApiAntiRic = useGet("aml:ts-anti-ric:api://", { lazy: true });

  useEffect(() => {
    loadDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataLoaded]);

  //#region Load Methods
  async function loadDatas() {
    try {
      if (!isDataLoaded) {
        setBackDropOpen(true);

        /**************************************/
        //Carico le informazioni relative al Tenant
        setTenant(sharedDatas.getPropertyByName("tenantModel"));

        /**************************************/
        //Carico i permessi relativi all'utente
        setUserPermissions(
          sharedDatas
            .getPropertyByName("userPermissions")
            .filter((x) => x.area === bsaRischio)[0]
        );

        /**************************************/
        //Carico l'utente
        const tipoTenantId =
          sharedDatas.getPropertyByName("userModel").tenantTipoId;
        setTenantTypeId(tipoTenantId);

        /**************************************/
        //Carico gli utenti
        const allUsersResponse = await apiUsersByTenantId.fetch({
          tenantId: tenantId,
        });
        const arrAllUsers = [];
        allUsersResponse.data.data.user_tenant.map((item) =>
          arrAllUsers.push(
            new userModel(
              item.UserId,
              item.TenantId,
              item.user.Username,
              item.user.Email,
              item.user.Name,
              item.user.Surname,
              item.user.Name + " " + item.user.Surname,
              item.Type,
              item.user.Locale,
              item.TenantTipoId,
              item.user.IsRespAntiRic,
              item.base_tipo_tenant.Value,
              item.user.IsActive,
              item.user.IsDeleted,
              item.user.IsBanned,
              item.user.CreatedOn,
              item.user.Cf
            )
          )
        );
        setAllUtentiList(arrAllUsers);

        const usersResponse = await apiUsersByCompanyId.fetch({
          tenantId: tenantId,
          aziendaId: aziendaId,
        });
        const arrUsers = [];
        usersResponse.data.data.user_tenant.map((item) =>
          arrUsers.push(
            new userModel(
              item.UserId,
              item.TenantId,
              item.user.Username,
              item.user.Email,
              item.user.Name,
              item.user.Surname,
              item.user.Name + " " + item.user.Surname,
              item.Type,
              item.user.Locale,
              item.TenantTipoId,
              item.user.IsRespAntiRic,
              item.base_tipo_tenant.Value,
              item.user.IsActive,
              item.user.IsDeleted,
              item.user.IsBanned,
              item.user.CreatedOn,
              item.user.Cf
            )
          )
        );

        /*base_paese*/
        const tbPaeseResponse = await apiBasePaese.fetch();
        const arrPaeseItems = [];
        tbPaeseResponse.data.data.base_paese.map((item) =>
          arrPaeseItems.push(
            new basePaeseModel(
              item.Id,
              item.Nome,
              item.Identificativo,
              item.IsRischio
            )
          )
        );
        setBasePaeseList(arrPaeseItems);

        /*base_provincia*/
        const tbProvResponse = await apiBaseProvincia.fetch();
        const arrProvItems = [];
        tbProvResponse.data.data.base_provincia.map((item) =>
          arrProvItems.push(
            new baseProvinciaModel(item.Id, item.Nome, item.Sigla, item.Rischio)
          )
        );
        setBaseProvinciaList(arrProvItems);

        /*base_tipo_anagrafica*/
        const tbTipoAnagResponse = await apiBaseTipoAnagrafica.fetch({
          tenantId: tenantId,
        });
        const arrTipoAnagItems = [];
        tbTipoAnagResponse.data.data.base_tipo_anagrafica.map((item) =>
          arrTipoAnagItems.push(
            new baseTipoAnagraficaModel(
              item.Id,
              item.Key,
              item.Value,
              item.Order,
              item.IsSystem
            )
          )
        );
        setBaseTipoAnagList(arrTipoAnagItems);

        //Carico il cliente relativo al fascicolo
        let selCliente = null;
        if (fascicolo.id > 0) {
          const anagResponse = await apiAnagraficaByFascicolo.fetch({
            fascicoloId: fascicolo.id,
          });
          if (anagResponse.data.errors !== undefined) {
          } else {
            const arrItemsClienti = [];

            for (var i = 0; i < anagResponse.data.data.anagrafica.length; i++) {
              const item = anagResponse.data.data.anagrafica[i];

              let itemModel = new anagraficaModel(
                item.Id,
                item.ParentId,
                item.IsPg,
                item.Cf,
                item.Nome,
                item.Cognome,
                item.GenereId,
                item.NascitaLuogo,
                item.NascitaData,
                item.Professione,
                item.Denominazione,
                item.PIva,
                item.ReaComune,
                item.ReaN,
                item.AddrPaeseId,
                item.AddrProvinciaId,
                item.AddrProvincia,
                item.AddrComuneId,
                item.AddrComune,
                item.AddrIndirizzo,
                item.AddrN,
                item.AddrCap,
                item.Addr2PaeseId,
                item.Addr2ProvinciaId,
                item.Addr2Provincia,
                item.Addr2ComuneId,
                item.Addr2Comune,
                item.Addr2Indirizzo,
                item.Addr2N,
                item.Addr2Cap,
                item.IsPep,
                item.IsNotPepMoreThanYear,
                item.VersionN,
                item.VersionDate,
                item.IsDeleted,
                [],
                [],
                item.PepCarica,
                item.CreatedBy,
                item.ModifiedBy,
                []
              );

              for (let n = 0; n < item.anagrafica_fascicolos.length; n++) {
                const anagFasc = item.anagrafica_fascicolos[n];
                const tipoAnagKey = arrTipoAnagItems.filter(
                  (bta) => bta.id === anagFasc.base_tipo_anagrafica.Id
                )[0].key;

                if (
                  tipoAnagKey === "tipo_anagrafica_1" ||
                  tipoAnagKey === "tipo_anagrafica_2"
                ) {
                  arrItemsClienti.push(itemModel);
                }
              }
            }

            if (arrItemsClienti.length > 0) {
              setCliente(arrItemsClienti[0]);
              selCliente = arrItemsClienti[0];
            }
          }
        }

        //Carico le prestazioni relative al fascicolo
        const arrItemsPrest = [];
        const prestFascResponse = await apiPrestazioniByFascicolo.fetch({
          fascicoloId: fascicolo.id,
        });
        if (prestFascResponse.data.errors !== undefined) {
          enqueueSnackbar(t("error.generale"), { variant: "error" });
        } else {
          //const arrItems = [];
          prestFascResponse.data.data.prestazione.forEach((item) => {
            let prefixPrest = "";
            if (item.base_tipo_prestazione.BaseTenantTipoId === 1) {
              prefixPrest = "COMM";
            } else if (item.base_tipo_prestazione.BaseTenantTipoId === 2) {
              prefixPrest = "CONS";
            } else if (item.base_tipo_prestazione.BaseTenantTipoId === 3) {
              prefixPrest = "AVV";
            } else if (item.base_tipo_prestazione.BaseTenantTipoId === 4) {
              prefixPrest = "NOT";
            }

            let itemModel = new prestazioneModel(
              item.Id,
              item.TipoKey,
              item.PrestazioneId,
              prefixPrest + " - " + item.base_tipo_prestazione.Nome,
              item.base_tipo_prestazione.Rischio,
              item.base_tipo_prestazione.Regole,
              item.StartDate,
              item.EndDate,
              item.PaeseId,
              item.ProvinciaId,
              item.Provincia,
              item.CittaId,
              item.Citta,
              item.ScopoId,
              item.NaturaId,
              item.Descrizione,
              item.IsPrivate,
              item.CreatedOn,
              item.ModifiedOn,
              [],
              [],
              item.AstensioneMotivo,
              item.IsEsoneroObbAst,
              "",
              "",
              [],
              item.base_tipo_prestazione.BaseTenantTipoId,
              item.CreatedBy,
              item.ModifiedBy
            );

            const arrItemsUsers = [];
            item.prestazione_users.forEach((itemU) => {
              arrUsers.forEach((item3) => {
                if (itemU.UserId === item3.userId) {
                  arrItemsUsers.push(item3);
                }
              });
            });

            const arrItemsPagamenti = [];
            item.prestazione_pagamentos.forEach((itemP) => {
              arrItemsPagamenti.push(
                new prestazionePagamentoModel(
                  itemP.Id,
                  itemP.ValutaId,
                  itemP.ValutaData,
                  itemP.ValutaCambio,
                  itemP.ValutaImporto,
                  itemP.ImportoEuro,
                  itemP.ModalitaPagamentoId,
                  itemP.OrigineId,
                  itemP.CreatedOn,
                  itemP.ModifiedOn,
                  itemP.base_valutum.IsoCode,
                  itemP.base_mezzo_pagamento.Value,
                  itemP.base_origine_fondi.Value,
                  itemP.Data,
                  itemP.CreatedBy,
                  itemP.ModifiedBy
                )
              );
            });

            itemModel.professionisti = arrItemsUsers;
            itemModel.pagamenti = arrItemsPagamenti;
            arrItemsPrest.push(itemModel);
          });

          let filteredPrest = [];
          arrItemsPrest.forEach((item) => {
            if (item.isPrivate) {
              let profs = item.professionisti.map((prof) => prof.userId);
              if (profs.includes(loggedUserId)) filteredPrest.push(item);
            } else {
              filteredPrest.push(item);
            }
          });
          setCurrentPrestazioniList(filteredPrest);
        }

        /**************************************/
        //Carico i gradi di rischio dalla tabella di base
        await loadGradiRischio(tipoTenantId);

        /**************************************/
        //Carico le valutazioni rischi
        if (prestazione.id > 0) {
          setTenantTypeId(prestazione.tipoTenantId);
          await loadRischi(prestazione);
          await loadGradiRischio(prestazione.tipoTenantId);
          await loadRischioAreaGeografica(
            prestazione,
            arrPaeseItems,
            arrProvItems,
            selCliente
          );
          checkIsUserAllowedToManage(prestazione);
        }

        const rischioFromNavigationResult = await rischioFromNavigation;
        let rischioFromNavigationResultJson = null;
        if (rischioFromNavigationResult !== null) {
          rischioFromNavigationResultJson = JSON.parse(
            rischioFromNavigationResult
          );
        }

        if (
          rischioFromNavigationResultJson !== null &&
          rischioFromNavigationResultJson.entityType === "rischio"
        ) {
          const rischioByIdResponse = await apiRischioById.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            userId: loggedUserId,
            id: rischioFromNavigationResultJson.entityId,
          });
          if (prestFascResponse.data.errors !== undefined) {
          } else {
            const rischioByIdItem =
              rischioByIdResponse.data.data.rischio.length > 0
                ? rischioByIdResponse.data.data.rischio[0]
                : null;
            if (rischioByIdItem !== null) {
              const prestItem = arrItemsPrest.filter(
                (x) => x.id === rischioByIdItem.PrestazioneId
              )[0];
              setTenantTypeId(prestItem.tipoTenantId);
              await loadRischi(prestItem);
              await loadGradiRischio(prestItem.tipoTenantId);
              await loadRischioAreaGeografica(
                prestItem,
                arrPaeseItems,
                arrProvItems,
                selCliente
              );
              checkIsUserAllowedToManage(prestItem);
            }
          }
        }
      }
    } catch (e) {
      AddLog(e.message,e.stack,"LoadDatas")      
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
      setIsDataLoaded(true);
    }
  }
  const loadRischi = async (prest) => {
    try {
      setBackDropOpen(true);

      /**************************************/
      //Carico gli utenti
      const usersResponse = await apiUsersByCompanyId.fetch({
        tenantId: tenantId,
        aziendaId: aziendaId,
      });
      const arrUsers = [];
      usersResponse.data.data.user_tenant.map((item) =>
        arrUsers.push(
          new userModel(
            item.UserId,
            item.TenantId,
            item.user.Username,
            item.user.Email,
            item.user.Name,
            item.user.Surname,
            item.user.Name + " " + item.user.Surname,
            item.Type,
            item.user.Locale,
            item.TenantTipoId,
            item.user.IsRespAntiRic,
            item.base_tipo_tenant.Value,
            item.user.IsActive,
            item.user.IsDeleted,
            item.user.IsBanned,
            item.user.CreatedOn,
            item.user.Cf
          )
        )
      );

      //gli utenti originali li confronto con i professionisti indicati nella prestazione
      //così da presentare l'elenco di questi ultimi
      const newArrUsers = [];
      arrUsers.forEach((user) => {
        prest.professionisti.forEach((prof) => {
          if (user.userId === prof.userId) {
            newArrUsers.push(user);
          }
        });
      });
      setUtentiList(newArrUsers);

      const arrItems = [];
      const rischiResponse = await apiRischiByPrestazione.fetch({
        prestazioneId: prest.id,
      });
      if (rischiResponse.data.errors !== undefined) {
        enqueueSnackbar(t("error.generale"), { variant: "error" });
      } else {
        //const arrItems = [];
        rischiResponse.data.data.rischio.forEach((item) => {
          let itemModel = new rischioModel(
            item.Id,
            item.Date,
            prest,
            item.SpA1Livello,
            item.SpA1_1,
            item.SpA1_2,
            item.SpA1_3,
            item.SpA1_4,
            item.SpA1_5,
            item.SpA1_6,
            item.SpA1_7,
            item.SpA1Altro,
            item.SpA1AltroNote,
            item.SpA2Livello,
            item.SpA2_1,
            item.SpA2_2,
            item.SpA2_3,
            item.SpA2Altro,
            item.SpA2AltroNote,
            item.SpA3Livello,
            item.SpA3_1,
            item.SpA3_2,
            item.SpA3_3,
            item.SpA3_4,
            item.SpA3Altro,
            item.SpA3AltroNote,
            item.SpA4Livello,
            item.SpA4_1,
            item.SpA4_2,
            item.SpA4_3,
            item.SpA4_4,
            item.SpA4Altro,
            item.SpA4AltroNote,
            item.SpASubTotale,
            item.SpB1Livello,
            item.SpB1_1,
            item.SpB1_2,
            item.SpB1_3,
            item.SpB1Altro,
            item.SpB1AltroNote,
            item.SpB2Livello,
            item.SpB2_1,
            item.SpB2_2,
            item.SpB2_3,
            item.SpB2_4,
            item.SpB2Altro,
            item.SpB2AltroNote,
            item.SpB3Livello,
            item.SpB3_1,
            item.SpB3_2,
            item.SpB3_3,
            item.SpB3Altro,
            item.SpB3AltroNote,
            item.SpB4Livello,
            item.SpB4_1,
            item.SpB4_2,
            item.SpB4_3,
            item.SpB4Altro,
            item.SpB4AltroNote,
            item.SpB5Livello,
            item.SpB5_1,
            item.SpB5_2,
            item.SpB5_3,
            item.SpB5Altro,
            item.SpB5AltroNote,
            item.SpB6Livello,
            item.SpB6_1,
            item.SpB6_2,
            item.SpB6_3,
            item.SpB6_4,
            item.SpB6Altro,
            item.SpB6AltroNote,
            item.SpBSubTotale,
            item.SpC1_1_Livello,
            item.SpC1_2_Livello,
            item.SpC1_3_Livello,
            item.SpCSubTotale,
            item.EfRiTotale,
            item.EfSpTotale,
            item.EfTotale,
            item.EfAdeguataLivello,
            item.HasTabellaB,
            item.ProvidedBy,
            item.CreatedOn,
            item.ModifiedOn,
            "",
            "",
            "",
            item.DataControlloCostante,
            item.CreatedBy,
            item.ModifiedBy
          );

          arrItems.push(itemModel);
        });

        setCurrentRischiList(arrItems);
        setSelectedUserId(loggedUserId);
        setCurrentRischio(emptyRischioModel());
        setCurrentRischioOriginal(emptyRischioModel());
      }

      setCurrentPrestazione(prest);

      const rischioFromNavigationResult = await rischioFromNavigation;
      let rischioFromNavigationResultJson = null;
      if (rischioFromNavigationResult !== null) {
        rischioFromNavigationResultJson = JSON.parse(
          rischioFromNavigationResult
        );
      }

      if (
        rischioFromNavigationResultJson !== null &&
        rischioFromNavigationResultJson.entityType === "rischio"
      ) {
        const rischioToSelect = arrItems.filter(
          (x) => x.id === rischioFromNavigationResultJson.entityId
        )[0];
        await removeLocalStorageSelectedEntity();
        await selectRischio(rischioToSelect);
      }
    } catch (e) {
      AddLog(e.message + "Prestazione: " + prest,e.stack,"LoadRischi")      
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
    }
  };
  const loadGradiRischio = async (tipoTenantId) => {
    try {
      const baseRischioGradoResponse =
        await apiBaseRischioGradoByTenantTipo.fetch({
          tenantTipoId: tipoTenantId,
        });
      let arrBaseRischioGrado = [];
      baseRischioGradoResponse.data.data.base_rischio_grado.map((item) =>
        arrBaseRischioGrado.push(
          new baseRischioGradoModel(
            item.Id,
            item.TipoRischio,
            item.GradoRischio,
            item.AdeguataVerifica,
            item.ValoreRischioDa,
            item.ValoreRischioA,
            item.Colore,
            item.Offset
          )
        )
      );
      setBaseRischioGradoList(arrBaseRischioGrado);
    } catch (e) {
      AddLog(e.message + "TenantTypeId: " + tipoTenantId,e.stack,"LoadGradiRischio")      
    }
  };
  const loadRischioAreaGeografica = async (
    prestItem,
    paesi,
    province,
    clienteItem
  ) => {
    try {
      //Rischio area geografica cliente
      const paeseCliente = paesi.filter(
        (x) => x.id === clienteItem.addrPaeseId
      )[0];
      const paeseClienteS = paeseCliente.isRischio
        ? t("valutazione.form.rischioInerenteLivello4")
        : t("valutazione.form.rischioInerenteLivello1");
      const paeseClienteC = paeseCliente.isRischio ? chipRed : chipGreen;
      const paeseClienteV = paeseCliente.isRischio ? 4 : 1;

      setRischioPaeseCliente((prevState) => ({
        ...prevState,
        paese: paeseCliente.nome,
        rischio: paeseClienteS,
        color: paeseClienteC,
        value: paeseClienteV,
      }));

      if (paeseCliente.identificativo === "ITA") {
        const provinciaCliente = province.filter(
          (x) => x.id === clienteItem.addrProvinciaId
        )[0];
        let provinciaClienteS = t("valutazione.form.rischioInerenteLivello1");
        let provinciaClienteC = chipGreen;

        switch (provinciaCliente.rischio) {
          case 1: {
            provinciaClienteS = t("valutazione.form.rischioInerenteLivello1");
            provinciaClienteC = chipGreen;
            break;
          }
          case 2: {
            provinciaClienteS = t("valutazione.form.rischioInerenteLivello2");
            provinciaClienteC = chipYellow;
            break;
          }
          case 3: {
            provinciaClienteS = t("valutazione.form.rischioInerenteLivello3");
            provinciaClienteC = chipOrange;
            break;
          }
          case 4: {
            provinciaClienteS = t("valutazione.form.rischioInerenteLivello4");
            provinciaClienteC = chipRed;
            break;
          }
          default: {
            // provinciaClienteS = t("valutazione.form.rischioInerenteLivello1");
            // provinciaClienteC = chipGreen;
            break;
          }
        }

        setRischioProvinciaCliente((prevState) => ({
          ...prevState,
          provincia: provinciaCliente.nome,
          rischio: provinciaClienteS,
          color: provinciaClienteC,
          value: provinciaCliente.rischio,
        }));
      }

      //Rischio area geografica prestazione
      const paesePrest = paesi.filter((x) => x.id === prestItem.paeseId)[0];
      const paesePrestS = paesePrest.isRischio
        ? t("valutazione.form.rischioInerenteLivello4")
        : t("valutazione.form.rischioInerenteLivello1");
      const paesePrestC = paesePrest.isRischio ? chipRed : chipGreen;
      const paesePrestV = paesePrest.isRischio ? 4 : 1;

      if (paesePrest.identificativo === "ITA") {
        const provinciaPrest = province.filter(
          (x) => x.id === prestItem.provinciaId
        )[0];
        let provinciaPrestS = t("valutazione.form.rischioInerenteLivello1");
        let provinciaPrestC = chipGreen;
        switch (provinciaPrest.rischio) {
          case 1: {
            provinciaPrestS = t("valutazione.form.rischioInerenteLivello1");
            provinciaPrestC = chipGreen;
            break;
          }
          case 2: {
            provinciaPrestS = t("valutazione.form.rischioInerenteLivello2");
            provinciaPrestC = chipYellow;
            break;
          }
          case 3: {
            provinciaPrestS = t("valutazione.form.rischioInerenteLivello3");
            provinciaPrestC = chipOrange;
            break;
          }
          case 4: {
            provinciaPrestS = t("valutazione.form.rischioInerenteLivello4");
            provinciaPrestC = chipRed;
            break;
          }
          default: {
            break;
          }
        }

        setRischioProvinciaPrest((prevState) => ({
          ...prevState,
          provincia: provinciaPrest.nome,
          rischio: provinciaPrestS,
          color: provinciaPrestC,
          value: provinciaPrest.rischio,
        }));
      }

      setRischioPaesePrest((prevState) => ({
        ...prevState,
        paese: paesePrest.nome,
        rischio: paesePrestS,
        color: paesePrestC,
        value: paesePrestV,
      }));
    } catch (e) {
      AddLog(e.message,e.stack,"LoadRischioAreaGeografica")      
    } finally {
    }
  };
  //#endregion

  const AddLog = (message, stack, method) => {
    apiAddLog.fetch({
      tenantId: tenantId,
      aziendaId: aziendaId,
      level: "ERROR",
      message: message,
      stack: stack,
      area: "RischioForm",
      method: method,
      version: version,
    });
  }  

  //#region Handler
  const sliderLivelli = [
    {
      value: 1,
      label: "1",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 4,
      label: "4",
    },
  ];
  const sliderLivelliAvv = [
    {
      value: 1,
      label: "1",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 4,
      label: "4",
    },
    {
      value: 5,
      label: "5",
    },
  ];
  const handleRadioChange = (event) => {
    if(Object.prototype.toString.call(event) === "[object String]")
    {
      let selectedRadio = event.split("_")[1]
      const id = event
      if(id.includes("A1")){
        setSelectedA1val(event);         
        currentRischio.sp_a1_1 = selectedRadio === "1"
        currentRischio.sp_a1_2 = selectedRadio === "2"
        currentRischio.sp_a1_3 = selectedRadio === "3"
        currentRischio.sp_a1_4 = selectedRadio === "4"
        currentRischio.sp_a1_5 = selectedRadio === "5"  
        currentRischio.sp_a1_6 = selectedRadio === "6"  
        currentRischio.sp_a1_7 = selectedRadio === "7"                             
      }
      else if(id.includes("A2")){
        setSelectedA2val(event)
        currentRischio.sp_a2_1 = selectedRadio === "1"
        currentRischio.sp_a2_2 = selectedRadio === "2"
        currentRischio.sp_a2_3 = selectedRadio === "3"
      } else if(id.includes("A3")){
        setSelectedA3val(event)
        currentRischio.sp_a3_1 = selectedRadio === "1"
        currentRischio.sp_a3_2 = selectedRadio === "2"
        //2 inutilizzate
      } else if(id.includes("A4")){
        setSelectedA4val(event)
        currentRischio.sp_a4_1 = selectedRadio === "1"
        currentRischio.sp_a4_2 = selectedRadio === "2"
        currentRischio.sp_a4_3 = selectedRadio === "3"
        currentRischio.sp_a4_4 = selectedRadio === "4"
      } else if (id.includes("B1")){
        setSelectedB1val(event)
        currentRischio.sp_b1_1 = selectedRadio === "1"
        currentRischio.sp_b1_2 = selectedRadio === "2"
        //1 inutilizzata
      } else if (id.includes("B2")){
        setSelectedB2val(event)
        currentRischio.sp_b2_1 = selectedRadio === "1"
        currentRischio.sp_b2_2 = selectedRadio === "2"
        //2 inutilizzate
      } else if (id.includes("B3")){
        setSelectedB3val(event)
        currentRischio.sp_b3_1 = selectedRadio === "1"
        currentRischio.sp_b3_2 = selectedRadio === "2"
        currentRischio.sp_b3_3 = selectedRadio === "3"
      } else if (id.includes("B4")){
        setSelectedB4val(event)
        currentRischio.sp_b4_1 = selectedRadio === "1"
        currentRischio.sp_b4_2 = selectedRadio === "2"
        currentRischio.sp_b4_3 = selectedRadio === "3"
      } else if (id.includes("B5")){
        setSelectedB5val(event)
        currentRischio.sp_b5_1 = selectedRadio === "1"
        currentRischio.sp_b5_2 = selectedRadio === "2"
        //1 inutilizzata
      } else if (id.includes("B6")){
        setSelectedB6val(event)
        currentRischio.sp_b6_1 = selectedRadio === "1"
        currentRischio.sp_b6_2 = selectedRadio === "2"
        currentRischio.sp_b6_3 = selectedRadio === "3"
        currentRischio.sp_b6_4 = selectedRadio === "4"
      }
    }
  };
  const handleChange = async (event, value) => {
    const id =
      event.target.id !== undefined ? event.target.id : event.target.name;

    if (id.indexOf("ddlPrestazioni-option") !== -1 || id === "ddlPrestazioni") {
      const prestModel = currentPrestazioniList.filter(
        (x) => x.id === value.id
      )[0];

      setTenantTypeId(prestModel.tipoTenantId);
      await loadGradiRischio(prestModel.tipoTenantId);
      await loadRischioAreaGeografica(
        prestModel,
        basePaeseList,
        baseProvinciaList,
        cliente
      );

      setCurrentPrestazione(prestModel);
      setCurrentRischio(emptyRischioModel());
      setCurrentRischioOriginal(emptyRischioModel());
      setShowRischiForm(false);
      setTenantTypeId(prestModel.tipoTenantId);
      await loadRischi(prestModel);
      setShowRischiForm(false);
      checkIsUserAllowedToManage(prestModel);
    } else if (id.indexOf("ddlUtenti-option") !== -1 || id === "ddlUtenti") {
      currentRischio.providedBy = value.userId;
      setSelectedUserId(value.userId);
    }

    //A1
    else if (id === "sliderA1") {
      currentRischio.sp_a1_livello = event.target.value;
      setSliderVal((prevState) => ({
        ...prevState,
        sliderA1: event.target.value,
      }));
    } else if (id === "chbA1_1") {
      currentRischio.sp_a1_1 = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbA1_1: event.target.checked,
      }));
    } else if (id === "chbA1_2") {
      currentRischio.sp_a1_2 = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbA1_2: event.target.checked,
      }));
    } else if (id === "chbA1_3") {
      currentRischio.sp_a1_3 = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbA1_3: event.target.checked,
      }));
    } else if (id === "chbA1_4") {
      currentRischio.sp_a1_4 = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbA1_4: event.target.checked,
      }));
    } else if (id === "chbA1_5") {
      currentRischio.sp_a1_5 = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbA1_5: event.target.checked,
      }));
    } else if (id === "chbA1_Altro") {
      currentRischio.sp_a1_altro = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbA1_Altro: event.target.checked,
      }));
    } else if (id === "txtAltroA1") {
      currentRischio.sp_a1_altro_note = event.target.value;
    }

    //A2
    else if (id === "sliderA2") {
      currentRischio.sp_a2_livello = event.target.value;
      setSliderVal((prevState) => ({
        ...prevState,
        sliderA2: event.target.value,
      }));
    } else if (id === "chbA2_1") {
      currentRischio.sp_a2_1 = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbA2_1: event.target.checked,
      }));
    } else if (id === "chbA2_2") {
      currentRischio.sp_a2_2 = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbA2_2: event.target.checked,
      }));
    } else if (id === "chbA2_3") {
      currentRischio.sp_a2_3 = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbA2_3: event.target.checked,
      }));
    } else if (id === "chbA2_Altro") {
      currentRischio.sp_a2_altro = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbA2_Altro: event.target.checked,
      }));
    } else if (id === "txtAltroA2") {
      currentRischio.sp_a2_altro_note = event.target.value;
    }

    //A3
    else if (id === "sliderA3") {
      currentRischio.sp_a3_livello = event.target.value;
      setSliderVal((prevState) => ({
        ...prevState,
        sliderA3: event.target.value,
      }));
    } else if (id === "chbA3_1") {
      currentRischio.sp_a3_1 = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbA3_1: event.target.checked,
      }));
    } else if (id === "chbA3_2") {
      currentRischio.sp_a3_2 = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbA3_2: event.target.checked,
      }));
    } else if (id === "chbA3_3") {
      currentRischio.sp_a3_3 = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbA3_3: event.target.checked,
      }));
    } else if (id === "chbA3_4") {
      currentRischio.sp_a3_4 = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbA3_4: event.target.checked,
      }));
    } else if (id === "chbA3_Altro") {
      currentRischio.sp_a3_altro = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbA3_Altro: event.target.checked,
      }));
    } else if (id === "txtAltroA3") {
      currentRischio.sp_a3_altro_note = event.target.value;
    }

    //A4
    else if (id === "sliderA4") {
      currentRischio.sp_a4_livello = event.target.value;
      setSliderVal((prevState) => ({
        ...prevState,
        sliderA4: event.target.value,
      }));
    } else if (id === "chbA4_1") {
      currentRischio.sp_a4_1 = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbA4_1: event.target.checked,
      }));
    } else if (id === "chbA4_2") {
      currentRischio.sp_a4_2 = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbA4_2: event.target.checked,
      }));
    } else if (id === "chbA4_Altro") {
      currentRischio.sp_a4_altro = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbA4_Altro: event.target.checked,
      }));
    } else if (id === "txtAltroA4") {
      currentRischio.sp_a4_altro_note = event.target.value;
    }

    //B1
    else if (id === "sliderB1") {
      currentRischio.sp_b1_livello = event.target.value;
      setSliderVal((prevState) => ({
        ...prevState,
        sliderB1: event.target.value,
      }));
    } else if (id === "chbB1_1") {
      currentRischio.sp_b1_1 = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbB1_1: event.target.checked,
      }));
    } else if (id === "chbB1_2") {
      currentRischio.sp_b1_2 = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbB1_2: event.target.checked,
      }));
    } else if (id === "chbB1_3") {
      currentRischio.sp_b1_3 = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbB1_3: event.target.checked,
      }));
    } else if (id === "chbB1_Altro") {
      currentRischio.sp_b1_altro = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbB1_Altro: event.target.checked,
      }));
    } else if (id === "txtAltroB1") {
      currentRischio.sp_b1_altro_note = event.target.value;
    }

    //B2
    else if (id === "sliderB2") {
      currentRischio.sp_b2_livello = event.target.value;
      setSliderVal((prevState) => ({
        ...prevState,
        sliderB2: event.target.value,
      }));
    } else if (id === "chbB2_1") {
      currentRischio.sp_b2_1 = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbB2_1: event.target.checked,
      }));
    } else if (id === "chbB2_2") {
      currentRischio.sp_b2_2 = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbB2_2: event.target.checked,
      }));
    } else if (id === "chbB2_3") {
      currentRischio.sp_b2_3 = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbB2_3: event.target.checked,
      }));
    } else if (id === "chbB2_4") {
      currentRischio.sp_b2_4 = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbB2_4: event.target.checked,
      }));
    } else if (id === "chbB2_Altro") {
      currentRischio.sp_b2_altro = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbB2_Altro: event.target.checked,
      }));
    } else if (id === "txtAltroB2") {
      currentRischio.sp_b2_altro_note = event.target.value;
    }

    //B3
    else if (id === "sliderB3") {
      currentRischio.sp_b3_livello = event.target.value;
      setSliderVal((prevState) => ({
        ...prevState,
        sliderB3: event.target.value,
      }));
    } else if (id === "chbB3_1") {
      currentRischio.sp_b3_1 = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbB3_1: event.target.checked,
      }));
    } else if (id === "chbB3_2") {
      currentRischio.sp_b3_2 = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbB3_2: event.target.checked,
      }));
    } else if (id === "chbB3_Altro") {
      currentRischio.sp_b3_altro = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbB3_Altro: event.target.checked,
      }));
    } else if (id === "txtAltroB3") {
      currentRischio.sp_b3_altro_note = event.target.value;
    }

    //B4
    else if (id === "sliderB4") {
      currentRischio.sp_b4_livello = event.target.value;
      setSliderVal((prevState) => ({
        ...prevState,
        sliderB4: event.target.value,
      }));
    } else if (id === "chbB4_1") {
      currentRischio.sp_b4_1 = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbB4_1: event.target.checked,
      }));
    } else if (id === "chbB4_2") {
      currentRischio.sp_b4_2 = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbB4_2: event.target.checked,
      }));
    } else if (id === "chbB4_Altro") {
      currentRischio.sp_b4_altro = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbB4_Altro: event.target.checked,
      }));
    } else if (id === "txtAltroB4") {
      currentRischio.sp_b4_altro_note = event.target.value;
    }

    //B5
    else if (id === "sliderB5") {
      currentRischio.sp_b5_livello = event.target.value;
      setSliderVal((prevState) => ({
        ...prevState,
        sliderB5: event.target.value,
      }));
    } else if (id === "chbB5_1") {
      currentRischio.sp_b5_1 = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbB5_1: event.target.checked,
      }));
    } else if (id === "chbB5_2") {
      currentRischio.sp_b5_2 = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbB5_2: event.target.checked,
      }));
    } else if (id === "chbB5_3") {
      currentRischio.sp_b5_3 = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbB5_3: event.target.checked,
      }));
    } else if (id === "chbB5_Altro") {
      currentRischio.sp_b5_altro = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbB5_Altro: event.target.checked,
      }));
    } else if (id === "txtAltroB5") {
      currentRischio.sp_b5_altro_note = event.target.value;
    }

    //B6
    else if (id === "sliderB6") {
      currentRischio.sp_b6_livello = event.target.value;
      setSliderVal((prevState) => ({
        ...prevState,
        sliderB6: event.target.value,
      }));
    } else if (id === "chbB6_1") {
      currentRischio.sp_b6_1 = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbB6_1: event.target.checked,
      }));
    } else if (id === "chbB6_2") {
      currentRischio.sp_b6_2 = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbB6_2: event.target.checked,
      }));
    } else if (id === "chbB6_3") {
      currentRischio.sp_b6_3 = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbB6_3: event.target.checked,
      }));
    } else if (id === "chbB6_Altro") {
      currentRischio.sp_b6_altro = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbB6_Altro: event.target.checked,
      }));
    } else if (id === "txtAltroB6") {
      currentRischio.sp_b6_altro_note = event.target.value;
    }

    //C1
    else if (id === "sliderC1") {
      currentRischio.sp_c1_1_livello = event.target.value;
      setSliderVal((prevState) => ({
        ...prevState,
        sliderC1: event.target.value,
      }));
    } else if (id === "sliderC2") {
      currentRischio.sp_c1_2_livello = event.target.value;
      setSliderVal((prevState) => ({
        ...prevState,
        sliderC2: event.target.value,
      }));
    } else if (id === "sliderC3") {
      currentRischio.sp_c1_3_livello = event.target.value;
      setSliderVal((prevState) => ({
        ...prevState,
        sliderC3: event.target.value,
      }));
    }

    //Has Tabella B
    else if (id === "chbHasTabellaB") {
      currentRischio.hasTabellaB = event.target.checked;
      setChbVal((prevState) => ({
        ...prevState,
        chbTabellaB: event.target.checked,
      }));
    }    

    //Calcolo dei sub-totali per ogni Tabella
    var subTotaleA =
      currentRischio.sp_a1_livello +
      currentRischio.sp_a2_livello +
      currentRischio.sp_a3_livello +
      currentRischio.sp_a4_livello;
    var subTotaleB =
      currentRischio.sp_b1_livello +
      currentRischio.sp_b2_livello +
      currentRischio.sp_b3_livello +
      currentRischio.sp_b4_livello +
      currentRischio.sp_b5_livello +
      currentRischio.sp_b6_livello;
    var subTotaleC =
      currentRischio.sp_c1_1_livello +
      currentRischio.sp_c1_2_livello +
      currentRischio.sp_c1_3_livello;

    currentRischio.sp_a_subTotal = subTotaleA;
    currentRischio.sp_b_subTotal = subTotaleB;
    currentRischio.sp_c_subTotal = subTotaleC;

    setSubTotaleA(subTotaleA);
    setSubTotaleB(subTotaleB);
    setSubTotaleC(subTotaleC);
    setCurrentRischio(currentRischio);
  };
  const handleTableRowClick = async (value) => {
    if (isUserAllowedToManage) {
      const id = value;

      //A1
      if (id === "chbA1_1") {
        currentRischio.sp_a1_1 = !chbVal.chbA1_1;
        setChbVal((prevState) => ({ ...prevState, chbA1_1: !chbVal.chbA1_1 }));
      } else if (id === "chbA1_2") {
        currentRischio.sp_a1_2 = !chbVal.chbA1_2;
        setChbVal((prevState) => ({ ...prevState, chbA1_2: !chbVal.chbA1_2 }));
      } else if (id === "chbA1_3") {
        currentRischio.sp_a1_3 = !chbVal.chbA1_3;
        setChbVal((prevState) => ({ ...prevState, chbA1_3: !chbVal.chbA1_3 }));
      } else if (id === "chbA1_4") {
        currentRischio.sp_a1_4 = !chbVal.chbA1_4;
        setChbVal((prevState) => ({ ...prevState, chbA1_4: !chbVal.chbA1_4 }));
      } else if (id === "chbA1_5") {
        currentRischio.sp_a1_5 = !chbVal.chbA1_5;
        setChbVal((prevState) => ({ ...prevState, chbA1_5: !chbVal.chbA1_5 }));
      } else if (id === "chbA1_Altro") {
        currentRischio.sp_a1_altro = !chbVal.chbA1_Altro;
        setChbVal((prevState) => ({
          ...prevState,
          chbA1_Altro: !chbVal.chbA1_Altro,
        }));
      }
      
      //A2
      else if (id === "chbA2_1") {
        currentRischio.sp_a2_1 = !chbVal.chbA2_1;
        setChbVal((prevState) => ({ ...prevState, chbA2_1: !chbVal.chbA2_1 }));
      } else if (id === "chbA2_2") {
        currentRischio.sp_a2_2 = !chbVal.chbA2_2;
        setChbVal((prevState) => ({ ...prevState, chbA2_2: !chbVal.chbA2_2 }));
      } else if (id === "chbA2_3") {
        currentRischio.sp_a2_3 = !chbVal.chbA2_3;
        setChbVal((prevState) => ({ ...prevState, chbA2_3: !chbVal.chbA2_3 }));
      } else if (id === "chbA2_Altro") {
        currentRischio.sp_a2_altro = !chbVal.chbA2_Altro;
        setChbVal((prevState) => ({
          ...prevState,
          chbA2_Altro: !chbVal.chbA2_Altro,
        }));
      }

      //A3
      else if (id === "chbA3_1") {
        currentRischio.sp_a3_1 = !chbVal.chbA3_1;
        setChbVal((prevState) => ({ ...prevState, chbA3_1: !chbVal.chbA3_1 }));
      } else if (id === "chbA3_2") {
        currentRischio.sp_a3_2 = !chbVal.chbA3_2;
        setChbVal((prevState) => ({ ...prevState, chbA3_2: !chbVal.chbA3_2 }));
      } else if (id === "chbA3_3") {
        currentRischio.sp_a3_3 = !chbVal.chbA3_3;
        setChbVal((prevState) => ({ ...prevState, chbA3_3: !chbVal.chbA3_3 }));
      } else if (id === "chbA3_4") {
        currentRischio.sp_a3_4 = !chbVal.chbA3_4;
        setChbVal((prevState) => ({ ...prevState, chbA3_4: !chbVal.chbA3_4 }));
      } else if (id === "chbA3_Altro") {
        currentRischio.sp_a3_altro = !chbVal.chbA3_Altro;
        setChbVal((prevState) => ({
          ...prevState,
          chbA3_Altro: !chbVal.chbA3_Altro,
        }));
      }

      //A4
      else if (id === "chbA4_1") {
        currentRischio.sp_a4_1 = !chbVal.chbA4_1;
        setChbVal((prevState) => ({ ...prevState, chbA4_1: !chbVal.chbA4_1 }));
      } else if (id === "chbA4_2") {
        currentRischio.sp_a4_2 = !chbVal.chbA4_2;
        setChbVal((prevState) => ({ ...prevState, chbA4_2: !chbVal.chbA4_2 }));
      } else if (id === "chbA4_Altro") {
        currentRischio.sp_a4_altro = !chbVal.chbA4_Altro;
        setChbVal((prevState) => ({
          ...prevState,
          chbA4_Altro: !chbVal.chbA4_Altro,
        }));
      }

      //B1
      else if (id === "chbB1_1") {
        currentRischio.sp_b1_1 = !chbVal.chbB1_1;
        setChbVal((prevState) => ({ ...prevState, chbB1_1: !chbVal.chbB1_1 }));
      } else if (id === "chbB1_2") {
        currentRischio.sp_b1_2 = !chbVal.chbB1_2;
        setChbVal((prevState) => ({ ...prevState, chbB1_2: !chbVal.chbB1_2 }));
      } else if (id === "chbB1_3") {
        currentRischio.sp_b1_3 = !chbVal.chbB1_3;
        setChbVal((prevState) => ({ ...prevState, chbB1_3: !chbVal.chbB1_3 }));
      } else if (id === "chbB1_Altro") {
        currentRischio.sp_b1_altro = !chbVal.chbB1_Altro;
        setChbVal((prevState) => ({
          ...prevState,
          chbB1_Altro: !chbVal.chbB1_Altro,
        }));
      }

      //B2
      else if (id === "chbB2_1") {
        currentRischio.sp_b2_1 = !chbVal.chbB2_1;
        setChbVal((prevState) => ({ ...prevState, chbB2_1: !chbVal.chbB2_1 }));
      } else if (id === "chbB2_2") {
        currentRischio.sp_b2_2 = !chbVal.chbB2_2;
        setChbVal((prevState) => ({ ...prevState, chbB2_2: !chbVal.chbB2_2 }));
      } else if (id === "chbB2_3") {
        currentRischio.sp_b2_3 = !chbVal.chbB2_3;
        setChbVal((prevState) => ({ ...prevState, chbB2_3: !chbVal.chbB2_3 }));
      } else if (id === "chbB2_4") {
        currentRischio.sp_b2_4 = !chbVal.chbB2_4;
        setChbVal((prevState) => ({ ...prevState, chbB2_4: !chbVal.chbB2_4 }));
      } else if (id === "chbB2_Altro") {
        currentRischio.sp_b2_altro = !chbVal.chbB2_Altro;
        setChbVal((prevState) => ({
          ...prevState,
          chbB2_Altro: !chbVal.chbB2_Altro,
        }));
      }

      //B3
      else if (id === "chbB3_1") {
        currentRischio.sp_b3_1 = !chbVal.chbB3_1;
        setChbVal((prevState) => ({ ...prevState, chbB3_1: !chbVal.chbB3_1 }));
      } else if (id === "chbB3_2") {
        currentRischio.sp_b3_2 = !chbVal.chbB3_2;
        setChbVal((prevState) => ({ ...prevState, chbB3_2: !chbVal.chbB3_2 }));
      } else if (id === "chbB3_Altro") {
        currentRischio.sp_b3_altro = !chbVal.chbB3_Altro;
        setChbVal((prevState) => ({
          ...prevState,
          chbB3_Altro: !chbVal.chbB3_Altro,
        }));
      }

      //B4
      else if (id === "chbB4_1") {
        currentRischio.sp_b4_1 = !chbVal.chbB4_1;
        setChbVal((prevState) => ({ ...prevState, chbB4_1: !chbVal.chbB4_1 }));
      } else if (id === "chbB4_2") {
        currentRischio.sp_b4_2 = !chbVal.chbB4_2;
        setChbVal((prevState) => ({ ...prevState, chbB4_2: !chbVal.chbB4_2 }));
      } else if (id === "chbB4_Altro") {
        currentRischio.sp_b4_altro = !chbVal.chbB4_Altro;
        setChbVal((prevState) => ({
          ...prevState,
          chbB4_Altro: !chbVal.chbB4_Altro,
        }));
      }

      //B5
      else if (id === "chbB5_1") {
        currentRischio.sp_b5_1 = !chbVal.chbB5_1;
        setChbVal((prevState) => ({ ...prevState, chbB5_1: !chbVal.chbB5_1 }));
      } else if (id === "chbB5_2") {
        currentRischio.sp_b5_2 = !chbVal.chbB5_2;
        setChbVal((prevState) => ({ ...prevState, chbB5_2: !chbVal.chbB5_2 }));
      } else if (id === "chbB5_3") {
        currentRischio.sp_b5_3 = !chbVal.chbB5_3;
        setChbVal((prevState) => ({ ...prevState, chbB5_3: !chbVal.chbB5_3 }));
      } else if (id === "chbB5_Altro") {
        currentRischio.sp_b5_altro = !chbVal.chbB5_Altro;
        setChbVal((prevState) => ({
          ...prevState,
          chbB5_Altro: !chbVal.chbB5_Altro,
        }));
      }

      //B6
      else if (id === "chbB6_1") {
        currentRischio.sp_b6_1 = !chbVal.chbB6_1;
        setChbVal((prevState) => ({ ...prevState, chbB6_1: !chbVal.chbB6_1 }));
      } else if (id === "chbB6_2") {
        currentRischio.sp_b6_2 = !chbVal.chbB6_2;
        setChbVal((prevState) => ({ ...prevState, chbB6_2: !chbVal.chbB6_2 }));
      } else if (id === "chbB6_3") {
        currentRischio.sp_b6_3 = !chbVal.chbB6_3;
        setChbVal((prevState) => ({ ...prevState, chbB6_3: !chbVal.chbB6_3 }));
      } else if (id === "chbB6_Altro") {
        currentRischio.sp_b6_altro = !chbVal.chbB6_Altro;
        setChbVal((prevState) => ({
          ...prevState,
          chbB6_Altro: !chbVal.chbB6_Altro,
        }));
      }

      setCurrentRischio(currentRischio);
    }
  };
  const handleChangeDate = (date) => {
    if (date !== null && isDateValid(date)) {
      if (
        date.setHours(0, 0, 0, 0) >=
        new Date(currentPrestazione.dataStart).setHours(0, 0, 0, 0)
      ) {
        currentRischio.date = date;
        setCurrentRischio((prevState) => ({ ...prevState, date: date }));

        var newDate = new Date(date.getTime());
        var dt = newDate.setMonth(
          newDate.getMonth() + contCostAdeguataVerifica
        );
        setDataContCostAdeguataVerifica(dt);
      } else {
        enqueueSnackbar(
          "La data della valutazione deve essere superiore alla data della prestazione",
          { variant: "error" }
        );
      }
    }
  };
  const handleChangeAdegVerDate = (date) => {
    if (date !== null && isDateValid(date)) {
      if (
        date.setHours(0, 0, 0, 0) >=
        new Date(currentRischio.date).setHours(0, 0, 0, 0)
      ) {
        setDataContCostAdeguataVerifica(date);
      } else {
        enqueueSnackbar(
          "La data di adeguata verifica deve essere superiore alla data della valutazione",
          { variant: "error" }
        );
      }
    }
  };
  const checkIsUserAllowedToManage = (prestazione) => {
    setIsUserAllowedToManage(prestazione !== null && prestazione.id > 0);
  };
  const handleAddRischioClick = async () => {
    const newItem = emptyRischioModel();

    //Recupero i valori della Tabella A dall'ultima valutazione effettuata
    if (currentRischiList.length > 0) {
      const lastItem = currentRischiList[0];
      newItem.sp_a1_livello = lastItem.sp_a1_livello;
      newItem.sp_a1_1 = lastItem.sp_a1_1;
      newItem.sp_a1_2 = lastItem.sp_a1_2;
      newItem.sp_a1_3 = lastItem.sp_a1_3;
      newItem.sp_a1_4 = lastItem.sp_a1_4;
      newItem.sp_a1_5 = lastItem.sp_a1_5;
      newItem.sp_a1_altro = lastItem.sp_a1_altro;
      newItem.sp_a1_altro_note = lastItem.sp_a1_altro_note;
      newItem.sp_a2_livello = lastItem.sp_a2_livello;
      newItem.sp_a2_1 = lastItem.sp_a2_1;
      newItem.sp_a2_2 = lastItem.sp_a2_2;
      newItem.sp_a2_3 = lastItem.sp_a2_3;
      newItem.sp_a2_altro = lastItem.sp_a2_altro;
      newItem.sp_a2_altro_note = lastItem.sp_a2_altro_note;
      newItem.sp_a3_livello = lastItem.sp_a3_livello;
      newItem.sp_a3_1 = lastItem.sp_a3_1;
      newItem.sp_a3_2 = lastItem.sp_a3_2;
      newItem.sp_a3_3 = lastItem.sp_a3_3;
      newItem.sp_a3_4 = lastItem.sp_a3_4;
      newItem.sp_a3_altro = lastItem.sp_a3_altro;
      newItem.sp_a3_altro_note = lastItem.sp_a3_altro_note;
      newItem.sp_a4_livello = lastItem.sp_a4_livello;
      newItem.sp_a4_1 = lastItem.sp_a4_1;
      newItem.sp_a4_2 = lastItem.sp_a4_2;
      newItem.sp_a4_altro = lastItem.sp_a4_altro;
      newItem.sp_a4_altro_note = lastItem.sp_a4_altro_note;
      newItem.sp_a_subTotal = lastItem.sp_a_subTotal;
      setSubTotaleA(newItem.sp_a_subTotal);
    }

    setCurrentRischio(newItem);
    setCurrentRischioOriginal(newItem);

    setSliderVal({
      sliderA1: newItem.sp_a1_livello,
      sliderA2: newItem.sp_a2_livello,
      sliderA3: newItem.sp_a3_livello,
      sliderA4: newItem.sp_a4_livello,
      sliderB1: newItem.sp_b1_livello,
      sliderB2: newItem.sp_b2_livello,
      sliderB3: newItem.sp_b3_livello,
      sliderB4: newItem.sp_b4_livello,
      sliderB5: newItem.sp_b5_livello,
      sliderB6: newItem.sp_b6_livello,
      sliderC1: newItem.sp_c1_livello,
      sliderC2: newItem.sp_c2_livello,
      sliderC3: newItem.sp_c3_livello,
    });

    setChbVal({
      chbA1_1: newItem.sp_a1_1,
      chbA1_2: newItem.sp_a1_2,
      chbA1_3: newItem.sp_a1_3,
      chbA1_4: newItem.sp_a1_4,
      chbA1_5: newItem.sp_a1_5,
      chbA1_Altro: newItem.sp_a1_altro,
      chbA2_1: newItem.sp_a2_1,
      chbA2_2: newItem.sp_a2_2,
      chbA2_3: newItem.sp_a2_3,
      chbA2_Altro: newItem.sp_a2_altro,
      chbA3_1: newItem.sp_a3_1,
      chbA3_2: newItem.sp_a3_2,
      chbA3_3: newItem.sp_a3_3,
      chbA3_4: newItem.sp_a3_4,
      chbA3_Altro: newItem.sp_a3_altro,
      chbA4_1: newItem.sp_a4_1,
      chbA4_2: newItem.sp_a4_2,
      chbA4_Altro: newItem.sp_a4_altro,
      chbB1_1: false,
      chbB1_2: false,
      chbB1_3: false,
      chbB1_Altro: false,
      chbB2_1: false,
      chbB2_2: false,
      chbB2_3: false,
      chbB2_4: false,
      chbB2_Altro: false,
      chbB3_1: false,
      chbB3_2: false,
      chbB3_Altro: false,
      chbB4_1: false,
      chbB4_2: false,
      chbB4_Altro: false,
      chbB5_1: false,
      chbB5_2: false,
      chbB5_3: false,
      chbB5_Altro: false,
      chbB6_1: false,
      chbB6_2: false,
      chbB6_3: false,
      chbB6_Altro: false,
      chbTabellaB: newItem.hasTabellaB,
    });

    setSelectedA1val("")
    setSelectedA2val("")
    setSelectedA3val("")
    setSelectedA4val(newItem.sp_a4_1 ? "chbA4_1" : "")
    setSelectedB1val("")
    setSelectedB2val("")
    setSelectedB3val("")
    setSelectedB4val("")
    setSelectedB5val("")
    setSelectedB6val(newItem.sp_b6_1 ? "chbB6_1" : "" )
    setRischioInerenteLivelloS(t("valutazione.form.rischioInerenteLivello1"));
    setRischioInerenteLivelloC(chipGreen);
    setSubTotaleA(4);
    setSubTotaleB(6);
    setSubTotaleC(3);
    setTotRischioInerente(0);
    setTotRischioSpecifico(0);
    setTotRischioSpecificoPonderato(0);
    setTotRischioEffettivo(0);
    setLivelloAdeguataVerifica(1);
    setLivelloAdeguataVerificaS(t("valutazione.form.adeguataVerificaLivello1"));
    setLivelloAdeguataVerificaC(chipGreen);
    setRegoleAdeguataVerifica("");
    setContCostAdeguataVerifica(36);
    setDataContCostAdeguataVerifica(new Date());
    setShowRischiForm(true);
  };
  const handleCancelClick = () => {
    currentRischio.date = new Date();
    setCurrentRischio(currentRischio);

    setCurrentRischio(emptyRischioModel());
    setCurrentRischioOriginal(emptyRischioModel());

    setSliderVal({
      sliderA1: 1,
      sliderA2: 1,
      sliderA3: 1,
      sliderA4: 1,
      sliderB1: 1,
      sliderB2: 1,
      sliderB3: 1,
      sliderB4: 1,
      sliderB5: 1,
      sliderB6: 1,
      sliderC1: 1,
      sliderC2: 1,
      sliderC3: 1,
    });
    setChbVal({
      chbA1_1: false,
      chbA1_2: false,
      chbA1_3: false,
      chbA1_4: false,
      chbA1_5: false,
      chbA1_Altro: false,
      chbA2_1: false,
      chbA2_2: false,
      chbA2_3: false,
      chbA2_Altro: false,
      chbA3_1: false,
      chbA3_2: false,
      chbA3_3: false,
      chbA3_4: false,
      chbA3_Altro: false,
      chbA4_1: false,
      chbA4_2: false,
      chbA4_Altro: false,
      chbB1_1: false,
      chbB1_2: false,
      chbB1_3: false,
      chbB1_Altro: false,
      chbB2_1: false,
      chbB2_2: false,
      chbB2_3: false,
      chbB2_4: false,
      chbB2_Altro: false,
      chbB3_1: false,
      chbB3_2: false,
      chbB3_Altro: false,
      chbB4_1: false,
      chbB4_2: false,
      chbB4_Altro: false,
      chbB5_1: false,
      chbB5_2: false,
      chbB5_3: false,
      chbB5_Altro: false,
      chbB6_1: false,
      chbB6_2: false,
      chbB6_3: false,
      chbB6_Altro: false,
      chbTabellaB: true,
    });

    setRischioInerenteLivelloS(t("valutazione.form.rischioInerenteLivello1"));
    setRischioInerenteLivelloC(chipGreen);
    setSubTotaleA(4);
    setSubTotaleB(6);
    setSubTotaleC(3);
    setTotRischioInerente(0);
    setTotRischioSpecifico(0);
    setTotRischioSpecificoPonderato(0);
    setTotRischioEffettivo(0);
    setLivelloAdeguataVerifica(1);
    setLivelloAdeguataVerificaS(t("valutazione.form.adeguataVerificaLivello1"));
    setLivelloAdeguataVerificaC(chipGreen);
    setRegoleAdeguataVerifica("");
    setContCostAdeguataVerifica(36);
    setDataContCostAdeguataVerifica(new Date());
    setShowRischiForm(false);
  };
  const handleSelectedRischioClick = async (valutazione) => {
    await selectRischio(valutazione);
  };
  const selectRischio = async (valutazione) => {
    try {
      setBackDropOpen(true);
      setCurrentRischio(valutazione);
      setCurrentRischioOriginal(valutazione);
      setDataContCostAdeguataVerifica(valutazione.dataControlloCostante);
      setSelectedUserId(valutazione.providedBy);
      setSliderVal({
        sliderA1: valutazione.sp_a1_livello,
        sliderA2: valutazione.sp_a2_livello,
        sliderA3: valutazione.sp_a3_livello,
        sliderA4: valutazione.sp_a4_livello,
        sliderB1: valutazione.sp_b1_livello,
        sliderB2: valutazione.sp_b2_livello,
        sliderB3: valutazione.sp_b3_livello,
        sliderB4: valutazione.sp_b4_livello,
        sliderB5: valutazione.sp_b5_livello,
        sliderB6: valutazione.sp_b6_livello,
        sliderC1: valutazione.sp_c1_livello,
        sliderC2: valutazione.sp_c2_livello,
        sliderC3: valutazione.sp_c3_livello,
      });
      setChbVal({
        chbA1_1: valutazione.sp_a1_1,
        chbA1_2: valutazione.sp_a1_2,
        chbA1_3: valutazione.sp_a1_3,
        chbA1_4: valutazione.sp_a1_4,
        chbA1_5: valutazione.sp_a1_5,
        chbA1_6: valutazione.sp_a1_6,
        chbA1_7: valutazione.sp_a1_7,
        chbA1_Altro: valutazione.sp_a1_altro,
        chbA2_1: valutazione.sp_a2_1,
        chbA2_2: valutazione.sp_a2_2,
        chbA2_3: valutazione.sp_a2_3,
        chbA2_Altro: valutazione.sp_a2_altro,
        chbA3_1: valutazione.sp_a3_1,
        chbA3_2: valutazione.sp_a3_2,
        chbA3_3: valutazione.sp_a3_3,
        chbA3_4: valutazione.sp_a3_4,
        chbA3_Altro: valutazione.sp_a3_altro,
        chbA4_1: valutazione.sp_a4_1,
        chbA4_2: valutazione.sp_a4_2,
        chbA4_3: valutazione.sp_a4_3,
        chbA4_4: valutazione.sp_a4_4,
        chbA4_Altro: valutazione.sp_a4_altro,
        chbB1_1: valutazione.sp_b1_1,
        chbB1_2: valutazione.sp_b1_2,
        chbB1_3: valutazione.sp_b1_3,
        chbB1_Altro: valutazione.sp_b1_altro,
        chbB2_1: valutazione.sp_b2_1,
        chbB2_2: valutazione.sp_b2_2,
        chbB2_3: valutazione.sp_b2_3,
        chbB2_4: valutazione.sp_b2_4,
        chbB2_Altro: valutazione.sp_b2_altro,
        chbB3_1: valutazione.sp_b3_1,
        chbB3_2: valutazione.sp_b3_2,
        chbB3_3: valutazione.sp_b3_3,
        chbB3_Altro: valutazione.sp_b3_altro,
        chbB4_1: valutazione.sp_b4_1,
        chbB4_2: valutazione.sp_b4_2,
        chbB4_3: valutazione.sp_b4_3,
        chbB4_Altro: valutazione.sp_b4_altro,
        chbB5_1: valutazione.sp_b5_1,
        chbB5_2: valutazione.sp_b5_2,
        chbB5_3: valutazione.sp_b5_3,
        chbB5_Altro: valutazione.sp_b5_altro,
        chbB6_1: valutazione.sp_b6_1,
        chbB6_2: valutazione.sp_b6_2,
        chbB6_3: valutazione.sp_b6_3,
        chbB6_4: valutazione.sp_b6_4,
        chbB6_Altro: valutazione.sp_b6_altro,
        chbTabellaB: valutazione.hasTabellaB,
      });
      
      setSelectedA1val(valutazione.sp_a1_1 ? "chbA1_1" : valutazione.sp_a1_2 ? "chbA1_2" : valutazione.sp_a1_3 ? "chbA1_3" : valutazione.sp_a1_4 ? "chbA1_4" : valutazione.sp_a1_5 ? "chbA1_5" : valutazione.sp_a1_6 ? "chbA1_6" : valutazione.sp_a1_7 ? "chbA1_7" : "")
      setSelectedA2val(valutazione.sp_a2_1 ? "chbA2_1" : valutazione.sp_a2_2 ? "chbA2_2" : valutazione.sp_a2_3 ? "chbA2_3" : "")
      setSelectedA3val(valutazione.sp_a3_1 ? "chbA3_1" : valutazione.sp_a3_2 ? "chbA3_2" : valutazione.sp_a3_3 ? "chbA3_3" : valutazione.sp_a3_4 ? "chbA3_4" : "")
      setSelectedA4val(valutazione.sp_a4_1 ? "chbA4_1" : valutazione.sp_a4_2 ? "chbA4_2" : valutazione.sp_a4_3 ? "chbA4_3" : valutazione.sp_a4_4 ? "chbA4_4" : "")
      setSelectedB1val(valutazione.sp_b1_1 ? "chbB1_1" : valutazione.sp_b1_2 ? "chbB1_2" : "")
      setSelectedB2val(valutazione.sp_b2_1 ? "chbB2_1" : valutazione.sp_b2_2 ? "chbB2_2" : "")
      setSelectedB3val(valutazione.sp_b3_1 ? "chbB3_1" : valutazione.sp_b3_2 ? "chbB3_2" : valutazione.sp_b3_3 ? "chbB3_3" : "")
      setSelectedB4val(valutazione.sp_b4_1 ? "chbB4_1" : valutazione.sp_b4_2 ? "chbB4_2" : valutazione.sp_b4_3 ? "chbB4_3" : "")
      setSelectedB5val(valutazione.sp_b5_1 ? "chbB5_1" : valutazione.sp_b5_2 ? "chbB5_2" : "")
      setSelectedB6val(valutazione.sp_b6_1 ? "chbB6_1" : valutazione.sp_b6_2 ? "chbB6_2" : valutazione.sp_b6_3 ? "chbB6_3" : valutazione.sp_b6_4 ? "chbB6_4" : "")

      setShowRischiForm(true);
    } catch (e) {
      AddLog(e.message + "Valutazione: " + valutazione, e.stack,"SelectRischio")      
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
    }
  };
  const handleDeleteRischioClick = (valutazione) => {
    setIdToPerform(valutazione.id);
    setShowDeleteModal(true);
  };
  const handleSaveClick = async () => {
    try {
      setBackDropOpen(true);
      let canIProceed = false;
      let missingSections = [];

      if (
        currentPrestazione !== undefined &&
        currentPrestazione !== null &&
        currentPrestazione.id > 0 &&
        currentRischio.date !== undefined &&
        currentRischio.date !== null &&
        currentRischio.providedBy !== undefined &&
        currentRischio.providedBy !== null &&
        currentRischio.providedBy !== ""
      ) {
        canIProceed = true;
      }

      //Per ogni sezione, se il Livello è maggiore di 1, ci deve essere almeno un item flaggato
      //Vale solo per i commercialisti
      if (
        !currentRischio.sp_a1_1 &&
        !currentRischio.sp_a1_2 &&
        !currentRischio.sp_a1_3 &&
        !currentRischio.sp_a1_4 &&
        !currentRischio.sp_a1_5 &&
        !currentRischio.sp_a1_6 &&
        !currentRischio.sp_a1_7 &&
        !currentRischio.sp_a1_altro
      ) {
        canIProceed = false;
        missingSections.push("A1");
      }
      if (
        canIProceed &&
        !currentRischio.sp_a2_1 &&
        !currentRischio.sp_a2_2 &&
        !currentRischio.sp_a2_3 &&
        !currentRischio.sp_a2_altro
      ) {
        canIProceed = false;
        missingSections.push("A2");
      }
      if (
        canIProceed &&
        !currentRischio.sp_a3_1 &&
        !currentRischio.sp_a3_2 &&
        !currentRischio.sp_a3_3 &&
        !currentRischio.sp_a3_4 &&
        !currentRischio.sp_a3_altro
      ) {
        canIProceed = false;
        missingSections.push("A3");
      }
      if (
        canIProceed &&
        !currentRischio.sp_a4_1 &&
        !currentRischio.sp_a4_2 &&
        !currentRischio.sp_a4_3 &&
        !currentRischio.sp_a4_4 &&
        !currentRischio.sp_a4_altro
      ) {
        canIProceed = false;
        missingSections.push("A4");
      }

      if (chbVal.chbTabellaB) {
        if (
          canIProceed &&
          !currentRischio.sp_b1_1 &&
          !currentRischio.sp_b1_2 &&
          !currentRischio.sp_b1_3 &&
          !currentRischio.sp_b1_altro
        ) {
          canIProceed = false;
          missingSections.push("B1");
        }
        if (
          canIProceed &&
          !currentRischio.sp_b2_1 &&
          !currentRischio.sp_b2_2 &&
          !currentRischio.sp_b2_3 &&
          !currentRischio.sp_b2_4 &&
          !currentRischio.sp_b2_altro
        ) {
          canIProceed = false;
          missingSections.push("B2");
        }
        if (
          canIProceed &&
          !currentRischio.sp_b3_1 &&
          !currentRischio.sp_b3_2 &&
          !currentRischio.sp_b3_3 &&
          !currentRischio.sp_b3_altro
        ) {
          canIProceed = false;
          missingSections.push("B3");
        }
        if (
          canIProceed &&
          !currentRischio.sp_b4_1 &&
          !currentRischio.sp_b4_2 &&
          !currentRischio.sp_b4_3 &&
          !currentRischio.sp_b4_altro
        ) {
          canIProceed = false;
          missingSections.push("B4");
        }
        if (
          canIProceed &&
          !currentRischio.sp_b5_1 &&
          !currentRischio.sp_b5_2 &&
          !currentRischio.sp_b5_3 &&
          !currentRischio.sp_b5_altro
        ) {
          canIProceed = false;
          missingSections.push("B5");
        }
        if (
          canIProceed &&
          !currentRischio.sp_b6_1 &&
          !currentRischio.sp_b6_2 &&
          !currentRischio.sp_b6_3 &&
          !currentRischio.sp_b6_4 &&
          !currentRischio.sp_b6_altro
        ) {
          canIProceed = false;
          missingSections.push("B6");
        }
      }
      

      if (canIProceed) {
        const a1AltroNote = currentRischio.sp_a1_altro
          ? currentRischio.sp_a1_altro_note
          : "";
        const a2AltroNote = currentRischio.sp_a2_altro
          ? currentRischio.sp_a2_altro_note
          : "";
        const a3AltroNote = currentRischio.sp_a3_altro
          ? currentRischio.sp_a3_altro_note
          : "";
        const a4AltroNote = currentRischio.sp_a4_altro
          ? currentRischio.sp_a4_altro_note
          : "";
        const b1AltroNote = currentRischio.sp_b1_altro
          ? currentRischio.sp_b1_altro_note
          : "";
        const b2AltroNote = currentRischio.sp_b2_altro
          ? currentRischio.sp_b2_altro_note
          : "";
        const b3AltroNote = currentRischio.sp_b3_altro
          ? currentRischio.sp_b3_altro_note
          : "";
        const b4AltroNote = currentRischio.sp_b4_altro
          ? currentRischio.sp_b4_altro_note
          : "";
        const b5AltroNote = currentRischio.sp_b5_altro
          ? currentRischio.sp_b5_altro_note
          : "";
        const b6AltroNote = currentRischio.sp_b6_altro
          ? currentRischio.sp_b6_altro_note
          : "";

        //Se sto creando la prestazione
        if (currentRischio.id === 0) {
          const newDateD = new Date(currentRischio.date);
          const newDate = new Date(
            Date.UTC(
              newDateD.getFullYear(),
              newDateD.getMonth(),
              newDateD.getDate(),
              newDateD.getHours(),
              newDateD.getMinutes(),
              newDateD.getSeconds()
            )
          );
          const newDataControlloD = new Date(dataContCostAdeguataVerifica);
          const newDataControllo = new Date(
            Date.UTC(
              newDataControlloD.getFullYear(),
              newDataControlloD.getMonth(),
              newDataControlloD.getDate(),
              newDataControlloD.getHours(),
              newDataControlloD.getMinutes(),
              newDataControlloD.getSeconds()
            )
          );

          const addRischioResponse = await apiAddRischio.fetch({
            prestazioneId: currentPrestazione.id,
            date: newDate,
            spA1Livello: currentRischio.sp_a1_livello,
            spA1_1: currentRischio.sp_a1_1,
            spA1_2: currentRischio.sp_a1_2,
            spA1_3: currentRischio.sp_a1_3,
            spA1_4: currentRischio.sp_a1_4,
            spA1_5: currentRischio.sp_a1_5,
            spA1_6: currentRischio.sp_a1_6,
            spA1_7: currentRischio.sp_a1_7,
            spA1Altro: currentRischio.sp_a1_altro,
            spA1AltroNote: a1AltroNote,
            spA2Livello: currentRischio.sp_a2_livello,
            spA2_1: currentRischio.sp_a2_1,
            spA2_2: currentRischio.sp_a2_2,
            spA2_3: currentRischio.sp_a2_3,
            spA2Altro: currentRischio.sp_a2_altro,
            spA2AltroNote: a2AltroNote,
            spA3Livello: currentRischio.sp_a3_livello,
            spA3_1: currentRischio.sp_a3_1,
            spA3_2: currentRischio.sp_a3_2,
            spA3_3: currentRischio.sp_a3_3,
            spA3_4: currentRischio.sp_a3_4,
            spA3Altro: currentRischio.sp_a3_altro,
            spA3AltroNote: a3AltroNote,
            spA4Livello: currentRischio.sp_a4_livello,
            spA4_1: currentRischio.sp_a4_1,
            spA4_2: currentRischio.sp_a4_2,
            spA4_3: currentRischio.sp_a4_3,
            spA4_4: currentRischio.sp_a4_4,
            spA4Altro: currentRischio.sp_a4_altro,
            spA4AltroNote: a4AltroNote,
            spASubTotale: currentRischio.sp_a_subTotal,
            spB1Livello: currentRischio.sp_b1_livello,
            spB1_1: currentRischio.sp_b1_1,
            spB1_2: currentRischio.sp_b1_2,
            spB1_3: currentRischio.sp_b1_3,
            spB1Altro: currentRischio.sp_b1_altro,
            spB1AltroNote: b1AltroNote,
            spB2Livello: currentRischio.sp_b2_livello,
            spB2_1: currentRischio.sp_b2_1,
            spB2_2: currentRischio.sp_b2_2,
            spB2_3: currentRischio.sp_b2_3,
            spB2_4: currentRischio.sp_b2_4,
            spB2_5: currentRischio.sp_b2_5,
            spB2Altro: currentRischio.sp_b2_altro,
            spB2AltroNote: b2AltroNote,
            spB3Livello: currentRischio.sp_b3_livello,
            spB3_1: currentRischio.sp_b3_1,
            spB3_2: currentRischio.sp_b3_2,
            spB3_3: currentRischio.sp_b3_3,
            spB3Altro: currentRischio.sp_b3_altro,
            spB3AltroNote: b3AltroNote,
            spB4Livello: currentRischio.sp_b4_livello,
            spB4_1: currentRischio.sp_b4_1,
            spB4_2: currentRischio.sp_b4_2,
            spB4_3: currentRischio.sp_b4_3,
            spB4Altro: currentRischio.sp_b4_altro,
            spB4AltroNote: b4AltroNote,
            spB5Livello: currentRischio.sp_b5_livello,
            spB5_1: currentRischio.sp_b5_1,
            spB5_2: currentRischio.sp_b5_2,
            spB5_3: currentRischio.sp_b5_3,
            spB5Altro: currentRischio.sp_b5_altro,
            spB5AltroNote: b5AltroNote,
            spB6Livello: currentRischio.sp_b6_livello,
            spB6_1: currentRischio.sp_b6_1,
            spB6_2: currentRischio.sp_b6_2,
            spB6_3: currentRischio.sp_b6_3,
            spB6_4: currentRischio.sp_b6_4,
            spB6Altro: currentRischio.sp_b6_altro,
            spB6AltroNote: b6AltroNote,
            spBSubTotale: currentRischio.sp_b_subTotal,
            spC1_1_Livello: currentRischio.sp_c1_1_livello,
            spC1_2_Livello: currentRischio.sp_c1_2_livello,
            spC1_3_Livello: currentRischio.sp_c1_3_livello,
            spCSubTotale: currentRischio.sp_c_subTotal,
            efRiTotale: currentRischio.ef_ri_total,
            efSpTotale: currentRischio.ef_sp_total,
            efTotale: currentRischio.ef_total,
            efAdeguataLivello: currentRischio.ef_adeguata_livello,
            providedBy: currentRischio.providedBy,
            hasTabellaB: currentRischio.hasTabellaB,
            dataControlloCostante: newDataControllo,
          });
          if (addRischioResponse.data.errors !== undefined) {
            enqueueSnackbar(t("error.generale"), { variant: "error" });
          } else {
            const result = addRischioResponse.data.data.insert_rischio_one;
            let rischioResultModel = new rischioModel(
              result.Id,
              result.Date,
              currentPrestazione,
              result.SpA1Livello,
              result.SpA1_1,
              result.SpA1_2,
              result.SpA1_3,
              result.SpA1_4,
              result.SpA1_5,
              result.SpA1_6,
              result.SpA1_7,
              result.SpA1Altro,
              result.SpA1AltroNote,
              result.SpA2Livello,
              result.SpA2_1,
              result.SpA2_2,
              result.SpA2_3,
              result.SpA2Altro,
              result.SpA2AltroNote,
              result.SpA3Livello,
              result.SpA3_1,
              result.SpA3_2,
              result.SpA3_3,
              result.SpA3_4,
              result.SpA3Altro,
              result.SpA3AltroNote,
              result.SpA4Livello,
              result.SpA4_1,
              result.SpA4_2,
              result.SpA4_3,
              result.SpA4_4,
              result.SpA4Altro,
              result.SpA4AltroNote,
              result.SpASubTotale,
              result.SpB1Livello,
              result.SpB1_1,
              result.SpB1_2,
              result.SpB1_3,
              result.SpB1Altro,
              result.SpB1AltroNote,
              result.SpB2Livello,
              result.SpB2_1,
              result.SpB2_2,
              result.SpB2_3,
              result.SpB2_4,
              result.SpB2Altro,
              result.SpB2AltroNote,
              result.SpB3Livello,
              result.SpB3_1,
              result.SpB3_2,
              result.SpB3_3,
              result.SpB3Altro,
              result.SpB3AltroNote,
              result.SpB4Livello,
              result.SpB4_1,
              result.SpB4_2,
              result.SpB4_3,
              result.SpB4Altro,
              result.SpB4AltroNote,
              result.SpB5Livello,
              result.SpB5_1,
              result.SpB5_2,
              result.SpB5_3,
              result.SpB5Altro,
              result.SpB5AltroNote,
              result.SpB6Livello,
              result.SpB6_1,
              result.SpB6_2,
              result.SpB6_3,
              result.SpB6_4,
              result.SpB6Altro,
              result.SpB6AltroNote,
              result.SpBSubTotale,
              result.SpC1_1_Livello,
              result.SpC1_2_Livello,
              result.SpC1_3_Livello,
              result.SpCSubTotale,
              result.EfRiTotale,
              result.EfSpTotale,
              result.EfTotale,
              result.EfAdeguataLivello,
              result.HasTabellaB,
              result.ProvidedBy,
              result.CreatedOn,
              result.ModifiedOn,
              "",
              "",
              "",
              result.DataControlloCostante,
              result.CreatedBy,
              result.ModifiedBy
            );

            //Aggiungo la Audit per tracciare l'azione
            const newValues = rischioResultModel;
            apiAddAudit.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
              area: bsaRischio,
              operation: "Create",
              entityType: "rischio",
              entityId: result.Id.toString(),
              oldValues: "",
              newValues: JSON.stringify(newValues),
            });

            //Aggiorno l'elenco delle prestazioni
            const valutazioni = Object.assign([], currentRischiList);
            valutazioni.unshift(rischioResultModel);
            setCurrentRischiList(valutazioni);
            setCurrentRischio(rischioResultModel);
            setCurrentRischioOriginal(rischioResultModel);

            enqueueSnackbar(t("message.success"), { variant: "success" });
          }
        }

        //Se la sto modificando
        else {
          const newDateD = new Date(currentRischio.date);
          const newDate = new Date(
            Date.UTC(
              newDateD.getFullYear(),
              newDateD.getMonth(),
              newDateD.getDate(),
              newDateD.getHours(),
              newDateD.getMinutes(),
              newDateD.getSeconds()
            )
          );
          const newDataControlloD = new Date(dataContCostAdeguataVerifica);
          const newDataControllo = new Date(
            Date.UTC(
              newDataControlloD.getFullYear(),
              newDataControlloD.getMonth(),
              newDataControlloD.getDate(),
              newDataControlloD.getHours(),
              newDataControlloD.getMinutes(),
              newDataControlloD.getSeconds()
            )
          );

          const updateRischioResponse = await apiUpdateRischio.fetch({
            id: currentRischio.id,
            prestazioneId: currentPrestazione.id,
            date: newDate,
            spA1Livello: currentRischio.sp_a1_livello,
            spA1_1: currentRischio.sp_a1_1,
            spA1_2: currentRischio.sp_a1_2,
            spA1_3: currentRischio.sp_a1_3,
            spA1_4: currentRischio.sp_a1_4,
            spA1_5: currentRischio.sp_a1_5,
            spA1_6: currentRischio.sp_a1_6,
            spA1_7: currentRischio.sp_a1_7,
            spA1Altro: currentRischio.sp_a1_altro,
            spA1AltroNote: a1AltroNote,
            spA2Livello: currentRischio.sp_a2_livello,
            spA2_1: currentRischio.sp_a2_1,
            spA2_2: currentRischio.sp_a2_2,
            spA2_3: currentRischio.sp_a2_3,
            spA2Altro: currentRischio.sp_a2_altro,
            spA2AltroNote: a2AltroNote,
            spA3Livello: currentRischio.sp_a3_livello,
            spA3_1: currentRischio.sp_a3_1,
            spA3_2: currentRischio.sp_a3_2,
            spA3_3: currentRischio.sp_a3_3,
            spA3_4: currentRischio.sp_a3_4,
            spA3Altro: currentRischio.sp_a3_altro,
            spA3AltroNote: a3AltroNote,
            spA4Livello: currentRischio.sp_a4_livello,
            spA4_1: currentRischio.sp_a4_1,
            spA4_2: currentRischio.sp_a4_2,
            spA4_3: currentRischio.sp_a4_3,
            spA4_4: currentRischio.sp_a4_4,
            spA4Altro: currentRischio.sp_a4_altro,
            spA4AltroNote: a4AltroNote,
            spASubTotale: currentRischio.sp_a_subTotal,
            spB1Livello: currentRischio.sp_b1_livello,
            spB1_1: currentRischio.sp_b1_1,
            spB1_2: currentRischio.sp_b1_2,
            spB1_3: currentRischio.sp_b1_3,
            spB1Altro: currentRischio.sp_b1_altro,
            spB1AltroNote: b1AltroNote,
            spB2Livello: currentRischio.sp_b2_livello,
            spB2_1: currentRischio.sp_b2_1,
            spB2_2: currentRischio.sp_b2_2,
            spB2_3: currentRischio.sp_b2_3,
            spB2_4: currentRischio.sp_b2_4,
            spB2_5: currentRischio.sp_b2_5,
            spB2Altro: currentRischio.sp_b2_altro,
            spB2AltroNote: b2AltroNote,
            spB3Livello: currentRischio.sp_b3_livello,
            spB3_1: currentRischio.sp_b3_1,
            spB3_2: currentRischio.sp_b3_2,
            spB3_3: currentRischio.sp_b3_3,
            spB3Altro: currentRischio.sp_b3_altro,
            spB3AltroNote: b3AltroNote,
            spB4Livello: currentRischio.sp_b4_livello,
            spB4_1: currentRischio.sp_b4_1,
            spB4_2: currentRischio.sp_b4_2,
            spB4_3: currentRischio.sp_b4_3,
            spB4Altro: currentRischio.sp_b4_altro,
            spB4AltroNote: b4AltroNote,
            spB5Livello: currentRischio.sp_b5_livello,
            spB5_1: currentRischio.sp_b5_1,
            spB5_2: currentRischio.sp_b5_2,
            spB5_3: currentRischio.sp_b5_3,
            spB5Altro: currentRischio.sp_b5_altro,
            spB5AltroNote: b5AltroNote,
            spB6Livello: currentRischio.sp_b6_livello,
            spB6_1: currentRischio.sp_b6_1,
            spB6_2: currentRischio.sp_b6_2,
            spB6_3: currentRischio.sp_b6_3,
            spB6_4: currentRischio.sp_b6_4,
            spB6Altro: currentRischio.sp_b6_altro,
            spB6AltroNote: b6AltroNote,
            spBSubTotale: currentRischio.sp_b_subTotal,
            spC1_1_Livello: currentRischio.sp_c1_1_livello,
            spC1_2_Livello: currentRischio.sp_c1_2_livello,
            spC1_3_Livello: currentRischio.sp_c1_3_livello,
            spCSubTotale: currentRischio.sp_c_subTotal,
            efRiTotale: currentRischio.ef_ri_total,
            efSpTotale: currentRischio.ef_sp_total,
            efTotale: currentRischio.ef_total,
            efAdeguataLivello: currentRischio.ef_adeguata_livello,
            providedBy: selectedUserId,
            hasTabellaB: currentRischio.hasTabellaB,
            dataControlloCostante: newDataControllo,
          });
          if (updateRischioResponse.data.errors !== undefined) {
            enqueueSnackbar(t("error.generale"), { variant: "error" });
          } else {
            const result =
              updateRischioResponse.data.data.update_rischio.returning[0];
            let rischioResultModel = new rischioModel(
              result.Id,
              result.Date,
              currentPrestazione,
              result.SpA1Livello,
              result.SpA1_1,
              result.SpA1_2,
              result.SpA1_3,
              result.SpA1_4,
              result.SpA1_5,
              result.SpA1_6,
              result.SpA1_7,
              result.SpA1Altro,
              result.SpA1AltroNote,
              result.SpA2Livello,
              result.SpA2_1,
              result.SpA2_2,
              result.SpA2_3,
              result.SpA2Altro,
              result.SpA2AltroNote,
              result.SpA3Livello,
              result.SpA3_1,
              result.SpA3_2,
              result.SpA3_3,
              result.SpA3_4,
              result.SpA3Altro,
              result.SpA3AltroNote,
              result.SpA4Livello,
              result.SpA4_1,
              result.SpA4_2,
              result.SpA4_3,
              result.SpA4_4,
              result.SpA4Altro,
              result.SpA4AltroNote,
              result.SpASubTotale,
              result.SpB1Livello,
              result.SpB1_1,
              result.SpB1_2,
              result.SpB1_3,
              result.SpB1Altro,
              result.SpB1AltroNote,
              result.SpB2Livello,
              result.SpB2_1,
              result.SpB2_2,
              result.SpB2_3,
              result.SpB2_4,
              result.SpB2Altro,
              result.SpB2AltroNote,
              result.SpB3Livello,
              result.SpB3_1,
              result.SpB3_2,
              result.SpB3_3,
              result.SpB3Altro,
              result.SpB3AltroNote,
              result.SpB4Livello,
              result.SpB4_1,
              result.SpB4_2,
              result.SpB4_3,
              result.SpB4Altro,
              result.SpB4AltroNote,
              result.SpB5Livello,
              result.SpB5_1,
              result.SpB5_2,
              result.SpB5_3,
              result.SpB5Altro,
              result.SpB5AltroNote,
              result.SpB6Livello,
              result.SpB6_1,
              result.SpB6_2,
              result.SpB6_3,
              result.SpB6_4,
              result.SpB6Altro,
              result.SpB6AltroNote,
              result.SpBSubTotale,
              result.SpC1_1_Livello,
              result.SpC1_2_Livello,
              result.SpC1_3_Livello,
              result.SpCSubTotale,
              result.EfRiTotale,
              result.EfSpTotale,
              result.EfTotale,
              result.EfAdeguataLivello,
              result.HasTabellaB,
              result.ProvidedBy,
              result.CreatedOn,
              result.ModifiedOn,
              "",
              "",
              "",
              result.DataControlloCostante,
              result.CreatedBy,
              result.ModifiedBy
            );

            //Aggiungo la Audit per tracciare l'azione
            const newValues = rischioResultModel;
            apiAddAudit.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
              area: bsaRischio,
              operation: "Update",
              entityType: "rischio",
              entityId: result.Id.toString(),
              oldValues: JSON.stringify(currentRischioOriginal),
              newValues: JSON.stringify(newValues),
            });

            //Aggiorno l'elenco delle prestazioni
            const valutazioni = Object.assign([], currentRischiList);
            const index = valutazioni.findIndex(
              (item) => item.id === rischioResultModel.id
            );
            valutazioni[index] = rischioResultModel;

            setCurrentRischiList(valutazioni);
            setCurrentRischio(rischioResultModel);
            setCurrentRischioOriginal(rischioResultModel);

            enqueueSnackbar(t("message.success"), { variant: "success" });
          }
        }

        //Aggiorno la data di ultima modifica del fascicolo
        await apiUpdateFascicolo.fetch({
          id: fascicolo.id,
          name: fascicolo.name,
          date: fascicolo.date,
          isPrivate: fascicolo.isPrivate,
        });
      } else {
        let missingSectionsString = "";
        for (let i = 0; i < missingSections.length; i++) {
          missingSectionsString =
            missingSectionsString + missingSections[i].toString();
        }
        //enqueueSnackbar(t("error.missingFields"), { variant: "error" });
        enqueueSnackbar(
          "Si prega di compilare la sezione " + missingSectionsString,
          { variant: "error" }
        );
      }
    } catch (e) {
      AddLog(e.message+ "Valutazione : " + currentRischio,e.stack,"HandleSaveClick")      
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
      reloadStatusFascicolo();
    }
  };
  //#endregion

  useEffect(() => {
    calcoloRischio();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sliderVal, chbVal.chbTabellaB]);
  const calcoloRischio = () => {
    let rischioItem = currentRischio;

    //Se l'id è uguale a -1 vuol dire che arrivo qui prima ancora che venga inizializzato il model
    if (rischioItem.id === -1) {
      rischioItem = emptyRischioModel();
    }

    //Inerente
    //Rischio che arriva dalla Prestazione
    switch (currentPrestazione.prestazioneRischio) {
      case 1: {
        setRischioInerenteLivelloS(
          t("valutazione.form.rischioInerenteLivello1")
        );
        setRischioInerenteLivelloC(chipGreen);
        break;
      }
      case 2: {
        setRischioInerenteLivelloS(
          t("valutazione.form.rischioInerenteLivello2")
        );
        setRischioInerenteLivelloC(chipYellow);
        break;
      }
      case 3: {
        setRischioInerenteLivelloS(
          t("valutazione.form.rischioInerenteLivello3")
        );
        setRischioInerenteLivelloC(chipOrange);
        break;
      }
      case 4: {
        setRischioInerenteLivelloS(
          t("valutazione.form.rischioInerenteLivello4")
        );
        setRischioInerenteLivelloC(chipRed);
        break;
      }
      default: {
        // setRischioInerenteLivelloS(t("valutazione.form.rischioInerenteLivello1"));
        // setRischioInerenteLivelloC(chipGreen);
        break;
      }
    }

    //Totale rischio inerente
    let totInerente = (currentPrestazione.prestazioneRischio * 30) / 100;
    if (tenantTypeId === 2) {
      totInerente = (currentPrestazione.prestazioneRischio * 40) / 100;
    }
    rischioItem.ef_ri_total = totInerente.toFixed(2);

    if (!Number.isNaN(totInerente)) {
      setTotRischioInerente(totInerente.toFixed(2));
    } else {
      totInerente = 0;
      setTotRischioInerente(0);
    }

    //Specifico
    var tabellaA =
      rischioItem.sp_a1_livello +
      rischioItem.sp_a2_livello +
      rischioItem.sp_a3_livello +
      rischioItem.sp_a4_livello;
    var tabellaB =
      rischioItem.sp_b1_livello +
      rischioItem.sp_b2_livello +
      rischioItem.sp_b3_livello +
      rischioItem.sp_b4_livello +
      rischioItem.sp_b5_livello +
      rischioItem.sp_b6_livello;
    var totale = (tabellaA + tabellaB) / 10;

    if (chbVal.chbTabellaB) {
      totale = (tabellaA + tabellaB) / 10;
    } else {
      totale = tabellaA / 4;
    }

    setTotRischioSpecifico(totale.toFixed(2));

    let totSpecifico = (totale * 70) / 100;
    if (tenantTypeId === 2) {
      totSpecifico = (totale * 60) / 100;
    }
    rischioItem.ef_sp_total = totSpecifico.toFixed(2);

    if (!Number.isNaN(totSpecifico)) {
      setTotRischioSpecificoPonderato(totSpecifico.toFixed(2));
    } else {
      totSpecifico = 0;
      setTotRischioSpecificoPonderato(0);
    }

    //Effettivo
    var totEffettivo = totInerente + totSpecifico;
    rischioItem.ef_total = totEffettivo.toFixed(2);

    if (!Number.isNaN(totEffettivo)) {
      setTotRischioEffettivo(totEffettivo.toFixed(2));

      if (totEffettivo >= 1 && totEffettivo <= 1.5) {
        setRischioEffettivoLivelloS(
          t("valutazione.form.rischioInerenteLivello1")
        );
        setRischioEffettivoLivelloC(chipGreen);
      } else if (totEffettivo > 1.5 && totEffettivo <= 2.5) {
        setRischioEffettivoLivelloS(
          t("valutazione.form.rischioInerenteLivello2")
        );
        setRischioEffettivoLivelloC(chipYellow);
      } else if (totEffettivo > 2.5 && totEffettivo <= 3.5) {
        setRischioEffettivoLivelloS(
          t("valutazione.form.rischioInerenteLivello3")
        );
        setRischioEffettivoLivelloC(chipOrange);
      } else if (totEffettivo > 3.5) {
        setRischioEffettivoLivelloS(
          t("valutazione.form.rischioInerenteLivello4")
        );
        setRischioEffettivoLivelloC(chipRed);
      }
    } else {
      totEffettivo = 0;
      setTotRischioEffettivo(0);
    }

    //Adeguata verifica
    var adeguataVerifica = 1;
    var adeguataVerificaS = t("valutazione.form.adeguataVerificaLivello1");
    var adeguataVerificaC = chipGreen;

    if (baseRischioGradoList.length > 0) {
      if (totEffettivo >= 1.0 && totEffettivo <= 1.5) {
        adeguataVerifica = 1;
        adeguataVerificaS = t("valutazione.form.adeguataVerificaLivello1");
        adeguataVerificaC = chipGreen;

        const baseGradoRischioItem = baseRischioGradoList.filter(
          (x) => x.valoreRischioDa === 1.0 && x.valoreRischioA === 1.5
        )[0];
        setRegoleAdeguataVerifica(baseGradoRischioItem.adeguataVerifica);
        setContCostAdeguataVerifica(baseGradoRischioItem.offset);

        var dt = new Date().setMonth(
          new Date().getMonth() + baseGradoRischioItem.offset
        );
        setDataContCostAdeguataVerifica(dt);
      } else if (totEffettivo > 1.5 && totEffettivo <= 2.5) {
        adeguataVerifica = 2;
        adeguataVerificaS = t("valutazione.form.adeguataVerificaLivello1");
        adeguataVerificaC = chipYellow;

        const baseGradoRischioItem = baseRischioGradoList.filter(
          (x) => x.valoreRischioDa === 1.5 && x.valoreRischioA === 2.5
        )[0];
        setRegoleAdeguataVerifica(baseGradoRischioItem.adeguataVerifica);
        setContCostAdeguataVerifica(baseGradoRischioItem.offset);

        var dt1 = new Date().setMonth(
          new Date().getMonth() + baseGradoRischioItem.offset
        );
        setDataContCostAdeguataVerifica(dt1);
      } else if (totEffettivo > 2.5 && totEffettivo <= 3.5) {
        adeguataVerifica = 3;
        adeguataVerificaS = t("valutazione.form.adeguataVerificaLivello2");
        adeguataVerificaC = chipOrange;

        const baseGradoRischioItem = baseRischioGradoList.filter(
          (x) => x.valoreRischioDa === 2.5 && x.valoreRischioA === 3.5
        )[0];
        setRegoleAdeguataVerifica(baseGradoRischioItem.adeguataVerifica);
        setContCostAdeguataVerifica(baseGradoRischioItem.offset);

        var dt2 = new Date().setMonth(
          new Date().getMonth() + baseGradoRischioItem.offset
        );
        setDataContCostAdeguataVerifica(dt2);
      } else if (totEffettivo > 3.5) {
        adeguataVerifica = 4;
        adeguataVerificaS = t("valutazione.form.adeguataVerificaLivello3");
        adeguataVerificaC = chipRed;

        const baseGradoRischioItem = baseRischioGradoList.filter(
          (x) => x.valoreRischioDa === 3.5 && x.valoreRischioA === 4.0
        )[0];
        setRegoleAdeguataVerifica(baseGradoRischioItem.adeguataVerifica);
        setContCostAdeguataVerifica(baseGradoRischioItem.offset);

        var dt3 = new Date().setMonth(
          new Date().getMonth() + baseGradoRischioItem.offset
        );
        setDataContCostAdeguataVerifica(dt3);
      }
    }

    if (rischioItem.dataControlloCostante !== undefined) {
      setDataContCostAdeguataVerifica(rischioItem.dataControlloCostante);
    }

    rischioItem.ef_adeguata_livello = adeguataVerifica;
    setLivelloAdeguataVerifica(adeguataVerifica);
    setLivelloAdeguataVerificaS(adeguataVerificaS);
    setLivelloAdeguataVerificaC(adeguataVerificaC);
    setCurrentRischio(rischioItem);

    if(tenantTypeId === 3){
      let total = subTotaleA + subTotaleB
      let offsetMonths = 0
      if(total >= 10 && total <= 20){
        setLivelloRischioAvv(chipGreen)
        setLabelRischioAvv("Rischio irrilevante")
        offsetMonths = 36
      } else if ( total > 20 && total <= 30){
        setLivelloRischioAvv(chipGreen)
        setLabelRischioAvv("Rischio basso")
        offsetMonths = 36
      } else if (total > 30 && total <= 35){
        setLivelloRischioAvv(chipYellow)
        setLabelRischioAvv("Rischio medio/moderato")
        offsetMonths = 24
      } else if (total > 35 && total <= 40){
        setLivelloRischioAvv(chipOrange)
        setLabelRischioAvv("Rischio moderato/alto")
        offsetMonths = 12
      } else {
        setLivelloRischioAvv(chipRed)
        setLabelRischioAvv("Rischio elevato e palese")
        offsetMonths = 6
      }
      
      var dateOfControl = new Date().setMonth(
        new Date().getMonth() + offsetMonths
      );
      setDataContCostAdeguataVerifica(dateOfControl);
      setContCostAdeguataVerifica(offsetMonths)
    }
  };

  //CRUD della valutazione
  const emptyRischioModel = () => {
    let data = new Date();
    if (currentRischio.date !== null && currentRischio.date !== undefined) {
      data = currentPrestazione.dataStart;
    }

    const valRischioModel = new rischioModel(0, data, currentPrestazione);
    valRischioModel.sp_a1_livello = 1;
    valRischioModel.sp_a1_1 = false;
    valRischioModel.sp_a1_2 = false;
    valRischioModel.sp_a1_3 = false;
    valRischioModel.sp_a1_4 = false;
    valRischioModel.sp_a1_5 = false;
    valRischioModel.sp_a1_6 = false;
    valRischioModel.sp_a1_7 = false;
    valRischioModel.sp_a1_altro = false;
    valRischioModel.sp_a1_altro_note = "";

    valRischioModel.sp_a2_livello = 1;
    valRischioModel.sp_a2_1 = false;
    valRischioModel.sp_a2_2 = false;
    valRischioModel.sp_a2_3 = false;
    valRischioModel.sp_a2_altro = false;
    valRischioModel.sp_a2_altro_note = "";

    valRischioModel.sp_a3_livello = 1;
    valRischioModel.sp_a3_1 = false;
    valRischioModel.sp_a3_2 = false;
    valRischioModel.sp_a3_3 = false;
    valRischioModel.sp_a3_4 = false;
    valRischioModel.sp_a3_altro = false;
    valRischioModel.sp_a3_altro_note = "";

    valRischioModel.sp_a4_livello = 1;
    valRischioModel.sp_a4_1 = rischioPaeseCliente.paese === "Italia" && tenantTypeId === 3;
    valRischioModel.sp_a4_2 = false;
    valRischioModel.sp_a4_3 = false;
    valRischioModel.sp_a4_4 = false;
    valRischioModel.sp_a4_altro = false;
    valRischioModel.sp_a4_altro_note = "";

    valRischioModel.sp_a_subTotal = 4;

    valRischioModel.sp_b1_livello = 1;
    valRischioModel.sp_b1_1 = false;
    valRischioModel.sp_b1_2 = false;
    valRischioModel.sp_b1_3 = false;
    valRischioModel.sp_b1_altro = false;
    valRischioModel.sp_b1_altro_note = "";

    valRischioModel.sp_b2_livello = 1;
    valRischioModel.sp_b2_1 = false;
    valRischioModel.sp_b2_2 = false;
    valRischioModel.sp_b2_3 = false;
    valRischioModel.sp_b2_4 = false;
    valRischioModel.sp_b2_altro = false;
    valRischioModel.sp_b2_altro_note = "";

    valRischioModel.sp_b3_livello = 1;
    valRischioModel.sp_b3_1 = false;
    valRischioModel.sp_b3_2 = false;
    valRischioModel.sp_b3_3 = false;
    valRischioModel.sp_b3_altro = false;
    valRischioModel.sp_b3_altro_note = "";

    valRischioModel.sp_b4_livello = 1;
    valRischioModel.sp_b4_1 = false;
    valRischioModel.sp_b4_2 = false;
    valRischioModel.sp_b4_3 = false;
    valRischioModel.sp_b4_altro = false;
    valRischioModel.sp_b4_altro_note = "";

    valRischioModel.sp_b5_livello = 1;
    valRischioModel.sp_b5_1 = false;
    valRischioModel.sp_b5_2 = false;
    valRischioModel.sp_b5_3 = false;
    valRischioModel.sp_b5_altro = false;
    valRischioModel.sp_b5_altro_note = "";

    valRischioModel.sp_b6_livello = 1;
    valRischioModel.sp_b6_1 = rischioPaeseCliente.paese === "Italia" && tenantTypeId === 3;
    valRischioModel.sp_b6_2 = false;
    valRischioModel.sp_b6_3 = false;
    valRischioModel.sp_b6_4 = false;
    valRischioModel.sp_b6_altro = false;
    valRischioModel.sp_b6_altro_note = "";

    valRischioModel.sp_b_subTotal = 6;

    valRischioModel.sp_c1_1_livello = 1;
    valRischioModel.sp_c1_2_livello = 1;
    valRischioModel.sp_c1_3_livello = 1;
    valRischioModel.sp_c_subTotal = 3;

    valRischioModel.ef_ri_total = 0;
    valRischioModel.ef_sp_total = 0;
    valRischioModel.ef_total = 0;
    valRischioModel.ef_adeguata_livello = 1;

    //valRischioModel.hasTabellaB = true;
    valRischioModel.hasTabellaB =
      currentRischio.hasTabellaB !== undefined
        ? currentRischio.hasTabellaB
        : true;
    valRischioModel.providedBy =
      currentRischio.providedBy !== undefined
        ? currentRischio.providedBy
        : sharedDatas.getPropertyByName("userId");

    valRischioModel.createdBy = loggedUserId;

    return valRischioModel;
  };

  //#region Modal Delete
  const handleModalDeleteClose = () => {
    setIdToPerform(0);
    setShowDeleteModal(false);
  };
  const handleModalDeleteOkClick = async () => {
    try {
      setBackDropOpen(true);

      if (idToPerform > 0) {
        const delRischioResponse = await apiDeleteRischio.fetch({
          id: idToPerform,
        });
        if (delRischioResponse.data.errors !== undefined) {
          enqueueSnackbar(t("error.generale"), { variant: "error" });
        } else {
          //Aggiungo la Audit per tracciare l'azione
          const itemById = JSON.stringify(
            currentRischiList.filter((item) => item.id === idToPerform)[0]
          );
          apiAddAudit.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            area: bsaRischio,
            operation: "Delete",
            entityType: "rischio",
            entityId: idToPerform.toString(),
            oldValues: itemById,
            newValues: "",
          });

          //Aggiorno l'elenco delle prestazioni
          const valutazioni = Object.assign([], currentRischiList);
          const newValutazioni = valutazioni.filter(
            (item) => item.id !== idToPerform
          );
          setCurrentRischiList(newValutazioni);

          setCurrentRischio(emptyRischioModel());
          setCurrentRischioOriginal(emptyRischioModel());

          setSliderVal({
            sliderA1: 1,
            sliderA2: 1,
            sliderA3: 1,
            sliderA4: 1,
            sliderB1: 1,
            sliderB2: 1,
            sliderB3: 1,
            sliderB4: 1,
            sliderB5: 1,
            sliderB6: 1,
            sliderC1: 1,
            sliderC2: 1,
            sliderC3: 1,
          });

          setChbVal({
            chbA1_1: false,
            chbA1_2: false,
            chbA1_3: false,
            chbA1_4: false,
            chbA1_5: false,
            chbA1_Altro: false,
            chbA2_1: false,
            chbA2_2: false,
            chbA2_3: false,
            chbA2_Altro: false,
            chbA3_1: false,
            chbA3_2: false,
            chbA3_3: false,
            chbA3_4: false,
            chbA3_Altro: false,
            chbA4_1: false,
            chbA4_2: false,
            chbA4_Altro: false,
            chbB1_1: false,
            chbB1_2: false,
            chbB1_3: false,
            chbB1_Altro: false,
            chbB2_1: false,
            chbB2_2: false,
            chbB2_3: false,
            chbB2_4: false,
            chbB2_Altro: false,
            chbB3_1: false,
            chbB3_2: false,
            chbB3_Altro: false,
            chbB4_1: false,
            chbB4_2: false,
            chbB4_Altro: false,
            chbB5_1: false,
            chbB5_2: false,
            chbB5_3: false,
            chbB5_Altro: false,
            chbB6_1: false,
            chbB6_2: false,
            chbB6_3: false,
            chbB6_Altro: false,
            chbTabellaB: true,
          });

          setShowRischiForm(false);
          enqueueSnackbar(t("message.success"), { variant: "success" });
        }

        //Aggiorno la data di ultima modifica del fascicolo
        await apiUpdateFascicolo.fetch({
          id: fascicolo.id,
          name: fascicolo.name,
          date: fascicolo.date,
          isPrivate: fascicolo.isPrivate,
        });
      }
    } catch (e) {
      AddLog(e.message+ "Rischio Id:" + idToPerform,e.stack,"HandleModalDeleteOkClick")      
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setTenantTypeId(1);
      setIdToPerform(0);
      setShowDeleteModal(false);
      setBackDropOpen(false);
      reloadStatusFascicolo();
    }
  };
  //#endregion

  //EuroConference
  const handleEuroConfClick = async (itemId) => {
    try {
      setBackDropOpen(true);

      const jsonToEnc = {
        id: itemId,
        search: "",
        userfullname: sharedDatas.getPropertyByName("userModel").username,
        context: "TS420",
        from: "TS Antiriciclaggio",
        userid: loggedUserId,
        teamsystemid: sharedDatas.getPropertyByName("userModel").email,
      };

      const cryptedContentResp = await tsApiAntiRic.fetch({
        url:
          "aml:ts-anti-ric:api://euroconf/getEncParam?valueToEnc=" +
          JSON.stringify(jsonToEnc),
      });

      window.open(
        "https://portale.ecevolution.it/ssoEvolution?encParam=" +
          cryptedContentResp.data,
        "_blank",
        "noopener,noreferrer"
      );
    } catch (e) {
      AddLog(e.message,e.stack,"HandleEuroConfClick")
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
    }
  };

  //#region Reutrn Graphics
  return (
    <Fragment>
      <VaporPage.Section>
        <Loading open={backDropOpen} />

        {isDataLoaded && !userPermissions.canRead ? <NotAuth /> : null}

        {showDeleteModal && idToPerform > 0 ? (
          <ModalDelete
            show={showDeleteModal}
            onClose={handleModalDeleteClose}
            onOk={handleModalDeleteOkClick}
          ></ModalDelete>
        ) : null}

        {isDataLoaded && tenantTypeId > 0 && userPermissions.canRead ? (
          <Fragment>
            <Stack
              direction="row"
              spacing={2}
              divider={<Divider orientation="vertical" flexItem />}
            >
              <Stack direction="column" spacing={1} sx={{ width: "25%" }}>
                <Autocomplete
                  id="ddlPrestazioni"
                  key={uuidv4()}
                  size="small"
                  fullWidth
                  options={currentPrestazioniList}
                  sx={{ width: "100%" }}
                  getOptionLabel={(option) => option.prestazioneNome}
                  defaultValue={
                    currentPrestazioniList.filter(
                      (item) => item.id === currentPrestazione.id
                    )[0]
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Prestazione" required />
                  )}
                  onChange={handleChange}
                />

                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={it}
                >
                  <DatePicker
                    label={t("valutazione.form.dataValutazione")}
                    closeOnSelect
                    format="dd/MM/yyyy"
                    mask="__/__/____"
                    minDate={
                      isDateValid(currentPrestazione.dataStart)
                        ? new Date(currentPrestazione.dataStart)
                        : null
                    }
                    maxDate={new Date()}
                    value={
                      isDateValid(currentPrestazione.dataStart)
                        ? new Date(currentPrestazione.dataStart)
                        : null
                    }
                    slotProps={{
                      textField: {
                        placeholder: "dd/MM/yyyy",
                        size: "small",
                        fullWidth: true,
                      },
                    }}
                    onChange={handleChangeDate}
                    disabled={!isUserAllowedToManage}
                  />
                </LocalizationProvider>

                <Autocomplete
                  id="ddlUtenti"
                  key={uuidv4()}
                  size="small"
                  fullWidth
                  options={utentiList}
                  sx={{ width: "100%" }}
                  getOptionLabel={(option) => option.completeName}
                  defaultValue={
                    utentiList.filter(
                      (item) => item.userId === selectedUserId
                    )[0]
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("valutazione.form.professionista")}
                      required
                    />
                  )}
                  onChange={handleChange}
                  disabled={!isUserAllowedToManage}
                />

                {tenantTypeId === 1 ? (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="chbHasTabellaB"
                          checked={chbVal.chbTabellaB}
                          onChange={handleChange}                          
                        />
                      }
                      label={t("valutazione.form.compilaTabellaB")}
                      disabled={!isUserAllowedToManage}
                    />
                  </FormGroup>
                ) : null}

                <Divider variant="standard" sx={{ pt: 1 }}></Divider>

                {userPermissions.canCreate ? (
                  <Button
                    variant="contained"
                    size="small"
                    endIcon={<AddIcon />}
                    onClick={handleAddRischioClick}
                    disabled={!isUserAllowedToManage}
                  >
                    {t("valutazione.titoloAdd")}
                  </Button>
                ) : null}
                {currentRischiList.length > 0 ? (
                  <TableContainer component={Paper}>
                    <Table size="small" sx={{ mt: 2 }}>
                      <TableBody>
                        {currentRischiList.map((item) => (
                          <TableRow
                            key={uuidv4()}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            hover
                            selected={currentRischio.id === item.id}
                          >
                            <TableCell
                              key={uuidv4()}
                              component="th"
                              scope="row"
                            >
                              <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Stack direction="column">
                                  <ExtendedTypography
                                    variant="titleSmall"
                                    color="primary.main"
                                  >
                                    {t("rischio.rischioForm.effBy")}{" "}
                                    {
                                      allUtentiList.filter(
                                        (x) => x.userId === item.providedBy
                                      )[0].completeName
                                    }{" "}
                                    {t("rischio.rischioForm.inDt")}{" "}
                                    {new Date(item.date).toLocaleDateString()}
                                  </ExtendedTypography>
                                  <ExtendedTypography
                                    variant="body"
                                    color="text.secondary"
                                  >
                                    {tenantTypeId === 3 ? 
                                      `Totale complessivo A: ${item.sp_a_subTotal}` :
                                      `${t("valutazione.form.rischioInerente")}${" "}
                                      ${t("rischio.rischioForm.pond")}${" "}
                                      ${item.ef_ri_total}`
                                    }
                                  </ExtendedTypography>
                                  <ExtendedTypography
                                    variant="body"
                                    color="text.secondary"
                                  >
                                    {tenantTypeId === 3 ? 
                                      `Totale complessivo B: ${item.sp_b_subTotal}` :
                                      `${t("rischio.rischioForm.rskSpecPond")}${" "}
                                      ${item.ef_sp_total}`
                                    }                                    
                                  </ExtendedTypography>
                                  <ExtendedTypography
                                    variant="body"
                                    color="text.secondary"                              
                                  >
                                    {tenantTypeId === 3 ? 
                                      `Totale: ${item.sp_a_subTotal+item.sp_b_subTotal}` : 
                                      `${t("valutazione.form.totale")}:${" "}
                                      ${item.ef_total}` 
                                    }                                    
                                  </ExtendedTypography>
                                  <ExtendedTypography
                                    variant="body"
                                    color="text.secondary"
                                  >
                                    {t("rischio.rischioForm.nxtVer")}{" "}
                                    {new Date(
                                      item.dataControlloCostante
                                    ).toLocaleDateString()}
                                  </ExtendedTypography>
                                </Stack>
                                <Box sx={{ flexGrow: 1 }} />
                                <Stack direction="row">
                                  <IconButton
                                    edge="end"
                                    aria-label="select"
                                    color="primary"
                                    onClick={() =>
                                      handleSelectedRischioClick(item)
                                    }
                                    sx={{ mr: "1px !important" }}
                                  >
                                    <ModeEditIcon fontSize="small" />
                                  </IconButton>
                                  {userPermissions.canDelete &&
                                  currentPrestazione !== null &&
                                  currentPrestazione.id > 0 ? (
                                    <IconButton
                                      edge="end"
                                      aria-label="delete"
                                      color="error"
                                      onClick={() =>
                                        handleDeleteRischioClick(item)
                                      }
                                      sx={{ mr: "1px !important" }}
                                    >
                                      <DeleteIcon fontSize="small" />
                                    </IconButton>
                                  ) : null}
                                </Stack>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <NoData size="subtitle" />
                )}
              </Stack>
              <Stack direction="column" spacing={1} sx={{ width: "75%" }}>
                {showRischiForm ? (
                  <Fragment>
                    {!isUserAllowedToManage ? (
                      <Stack direction="column" sx={{ width: "100%" }}>
                        <Alert variant="outlined" severity="warning">
                          {t("rischio.rischioForm.prestNotEdt")}
                        </Alert>
                      </Stack>
                    ) : null}
                    <Stack
                      direction="row"
                      spacing={2}
                      divider={<Divider orientation="vertical" flexItem />}
                      sx={{ pt: 1 }}
                    >
                      {/* Colonna centrale con slider */}
                      <Stack
                        direction="column"
                        spacing={1}
                        sx={{ width: "70%" }}
                      >
                        <ExtendedTypography
                          variant="titleSmall"
                          color="primary.main"
                        >
                          {t("valutazione.form.rischioSpecifico")}
                        </ExtendedTypography>

                        {tenantTypeId !== 3 &&
                          <Stack direction="row">
                            <Button
                              variant="text"
                              size="small"
                              startIcon={
                                <img
                                  src="/img/EcLogo.png"
                                  width={20}
                                  alt="EcoLogo"
                                />
                              }
                              sx={{ width: "fit-content !important" }}
                              onClick={() => handleEuroConfClick(17236)}
                            >
                              {t("rischio.rischioForm.antRisk")}
                            </Button>
                          </Stack>
                        }

                        <TableContainer component={Paper} sx={{ mt: 2 }}>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell align="left" width="70%">
                                  <ExtendedTypography
                                    variant="subtitle2"
                                    fontStyle={{ fontWeight: "bold" }}
                                  >
                                    {t("valutazione.form.tabellaA.titolo")}
                                  </ExtendedTypography>
                                </TableCell>
                                <TableCell align="center" width="15%">
                                    <ExtendedTypography
                                      variant="subtitle2"
                                      fontStyle={{ fontWeight: "bold" }}
                                    >
                                      {t(
                                        "valutazione.form.fattoreRischioRiscontrato"
                                      )}
                                    </ExtendedTypography>
                                </TableCell>
                                <TableCell align="center" width="15%">
                                  <ExtendedTypography
                                    variant="subtitle2"
                                    fontStyle={{ fontWeight: "bold" }}
                                  >
                                    {t(
                                      "valutazione.form.livelloRischioSpecifico"
                                    )}
                                  </ExtendedTypography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell colSpan={2}>
                                  <ExtendedTypography
                                    variant="subtitle2"
                                    fontStyle={
                                      tenantTypeId === 1 || tenantTypeId === 3
                                        ? { fontWeight: "bold" }
                                        : null
                                    }
                                  >
                                    {tenantTypeId === 3 ? "A.1 - Identità del cliente" : t("valutazione.form.tabellaA.a1")}
                                  </ExtendedTypography>
                                </TableCell> 
                                <TableCell align="center" colSpan={1}>
                                  <Slider
                                    id="sliderA1"
                                    name="sliderA1"
                                    step={1}
                                    marks={tenantTypeId === 3 ? sliderLivelliAvv : sliderLivelli}
                                    min={1}
                                    max={tenantTypeId === 3 ? 5 : 4}
                                    valueLabelDisplay="auto"
                                    value={sliderVal.sliderA1}
                                    onChange={handleChange}
                                    disabled={!isUserAllowedToManage}
                                  />
                                </TableCell>
                              </TableRow>

                              {tenantTypeId === 1 ? (
                                <Fragment>
                                  <TableRow
                                    hover
                                    onClick={() =>
                                      handleTableRowClick("chbA1_1")
                                    }
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell align="left" width="70%">
                                      {t("valutazione.form.tabellaA.a1_1")}
                                    </TableCell>
                                    <TableCell align="center" width="15%">
                                      <Checkbox
                                        id="chbA1_1"
                                        checked={chbVal.chbA1_1}
                                        onChange={handleChange}
                                        disabled={!isUserAllowedToManage}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="15%"
                                    ></TableCell>
                                  </TableRow>
                                  <TableRow
                                    hover
                                    onClick={() =>
                                      handleTableRowClick("chbA1_2")
                                    }
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell align="left" width="70%">
                                      {t("valutazione.form.tabellaA.a1_2")}
                                    </TableCell>
                                    <TableCell align="center" width="15%">
                                      <Checkbox
                                        id="chbA1_2"
                                        checked={chbVal.chbA1_2}
                                        onChange={handleChange}
                                        disabled={!isUserAllowedToManage}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="15%"
                                    ></TableCell>
                                  </TableRow>
                                  <TableRow
                                    hover
                                    onClick={() =>
                                      handleTableRowClick("chbA1_3")
                                    }
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell align="left" width="70%">
                                      {t("valutazione.form.tabellaA.a1_3")}
                                    </TableCell>
                                    <TableCell align="center" width="15%">
                                      <Checkbox
                                        id="chbA1_3"
                                        checked={chbVal.chbA1_3}
                                        onChange={handleChange}
                                        disabled={!isUserAllowedToManage}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="15%"
                                    ></TableCell>
                                  </TableRow>
                                  <TableRow
                                    hover
                                    onClick={() =>
                                      handleTableRowClick("chbA1_4")
                                    }
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell align="left" width="70%">
                                      {t("valutazione.form.tabellaA.a1_4")}
                                    </TableCell>
                                    <TableCell align="center" width="15%">
                                      <Checkbox
                                        id="chbA1_4"
                                        checked={chbVal.chbA1_4}
                                        onChange={handleChange}
                                        disabled={!isUserAllowedToManage}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="15%"
                                    ></TableCell>
                                  </TableRow>
                                  <TableRow
                                    hover
                                    onClick={() =>
                                      handleTableRowClick("chbA1_5")
                                    }
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell align="left" width="70%">
                                      {t("valutazione.form.tabellaA.a1_5")}
                                    </TableCell>
                                    <TableCell align="center" width="15%">
                                      <Checkbox
                                        id="chbA1_5"
                                        checked={chbVal.chbA1_5}
                                        onChange={handleChange}
                                        disabled={!isUserAllowedToManage}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="15%"
                                    ></TableCell>
                                  </TableRow>
                                  <TableRow hover>
                                    <TableCell align="left" width="70%">
                                      <Stack direction="column" spacing={1}>
                                        <ExtendedTypography variant="subtitle2">
                                          {t("valutazione.form.tabellaA.a1_6")}
                                        </ExtendedTypography>
                                        <TextField
                                          id="txtAltroA1"
                                          key={uuidv4()}
                                          label=""
                                          size="small"
                                          fullWidth
                                          multiline
                                          rows={2}
                                          sx={
                                            chbVal.chbA1_Altro
                                              ? { display: "block" }
                                              : { display: "none" }
                                          }
                                          defaultValue={
                                            currentRischio.sp_a1_altro_note
                                          }
                                          onChange={handleChange}
                                          inputProps={{ autocomplete: "nope" }}
                                          disabled={!isUserAllowedToManage}
                                        />
                                      </Stack>
                                    </TableCell>
                                    <TableCell align="center" width="15%">
                                      <Checkbox
                                        id="chbA1_Altro"
                                        checked={chbVal.chbA1_Altro}
                                        onChange={handleChange}
                                        disabled={!isUserAllowedToManage}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="15%"
                                    ></TableCell>
                                  </TableRow>
                                </Fragment>
                              ) : null}

                              {tenantTypeId === 3? (                                
                                <Fragment>                                    
                                  <TableRow
                                    hover
                                    onClick={() =>handleRadioChange("chbA1_1")}
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell align="left" width="70%">
                                      Ditta individuale
                                    </TableCell>
                                    <TableCell align="center" width="15%">                                      
                                      <Radio
                                        checked={selectedA1val === "chbA1_1"}                                        
                                        id="chbA1_1"
                                        value="chbA1_1"
                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'A' }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="15%"
                                    ></TableCell>
                                  </TableRow>
                                  <TableRow
                                    hover
                                    onClick={() =>
                                      handleRadioChange("chbA1_2")
                                    }
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell align="left" width="70%">
                                      Associazione professionale
                                    </TableCell>
                                    <TableCell align="center" width="15%">                                      
                                      <Radio
                                        checked={selectedA1val === "chbA1_2"}                                        
                                        value="chbA1_2"
                                        id="chbA1_2"
                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'A' }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="15%"
                                    ></TableCell>
                                  </TableRow>
                                  <TableRow
                                    hover
                                    onClick={() =>
                                      handleRadioChange("chbA1_3")
                                    }
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell align="left" width="70%">
                                      Società di persone
                                    </TableCell>
                                    <TableCell align="center" width="15%">                                      
                                      <Radio
                                        checked={selectedA1val === "chbA1_3"}                                        
                                        value="chbA1_3"
                                        id="chbA1_3"
                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'A' }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="15%"
                                    ></TableCell>
                                  </TableRow>
                                  <TableRow
                                    hover
                                    onClick={() =>
                                      handleRadioChange("chbA1_4")
                                    }
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell align="left" width="70%">
                                      Società di capitali
                                    </TableCell>
                                    <TableCell align="center" width="15%">                                      
                                      <Radio
                                        checked={selectedA1val === "chbA1_4"}                                        
                                        value="chbA1_4"
                                        id="chbA1_4"
                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'A' }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="15%"
                                    ></TableCell>
                                  </TableRow>
                                  <TableRow
                                    hover
                                    onClick={() =>
                                      handleRadioChange("chbA1_5")
                                    }
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell align="left" width="70%">
                                      Trust
                                    </TableCell>
                                    <TableCell align="center" width="15%">                                      
                                      <Radio
                                        checked={selectedA1val === "chbA1_5"}                                        
                                        value="chbA1_5"
                                        id="chbA1_5"
                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'A' }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="15%"
                                    ></TableCell>
                                  </TableRow>
                                  <TableRow
                                    hover
                                    onClick={() =>
                                      handleRadioChange("chbA1_6")
                                    }
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell align="left" width="70%">
                                      Asocietà fiduciaria
                                    </TableCell>
                                    <TableCell align="center" width="15%">                                      
                                      <Radio
                                        checked={selectedA1val === "chbA1_6"}                                        
                                        value="chbA1_6"
                                        id="chbA1_6"
                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'A' }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="15%"
                                    ></TableCell>
                                  </TableRow>
                                  <TableRow
                                    hover
                                    onClick={() =>
                                      handleRadioChange("chbA1_7")
                                    }
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell align="left" width="70%">
                                      Persona fisica
                                    </TableCell>
                                    <TableCell align="center" width="15%">                                      
                                      <Radio
                                        checked={selectedA1val === "chbA1_7"}                                        
                                        value="chbA1_7"
                                        id="chbA1_7"
                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'A' }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="15%"
                                    ></TableCell>
                                  </TableRow>
                                </Fragment>                                
                              ): null}

                              <TableRow>
                                <TableCell colSpan={2}>
                                  <ExtendedTypography
                                    variant="subtitle2"
                                    fontStyle={
                                      tenantTypeId === 1 || tenantTypeId === 3
                                        ? { fontWeight: "bold" }
                                        : null
                                    }
                                  >
                                    {t("valutazione.form.tabellaA.a2")}
                                  </ExtendedTypography>
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  <Slider
                                    id="sliderA2"
                                    name="sliderA2"
                                    step={1}
                                    marks={tenantTypeId === 3 ? sliderLivelliAvv : sliderLivelli}
                                    min={1}
                                    max={tenantTypeId === 3 ? 5 : 4}
                                    valueLabelDisplay="auto"
                                    value={sliderVal.sliderA2}
                                    onChange={handleChange}
                                    disabled={!isUserAllowedToManage}
                                  />
                                </TableCell>
                              </TableRow>

                              {tenantTypeId === 1 ? (
                                <Fragment>
                                  <TableRow
                                    hover
                                    onClick={() =>
                                      handleTableRowClick("chbA2_1")
                                    }
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell align="left" width="70%">
                                      {t("valutazione.form.tabellaA.a2_1")}
                                    </TableCell>
                                    <TableCell align="center" width="15%">
                                      <Checkbox
                                        id="chbA2_1"
                                        checked={chbVal.chbA2_1}
                                        onChange={handleChange}
                                        disabled={!isUserAllowedToManage}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="15%"
                                    ></TableCell>
                                  </TableRow>
                                  <TableRow
                                    hover
                                    onClick={() =>
                                      handleTableRowClick("chbA2_2")
                                    }
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell align="left" width="70%">
                                      {t("valutazione.form.tabellaA.a2_2")}
                                    </TableCell>
                                    <TableCell align="center" width="15%">
                                      <Checkbox
                                        id="chbA2_2"
                                        checked={chbVal.chbA2_2}
                                        onChange={handleChange}
                                        disabled={!isUserAllowedToManage}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="15%"
                                    ></TableCell>
                                  </TableRow>
                                  <TableRow
                                    hover
                                    onClick={() =>
                                      handleTableRowClick("chbA2_3")
                                    }
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell align="left" width="70%">
                                      {t("valutazione.form.tabellaA.a2_3")}
                                    </TableCell>
                                    <TableCell align="center" width="15%">
                                      <Checkbox
                                        id="chbA2_3"
                                        checked={chbVal.chbA2_3}
                                        onChange={handleChange}
                                        disabled={!isUserAllowedToManage}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="15%"
                                    ></TableCell>
                                  </TableRow>
                                  <TableRow hover>
                                    <TableCell align="left" width="70%">
                                      <Stack direction="column" spacing={1}>
                                        <ExtendedTypography variant="subtitle2">
                                          {t("valutazione.form.tabellaA.a2_4")}
                                        </ExtendedTypography>
                                        <TextField
                                          id="txtAltroA2"
                                          key={uuidv4()}
                                          label=""
                                          size="small"
                                          fullWidth
                                          multiline
                                          rows={2}
                                          sx={
                                            chbVal.chbA2_Altro
                                              ? { display: "block" }
                                              : { display: "none" }
                                          }
                                          defaultValue={
                                            currentRischio.sp_a2_altro_note
                                          }
                                          onChange={handleChange}
                                          inputProps={{ autocomplete: "nope" }}
                                          disabled={!isUserAllowedToManage}
                                        />
                                      </Stack>
                                    </TableCell>
                                    <TableCell align="center" width="15%">
                                      <Checkbox
                                        id="chbA2_Altro"
                                        checked={chbVal.chbA2_Altro}
                                        onChange={handleChange}
                                        disabled={!isUserAllowedToManage}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="15%"
                                    ></TableCell>
                                  </TableRow>
                                </Fragment>
                              ) : null}

                              {tenantTypeId === 3? (                                
                                <Fragment>                                    
                                  <TableRow
                                    hover
                                    onClick={() =>handleRadioChange("chbA2_1")}
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell align="left" width="70%">
                                      Operazioni "normali"
                                    </TableCell>
                                    <TableCell align="center" width="15%">                                      
                                      <Radio
                                        checked={selectedA2val === "chbA2_1"}                                        
                                        id="chbA2_1"
                                        value="chbA2_1"
                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'A' }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="15%"
                                    ></TableCell>
                                  </TableRow>
                                  <TableRow
                                    hover
                                    onClick={() =>
                                      handleRadioChange("chbA2_2")
                                    }
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell align="left" width="70%">
                                      Operazioni di particolare rilevanza
                                    </TableCell>
                                    <TableCell align="center" width="15%">                                      
                                      <Radio
                                        checked={selectedA2val === "chbA2_2"}                                        
                                        value="chbA2_2"
                                        id="chbA2_2"
                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'A' }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="15%"
                                    ></TableCell>
                                  </TableRow>
                                  <TableRow
                                    hover
                                    onClick={() =>
                                      handleRadioChange("chbA2_3")
                                    }
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell align="left" width="70%">
                                      Operazioni "anomale"
                                    </TableCell>
                                    <TableCell align="center" width="15%">                                      
                                      <Radio
                                        checked={selectedA2val === "chbA2_3"}                                        
                                        value="chbA2_3"
                                        id="chbA2_3"
                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'A' }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="15%"
                                    ></TableCell>
                                  </TableRow>                                  
                                </Fragment>                                
                              ): null}

                              <TableRow>
                                <TableCell colSpan={2}>
                                  <ExtendedTypography
                                    variant="subtitle2"
                                    fontStyle={
                                      tenantTypeId === 1 || tenantTypeId === 3
                                        ? { fontWeight: "bold" }
                                        : null
                                    }
                                  >
                                    {tenantTypeId === 3 ? "A.3 - Comportamento tenuto al momento dell’operazione e relazioni tra le parti" : t("valutazione.form.tabellaA.a3")}
                                  </ExtendedTypography>
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  <Slider
                                    id="sliderA3"
                                    name="sliderA3"
                                    step={1}
                                    marks={tenantTypeId === 3 ? sliderLivelliAvv : sliderLivelli}
                                    min={1}
                                    max={tenantTypeId === 3 ? 5 : 4}
                                    valueLabelDisplay="auto"
                                    value={sliderVal.sliderA3}
                                    onChange={handleChange}
                                    disabled={!isUserAllowedToManage}
                                  />
                                </TableCell>
                              </TableRow>

                              {tenantTypeId === 1 ? (
                                <Fragment>
                                  <TableRow
                                    hover
                                    onClick={() =>
                                      handleTableRowClick("chbA3_1")
                                    }
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell align="left" width="70%">
                                      {t("valutazione.form.tabellaA.a3_1")}
                                    </TableCell>
                                    <TableCell align="center" width="15%">
                                      <Checkbox
                                        id="chbA3_1"
                                        checked={chbVal.chbA3_1}
                                        onChange={handleChange}
                                        disabled={!isUserAllowedToManage}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="15%"
                                    ></TableCell>
                                  </TableRow>
                                  <TableRow
                                    hover
                                    onClick={() =>
                                      handleTableRowClick("chbA3_2")
                                    }
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell align="left" width="70%">
                                      {t("valutazione.form.tabellaA.a3_2")}
                                    </TableCell>
                                    <TableCell align="center" width="15%">
                                      <Checkbox
                                        id="chbA3_2"
                                        checked={chbVal.chbA3_2}
                                        onChange={handleChange}
                                        disabled={!isUserAllowedToManage}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="15%"
                                    ></TableCell>
                                  </TableRow>
                                  <TableRow
                                    hover
                                    onClick={() =>
                                      handleTableRowClick("chbA3_3")
                                    }
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell align="left" width="70%">
                                      {t("valutazione.form.tabellaA.a3_3")}
                                    </TableCell>
                                    <TableCell align="center" width="15%">
                                      <Checkbox
                                        id="chbA3_3"
                                        checked={chbVal.chbA3_3}
                                        onChange={handleChange}
                                        disabled={!isUserAllowedToManage}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="15%"
                                    ></TableCell>
                                  </TableRow>
                                  <TableRow
                                    hover
                                    onClick={() =>
                                      handleTableRowClick("chbA3_4")
                                    }
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell align="left" width="70%">
                                      {t("valutazione.form.tabellaA.a3_4")}
                                    </TableCell>
                                    <TableCell align="center" width="15%">
                                      <Checkbox
                                        id="chbA3_4"
                                        checked={chbVal.chbA3_4}
                                        onChange={handleChange}
                                        disabled={!isUserAllowedToManage}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="15%"
                                    ></TableCell>
                                  </TableRow>
                                  <TableRow hover>
                                    <TableCell align="left" width="70%">
                                      <Stack direction="column" spacing={1}>
                                        <ExtendedTypography variant="subtitle2">
                                          {t("valutazione.form.tabellaA.a3_5")}
                                        </ExtendedTypography>
                                        <TextField
                                          id="txtAltroA3"
                                          key={uuidv4()}
                                          label=""
                                          size="small"
                                          fullWidth
                                          multiline
                                          rows={2}
                                          sx={
                                            chbVal.chbA3_Altro
                                              ? { display: "block" }
                                              : { display: "none" }
                                          }
                                          defaultValue={
                                            currentRischio.sp_a3_altro_note
                                          }
                                          onChange={handleChange}
                                          inputProps={{ autocomplete: "nope" }}
                                          disabled={!isUserAllowedToManage}
                                        />
                                      </Stack>
                                    </TableCell>
                                    <TableCell align="center" width="15%">
                                      <Checkbox
                                        id="chbA3_Altro"
                                        checked={chbVal.chbA3_Altro}
                                        onChange={handleChange}
                                        disabled={!isUserAllowedToManage}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="15%"
                                    ></TableCell>
                                  </TableRow>
                                </Fragment>
                              ) : null}

                              {tenantTypeId === 3? (                                
                                <Fragment>                                    
                                  <TableRow
                                    hover
                                    onClick={() =>handleRadioChange("chbA3_1")}
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell align="left" width="70%">
                                      Nella norma (cliente collaborativo/trasparente)
                                    </TableCell>
                                    <TableCell align="center" width="15%">                                      
                                      <Radio
                                        checked={selectedA3val === "chbA3_1"}                                        
                                        id="chbA3_1"
                                        value="chbA3_1"
                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'A' }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="15%"
                                    ></TableCell>
                                  </TableRow>
                                  <TableRow
                                    hover
                                    onClick={() =>
                                      handleRadioChange("chbA3_2")
                                    }
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell align="left" width="70%">
                                      Fuori dalla norma(cliente reticente/poco trasparente)
                                    </TableCell>
                                    <TableCell align="center" width="15%">                                      
                                      <Radio
                                        checked={selectedA3val === "chbA3_2"}                                        
                                        value="chbA3_2"
                                        id="chbA3_2"
                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'A' }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="15%"
                                    ></TableCell>
                                  </TableRow>                                                                  
                                </Fragment>                                
                              ): null}

                              <TableRow>
                                <TableCell colSpan={2}>
                                  <ExtendedTypography
                                    variant="subtitle2"
                                    fontStyle={
                                      tenantTypeId === 1 || tenantTypeId === 3
                                        ? { fontWeight: "bold" }
                                        : null
                                    }
                                  >
                                    {tenantTypeId === 3 ? "A.4 - Area geografica di residenza ": t("valutazione.form.tabellaA.a4")}
                                  </ExtendedTypography>
                                  {rischioPaeseCliente.paese !== "Italia" ? (
                                    <VaporTag
                                      variant="standard"
                                      label={
                                        rischioPaeseCliente.paese +
                                        " - " +
                                        rischioPaeseCliente.rischio +
                                        " (" +
                                        rischioPaeseCliente.value +
                                        ")"
                                      }
                                      sx={{
                                        ...rischioPaeseCliente.color,
                                        mr: 1,
                                      }}
                                    />
                                  ) : null}

                                  {rischioPaeseCliente.paese === "Italia" ? (
                                    <VaporTag
                                      variant="standard"
                                      label={
                                        rischioProvinciaCliente.provincia +
                                        " - " +
                                        rischioProvinciaCliente.rischio +
                                        " (" +
                                        rischioProvinciaCliente.value +
                                        ")"
                                      }
                                      sx={rischioProvinciaCliente.color}
                                    />
                                  ) : null}
                                </TableCell>
                                <TableCell align="center" colSpan={1}>
                                  <Slider
                                    id="sliderA4"
                                    name="sliderA4"
                                    step={1}
                                    marks={tenantTypeId === 3 ? sliderLivelliAvv : sliderLivelli}
                                    min={1}
                                    max={tenantTypeId === 3 ? 5 : 4}
                                    valueLabelDisplay="auto"
                                    value={sliderVal.sliderA4}
                                    onChange={handleChange}
                                    disabled={!isUserAllowedToManage}
                                  />
                                </TableCell>
                              </TableRow>

                              {tenantTypeId === 1 ? (
                                <Fragment>
                                  <TableRow
                                    hover
                                    onClick={() =>
                                      handleTableRowClick("chbA4_1")
                                    }
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell align="left" width="70%">
                                      {t("valutazione.form.tabellaA.a4_1")}
                                    </TableCell>
                                    <TableCell align="center" width="15%">
                                      <Checkbox
                                        id="chbA4_1"
                                        checked={chbVal.chbA4_1}
                                        onChange={handleChange}
                                        disabled={!isUserAllowedToManage}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="15%"
                                    ></TableCell>
                                  </TableRow>
                                  <TableRow
                                    hover
                                    onClick={() =>
                                      handleTableRowClick("chbA4_2")
                                    }
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell align="left" width="70%">
                                      {t("valutazione.form.tabellaA.a4_2")}
                                    </TableCell>
                                    <TableCell align="center" width="15%">
                                      <Checkbox
                                        id="chbA4_2"
                                        checked={chbVal.chbA4_2}
                                        onChange={handleChange}
                                        disabled={!isUserAllowedToManage}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="15%"
                                    ></TableCell>
                                  </TableRow>
                                  <TableRow hover>
                                    <TableCell align="left" width="70%">
                                      <Stack direction="column" spacing={1}>
                                        <ExtendedTypography variant="subtitle2">
                                          {t("valutazione.form.tabellaA.a4_3")}
                                        </ExtendedTypography>
                                        <TextField
                                          id="txtAltroA4"
                                          key={uuidv4()}
                                          label=""
                                          size="small"
                                          fullWidth
                                          multiline
                                          rows={2}
                                          sx={
                                            chbVal.chbA4_Altro
                                              ? { display: "block" }
                                              : { display: "none" }
                                          }
                                          defaultValue={
                                            currentRischio.sp_a4_altro_note
                                          }
                                          onChange={handleChange}
                                          inputProps={{ autocomplete: "nope" }}
                                          disabled={!isUserAllowedToManage}
                                        />
                                      </Stack>
                                    </TableCell>
                                    <TableCell align="center" width="15%">
                                      <Checkbox
                                        id="chbA4_Altro"
                                        checked={chbVal.chbA4_Altro}
                                        onChange={handleChange}
                                        disabled={!isUserAllowedToManage}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="15%"
                                    ></TableCell>
                                  </TableRow>
                                </Fragment>
                              ) : null}

                              {tenantTypeId === 3? (                                
                                <Fragment>                                    
                                  <TableRow
                                    hover
                                    onClick={() =>handleRadioChange("chbA4_1")}
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell align="left" width="70%">
                                      Italia
                                    </TableCell>
                                    <TableCell align="center" width="15%">                                      
                                      <Radio
                                        checked={selectedA4val === "chbA4_1"}                                        
                                        id="chbA4_1"
                                        value="chbA4_1"
                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'A' }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="15%"
                                    ></TableCell>
                                  </TableRow>
                                  <TableRow
                                    hover
                                    onClick={() =>
                                      handleRadioChange("chbA4_2")
                                    }
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell align="left" width="70%">
                                      Paesi UE
                                    </TableCell>
                                    <TableCell align="center" width="15%">                                      
                                      <Radio
                                        checked={selectedA4val === "chbA4_2"}                                        
                                        value="chbA4_2"
                                        id="chbA4_2"
                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'A' }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="15%"
                                    ></TableCell>
                                  </TableRow>
                                  <TableRow
                                    hover
                                    onClick={() =>
                                      handleRadioChange("chbA4_3")
                                    }
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell align="left" width="70%">
                                      Paesi extra UE
                                    </TableCell>
                                    <TableCell align="center" width="15%">                                      
                                      <Radio
                                        checked={selectedA4val === "chbA4_3"}                                        
                                        value="chbA4_3"
                                        id="chbA4_3"
                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'A' }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="15%"
                                    ></TableCell>
                                  </TableRow>  
                                  <TableRow
                                    hover
                                    onClick={() =>
                                      handleRadioChange("chbA4_4")
                                    }
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell align="left" width="70%">
                                      Terrirori off shore
                                    </TableCell>
                                    <TableCell align="center" width="15%">                                      
                                      <Radio
                                        checked={selectedA4val === "chbA4_4"}                                        
                                        value="chbA4_4"
                                        id="chbA4_4"
                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'A' }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="15%"
                                    ></TableCell>
                                  </TableRow>                                                                    
                                </Fragment>                                
                              ): null}

                              <TableRow
                                sx={{ backgroundColor: "hsl(199, 100%, 40%)" }}
                              >
                                <TableCell
                                  colSpan="2"
                                  align="right"
                                  width="85%"
                                >
                                  <ExtendedTypography
                                    variant="subtitle2"
                                    fontStyle={{
                                      fontWeight: "bold",
                                      color: "white",
                                    }}
                                  >
                                    {t("rischio.rischioForm.cstSpRsk")}
                                  </ExtendedTypography>
                                </TableCell>
                                <TableCell align="center" width="15%">
                                  <ExtendedTypography
                                    variant="subtitle2"
                                    fontStyle={{
                                      fontWeight: "bold",
                                      color: "white",
                                    }}
                                  >
                                    {subTotaleA}
                                  </ExtendedTypography>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>

                        {chbVal.chbTabellaB ? (
                          <Fragment>
                            <TableContainer component={Paper} sx={{ mt: 2 }}>
                              <Table size="small">
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="left" width="70%">
                                      <ExtendedTypography
                                        variant="subtitle2"
                                        fontStyle={{ fontWeight: "bold" }}
                                      >
                                        {t("valutazione.form.tabellaB.titolo")}
                                      </ExtendedTypography>
                                    </TableCell>
                                    <TableCell align="center" width="15%">
                                      <ExtendedTypography
                                        variant="subtitle2"
                                        fontStyle={{ fontWeight: "bold" }}
                                      >
                                        {t(
                                          "valutazione.form.fattoreRischioRiscontrato"
                                        )}
                                      </ExtendedTypography>
                                    </TableCell>
                                    <TableCell align="center" width="15%">
                                      <ExtendedTypography
                                        variant="subtitle2"
                                        fontStyle={{ fontWeight: "bold" }}
                                      >
                                        {t(
                                          "valutazione.form.livelloRischioSpecifico"
                                        )}
                                      </ExtendedTypography>
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell colSpan={2}>
                                      <ExtendedTypography
                                        variant="subtitle2"
                                        fontStyle={
                                          tenantTypeId === 1 || tenantTypeId === 3
                                            ? { fontWeight: "bold" }
                                            : null
                                        }
                                      >
                                        {tenantTypeId === 3 ? "B.1 - Tipologia" : t("valutazione.form.tabellaB.b1")}
                                      </ExtendedTypography>
                                    </TableCell>
                                    <TableCell align="center" colSpan={1}>
                                      <Slider
                                        id="sliderB1"
                                        name="sliderB1"
                                        step={1}
                                        marks={tenantTypeId === 3 ? sliderLivelliAvv : sliderLivelli}
                                        min={1}
                                        max={tenantTypeId === 3 ? 5 : 4}
                                        valueLabelDisplay="auto"
                                        value={sliderVal.sliderB1}
                                        onChange={handleChange}
                                        disabled={!isUserAllowedToManage}
                                      />
                                    </TableCell>
                                  </TableRow>

                                  {tenantTypeId === 1 ? (
                                    <Fragment>
                                      <TableRow
                                        hover
                                        onClick={() =>
                                          handleTableRowClick("chbB1_1")
                                        }
                                        sx={{ cursor: "pointer" }}
                                      >
                                        <TableCell align="left" width="70%">
                                          {t("valutazione.form.tabellaB.b1_1")}
                                        </TableCell>
                                        <TableCell align="center" width="15%">
                                          <Checkbox
                                            id="chbB1_1"
                                            checked={chbVal.chbB1_1}
                                            onChange={handleChange}
                                            disabled={!isUserAllowedToManage}
                                          />
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          width="15%"
                                        ></TableCell>
                                      </TableRow>
                                      <TableRow
                                        hover
                                        onClick={() =>
                                          handleTableRowClick("chbB1_2")
                                        }
                                        sx={{ cursor: "pointer" }}
                                      >
                                        <TableCell align="left" width="70%">
                                          {t("valutazione.form.tabellaB.b1_2")}
                                        </TableCell>
                                        <TableCell align="center" width="15%">
                                          <Checkbox
                                            id="chbB1_2"
                                            checked={chbVal.chbB1_2}
                                            onChange={handleChange}
                                            disabled={!isUserAllowedToManage}
                                          />
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          width="15%"
                                        ></TableCell>
                                      </TableRow>
                                      <TableRow
                                        hover
                                        onClick={() =>
                                          handleTableRowClick("chbB1_3")
                                        }
                                        sx={{ cursor: "pointer" }}
                                      >
                                        <TableCell align="left" width="70%">
                                          {t("valutazione.form.tabellaB.b1_3")}
                                        </TableCell>
                                        <TableCell align="center" width="15%">
                                          <Checkbox
                                            id="chbB1_3"
                                            checked={chbVal.chbB1_3}
                                            onChange={handleChange}
                                            disabled={!isUserAllowedToManage}
                                          />
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          width="15%"
                                        ></TableCell>
                                      </TableRow>
                                      <TableRow hover>
                                        <TableCell align="left" width="70%">
                                          <Stack direction="column" spacing={1}>
                                            <ExtendedTypography variant="subtitle2">
                                              {t(
                                                "valutazione.form.tabellaB.b1_4"
                                              )}
                                            </ExtendedTypography>
                                            <TextField
                                              id="txtAltroB1"
                                              key={uuidv4()}
                                              label=""
                                              size="small"
                                              fullWidth
                                              multiline
                                              rows={2}
                                              sx={
                                                chbVal.chbB1_Altro
                                                  ? { display: "block" }
                                                  : { display: "none" }
                                              }
                                              defaultValue={
                                                currentRischio.sp_b1_altro_note
                                              }
                                              onChange={handleChange}
                                              inputProps={{
                                                autocomplete: "nope",
                                              }}
                                              disabled={!isUserAllowedToManage}
                                            />
                                          </Stack>
                                        </TableCell>
                                        <TableCell align="center" width="15%">
                                          <Checkbox
                                            id="chbB1_Altro"
                                            checked={chbVal.chbB1_Altro}
                                            onChange={handleChange}
                                            disabled={!isUserAllowedToManage}
                                          />
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          width="15%"
                                        ></TableCell>
                                      </TableRow>
                                    </Fragment>
                                  ) : null}

                                  {tenantTypeId === 3? (                                
                                    <Fragment>                                    
                                      <TableRow
                                        hover
                                        onClick={() =>handleRadioChange("chbB1_1")}
                                        sx={{ cursor: "pointer" }}
                                      >
                                        <TableCell align="left" width="70%">
                                          Ordinaria
                                        </TableCell>
                                        <TableCell align="center" width="15%">                                      
                                          <Radio
                                            checked={selectedB1val === "chbB1_1"}                                        
                                            id="chbB1_1"
                                            value="chbB1_1"
                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'A' }}
                                          />
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          width="15%"
                                        ></TableCell>
                                      </TableRow>
                                      <TableRow
                                        hover
                                        onClick={() =>
                                          handleRadioChange("chbB1_2")
                                        }
                                        sx={{ cursor: "pointer" }}
                                      >
                                        <TableCell align="left" width="70%">
                                          Starordinaria
                                        </TableCell>
                                        <TableCell align="center" width="15%">                                      
                                          <Radio
                                            checked={selectedB1val === "chbB1_2"}                                        
                                            value="chbB1_2"
                                            id="chbB1_2"
                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'A' }}
                                          />
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          width="15%"
                                        ></TableCell>
                                      </TableRow>                                                                                                         
                                    </Fragment>                                
                                  ): null}

                                  <TableRow>
                                    <TableCell colSpan={2}>
                                      <ExtendedTypography
                                        variant="subtitle2"
                                        fontStyle={
                                          tenantTypeId === 1 || tenantTypeId === 3
                                            ? { fontWeight: "bold" }
                                            : null
                                        }
                                      >
                                        {tenantTypeId === 3 ? "B.2 - Modalità di svolgimento" : t("valutazione.form.tabellaB.b2")}
                                      </ExtendedTypography>
                                    </TableCell>
                                    <TableCell align="center" colSpan={1}>
                                      <Slider
                                        id="sliderB2"
                                        name="sliderB2"
                                        step={1}
                                        marks={tenantTypeId === 3 ? sliderLivelliAvv : sliderLivelli}
                                        min={1}
                                        max={tenantTypeId === 3 ? 5 : 4}
                                        valueLabelDisplay="auto"
                                        value={sliderVal.sliderB2}
                                        onChange={handleChange}
                                        disabled={!isUserAllowedToManage}
                                      />
                                    </TableCell>
                                  </TableRow>

                                  {tenantTypeId === 1 ? (
                                    <Fragment>
                                      <TableRow
                                        hover
                                        onClick={() =>
                                          handleTableRowClick("chbB2_1")
                                        }
                                        sx={{ cursor: "pointer" }}
                                      >
                                        <TableCell align="left" width="70%">
                                          {t("valutazione.form.tabellaB.b2_1")}
                                        </TableCell>
                                        <TableCell align="center" width="15%">
                                          <Checkbox
                                            id="chbB2_1"
                                            checked={chbVal.chbB2_1}
                                            onChange={handleChange}
                                            disabled={!isUserAllowedToManage}
                                          />
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          width="15%"
                                        ></TableCell>
                                      </TableRow>
                                      <TableRow
                                        hover
                                        onClick={() =>
                                          handleTableRowClick("chbB2_2")
                                        }
                                        sx={{ cursor: "pointer" }}
                                      >
                                        <TableCell align="left" width="70%">
                                          {t("valutazione.form.tabellaB.b2_2")}
                                        </TableCell>
                                        <TableCell align="center" width="15%">
                                          <Checkbox
                                            id="chbB2_2"
                                            checked={chbVal.chbB2_2}
                                            onChange={handleChange}
                                            disabled={!isUserAllowedToManage}
                                          />
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          width="15%"
                                        ></TableCell>
                                      </TableRow>
                                      <TableRow
                                        hover
                                        onClick={() =>
                                          handleTableRowClick("chbB2_3")
                                        }
                                        sx={{ cursor: "pointer" }}
                                      >
                                        <TableCell align="left" width="70%">
                                          {t("valutazione.form.tabellaB.b2_3")}
                                        </TableCell>
                                        <TableCell align="center" width="15%">
                                          <Checkbox
                                            id="chbB2_3"
                                            checked={chbVal.chbB2_3}
                                            onChange={handleChange}
                                            disabled={!isUserAllowedToManage}
                                          />
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          width="15%"
                                        ></TableCell>
                                      </TableRow>
                                      <TableRow
                                        hover
                                        onClick={() =>
                                          handleTableRowClick("chbB2_4")
                                        }
                                        sx={{ cursor: "pointer" }}
                                      >
                                        <TableCell align="left" width="70%">
                                          {t("valutazione.form.tabellaB.b2_4")}
                                        </TableCell>
                                        <TableCell align="center" width="15%">
                                          <Checkbox
                                            id="chbB2_4"
                                            checked={chbVal.chbB2_4}
                                            onChange={handleChange}
                                            disabled={!isUserAllowedToManage}
                                          />
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          width="15%"
                                        ></TableCell>
                                      </TableRow>
                                      <TableRow hover>
                                        <TableCell align="left" width="70%">
                                          <Stack direction="column" spacing={1}>
                                            <ExtendedTypography variant="subtitle2">
                                              {t(
                                                "valutazione.form.tabellaB.b2_5"
                                              )}
                                            </ExtendedTypography>
                                            <TextField
                                              id="txtAltroB2"
                                              key={uuidv4()}
                                              label=""
                                              size="small"
                                              fullWidth
                                              multiline
                                              rows={2}
                                              sx={
                                                chbVal.chbB2_Altro
                                                  ? { display: "block" }
                                                  : { display: "none" }
                                              }
                                              defaultValue={
                                                currentRischio.sp_b2_altro_note
                                              }
                                              onChange={handleChange}
                                              inputProps={{
                                                autocomplete: "nope",
                                              }}
                                              disabled={!isUserAllowedToManage}
                                            />
                                          </Stack>
                                        </TableCell>
                                        <TableCell align="center" width="15%">
                                          <Checkbox
                                            id="chbB2_Altro"
                                            checked={chbVal.chbB2_Altro}
                                            onChange={handleChange}
                                            disabled={!isUserAllowedToManage}
                                          />
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          width="15%"
                                        ></TableCell>
                                      </TableRow>
                                    </Fragment>
                                  ) : null}

                                  {tenantTypeId === 3? (                                
                                    <Fragment>                                    
                                      <TableRow
                                        hover
                                        onClick={() =>handleRadioChange("chbB2_1")}
                                        sx={{ cursor: "pointer" }}
                                      >
                                        <TableCell align="left" width="70%">
                                          Con banche
                                        </TableCell>
                                        <TableCell align="center" width="15%">                                      
                                          <Radio
                                            checked={selectedB2val === "chbB2_1"}                                        
                                            id="chbB2_1"
                                            value="chbB2_1"
                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'A' }}
                                          />
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          width="15%"
                                        ></TableCell>
                                      </TableRow>
                                      <TableRow
                                        hover
                                        onClick={() =>
                                          handleRadioChange("chbB2_2")
                                        }
                                        sx={{ cursor: "pointer" }}
                                      >
                                        <TableCell align="left" width="70%">
                                          Tra soggetti privati
                                        </TableCell>
                                        <TableCell align="center" width="15%">                                      
                                          <Radio
                                            checked={selectedB2val === "chbB2_2"}                                        
                                            value="chbB2_2"
                                            id="chbB2_2"
                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'A' }}
                                          />
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          width="15%"
                                        ></TableCell>
                                      </TableRow>                                                                                                         
                                    </Fragment>                                
                                  ): null}

                                  <TableRow>
                                    <TableCell colSpan={2}>
                                      <ExtendedTypography
                                        variant="subtitle2"
                                        fontStyle={
                                          tenantTypeId === 1 || tenantTypeId === 3
                                            ? { fontWeight: "bold" }
                                            : null
                                        }
                                      >
                                        {t("valutazione.form.tabellaB.b3")}
                                      </ExtendedTypography>
                                    </TableCell>
                                    <TableCell align="center" colSpan={1}>
                                      <Slider
                                        id="sliderB3"
                                        name="sliderB3"
                                        step={1}
                                        marks={tenantTypeId === 3 ? sliderLivelliAvv : sliderLivelli}
                                        min={1}
                                        max={tenantTypeId === 3 ? 5 : 4}
                                        valueLabelDisplay="auto"
                                        value={sliderVal.sliderB3}
                                        onChange={handleChange}
                                        disabled={!isUserAllowedToManage}
                                      />
                                    </TableCell>
                                  </TableRow>

                                  {tenantTypeId === 1 ? (
                                    <Fragment>
                                      <TableRow
                                        hover
                                        onClick={() =>
                                          handleTableRowClick("chbB3_1")
                                        }
                                        sx={{ cursor: "pointer" }}
                                      >
                                        <TableCell align="left" width="70%">
                                          {t("valutazione.form.tabellaB.b3_1")}
                                        </TableCell>
                                        <TableCell align="center" width="15%">
                                          <Checkbox
                                            id="chbB3_1"
                                            checked={chbVal.chbB3_1}
                                            onChange={handleChange}
                                            disabled={!isUserAllowedToManage}
                                          />
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          width="15%"
                                        ></TableCell>
                                      </TableRow>
                                      <TableRow
                                        hover
                                        onClick={() =>
                                          handleTableRowClick("chbB3_2")
                                        }
                                        sx={{ cursor: "pointer" }}
                                      >
                                        <TableCell align="left" width="70%">
                                          {t("valutazione.form.tabellaB.b3_2")}
                                        </TableCell>
                                        <TableCell align="center" width="15%">
                                          <Checkbox
                                            id="chbB3_2"
                                            checked={chbVal.chbB3_2}
                                            onChange={handleChange}
                                            disabled={!isUserAllowedToManage}
                                          />
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          width="15%"
                                        ></TableCell>
                                      </TableRow>
                                      <TableRow hover>
                                        <TableCell align="left" width="70%">
                                          <Stack direction="column" spacing={1}>
                                            <ExtendedTypography variant="subtitle2">
                                              {t(
                                                "valutazione.form.tabellaB.b3_3"
                                              )}
                                            </ExtendedTypography>
                                            <TextField
                                              id="txtAltroB3"
                                              key={uuidv4()}
                                              label=""
                                              size="small"
                                              fullWidth
                                              multiline
                                              rows={2}
                                              sx={
                                                chbVal.chbB3_Altro
                                                  ? { display: "block" }
                                                  : { display: "none" }
                                              }
                                              defaultValue={
                                                currentRischio.sp_b3_altro_note
                                              }
                                              onChange={handleChange}
                                              inputProps={{
                                                autocomplete: "nope",
                                              }}
                                              disabled={!isUserAllowedToManage}
                                            />
                                          </Stack>
                                        </TableCell>
                                        <TableCell align="center" width="15%">
                                          <Checkbox
                                            id="chbB3_Altro"
                                            checked={chbVal.chbB3_Altro}
                                            onChange={handleChange}
                                            disabled={!isUserAllowedToManage}
                                          />
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          width="15%"
                                        ></TableCell>
                                      </TableRow>
                                    </Fragment>
                                  ) : null}

                                  {tenantTypeId === 3? (                                
                                    <Fragment>                                    
                                      <TableRow
                                        hover
                                        onClick={() =>handleRadioChange("chbB3_1")}
                                        sx={{ cursor: "pointer" }}
                                      >
                                        <TableCell align="left" width="70%">
                                          Basso
                                        </TableCell>
                                        <TableCell align="center" width="15%">                                      
                                          <Radio
                                            checked={selectedB3val === "chbB3_1"}                                        
                                            id="chbB3_1"
                                            value="chbB3_1"
                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'A' }}
                                          />
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          width="15%"
                                        ></TableCell>
                                      </TableRow>
                                      <TableRow
                                        hover
                                        onClick={() =>
                                          handleRadioChange("chbB3_2")
                                        }
                                        sx={{ cursor: "pointer" }}
                                      >
                                        <TableCell align="left" width="70%">
                                          Medio
                                        </TableCell>
                                        <TableCell align="center" width="15%">                                      
                                          <Radio
                                            checked={selectedB3val === "chbB3_2"}                                        
                                            value="chbB3_2"
                                            id="chbB3_2"
                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'A' }}
                                          />
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          width="15%"
                                        ></TableCell>
                                      </TableRow>     
                                      <TableRow
                                        hover
                                        onClick={() =>
                                          handleRadioChange("chbB3_3")
                                        }
                                        sx={{ cursor: "pointer" }}
                                      >
                                        <TableCell align="left" width="70%">
                                          Alto
                                        </TableCell>
                                        <TableCell align="center" width="15%">                                      
                                          <Radio
                                            checked={selectedB3val === "chbB3_3"}                                        
                                            value="chbB3_3"
                                            id="chbB3_3"
                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'A' }}
                                          />
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          width="15%"
                                        ></TableCell>
                                      </TableRow>                                                                                                         
                                    </Fragment>                                
                                  ): null}

                                  <TableRow>
                                    <TableCell colSpan={2}>
                                      <ExtendedTypography
                                        variant="subtitle2"
                                        fontStyle={
                                          tenantTypeId === 1 || tenantTypeId === 3
                                            ? { fontWeight: "bold" }
                                            : null
                                        }
                                      >
                                        {tenantTypeId === 3 ? "B.4 - Frequenza e durata": t("valutazione.form.tabellaB.b4")}
                                      </ExtendedTypography>
                                    </TableCell>
                                    <TableCell align="center" colSpan={1}>
                                      <Slider
                                        id="sliderB4"
                                        name="sliderB4"
                                        step={1}
                                        marks={tenantTypeId === 3 ? sliderLivelliAvv : sliderLivelli}
                                        min={1}
                                        max={tenantTypeId === 3 ? 5 : 4}
                                        valueLabelDisplay="auto"
                                        value={sliderVal.sliderB4}
                                        onChange={handleChange}
                                        disabled={!isUserAllowedToManage}
                                      />
                                    </TableCell>
                                  </TableRow>

                                  {tenantTypeId === 1 ? (
                                    <Fragment>
                                      <TableRow
                                        hover
                                        onClick={() =>
                                          handleTableRowClick("chbB4_1")
                                        }
                                        sx={{ cursor: "pointer" }}
                                      >
                                        <TableCell align="left" width="70%">
                                          {t("valutazione.form.tabellaB.b4_1")}
                                        </TableCell>
                                        <TableCell align="center" width="15%">
                                          <Checkbox
                                            id="chbB4_1"
                                            checked={chbVal.chbB4_1}
                                            onChange={handleChange}
                                            disabled={!isUserAllowedToManage}
                                          />
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          width="15%"
                                        ></TableCell>
                                      </TableRow>
                                      <TableRow
                                        hover
                                        onClick={() =>
                                          handleTableRowClick("chbB4_2")
                                        }
                                        sx={{ cursor: "pointer" }}
                                      >
                                        <TableCell align="left" width="70%">
                                          {t("valutazione.form.tabellaB.b4_2")}
                                        </TableCell>
                                        <TableCell align="center" width="15%">
                                          <Checkbox
                                            id="chbB4_2"
                                            checked={chbVal.chbB4_2}
                                            onChange={handleChange}
                                            disabled={!isUserAllowedToManage}
                                          />
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          width="15%"
                                        ></TableCell>
                                      </TableRow>
                                      <TableRow hover>
                                        <TableCell align="left" width="70%">
                                          <Stack direction="column" spacing={1}>
                                            <ExtendedTypography variant="subtitle2">
                                              {t(
                                                "valutazione.form.tabellaB.b4_3"
                                              )}
                                            </ExtendedTypography>
                                            <TextField
                                              id="txtAltroB4"
                                              key={uuidv4()}
                                              label=""
                                              size="small"
                                              fullWidth
                                              multiline
                                              rows={2}
                                              sx={
                                                chbVal.chbB4_Altro
                                                  ? { display: "block" }
                                                  : { display: "none" }
                                              }
                                              defaultValue={
                                                currentRischio.sp_b4_altro_note
                                              }
                                              onChange={handleChange}
                                              inputProps={{
                                                autocomplete: "nope",
                                              }}
                                              disabled={!isUserAllowedToManage}
                                            />
                                          </Stack>
                                        </TableCell>
                                        <TableCell align="center" width="15%">
                                          <Checkbox
                                            id="chbB4_Altro"
                                            checked={chbVal.chbB4_Altro}
                                            onChange={handleChange}
                                            disabled={!isUserAllowedToManage}
                                          />
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          width="15%"
                                        ></TableCell>
                                      </TableRow>
                                    </Fragment>
                                  ) : null}

                                  {tenantTypeId === 3? (                                
                                    <Fragment>                                    
                                      <TableRow
                                        hover
                                        onClick={() =>handleRadioChange("chbB4_1")}
                                        sx={{ cursor: "pointer" }}
                                      >
                                        <TableCell align="left" width="70%">
                                          Occasionale
                                        </TableCell>
                                        <TableCell align="center" width="15%">                                      
                                          <Radio
                                            checked={selectedB4val === "chbB4_1"}                                        
                                            id="chbB4_1"
                                            value="chbB4_1"
                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'A' }}
                                          />
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          width="15%"
                                        ></TableCell>
                                      </TableRow>
                                      <TableRow
                                        hover
                                        onClick={() =>
                                          handleRadioChange("chbB4_2")
                                        }
                                        sx={{ cursor: "pointer" }}
                                      >
                                        <TableCell align="left" width="70%">
                                          Poco frequente
                                        </TableCell>
                                        <TableCell align="center" width="15%">                                      
                                          <Radio
                                            checked={selectedB4val === "chbB4_2"}                                        
                                            value="chbB4_2"
                                            id="chbB4_2"
                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'A' }}
                                          />
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          width="15%"
                                        ></TableCell>
                                      </TableRow>     
                                      <TableRow
                                        hover
                                        onClick={() =>
                                          handleRadioChange("chbB4_3")
                                        }
                                        sx={{ cursor: "pointer" }}
                                      >
                                        <TableCell align="left" width="70%">
                                          Frequente
                                        </TableCell>
                                        <TableCell align="center" width="15%">                                      
                                          <Radio
                                            checked={selectedB4val === "chbB4_3"}                                        
                                            value="chbB4_3"
                                            id="chbB4_3"
                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'A' }}
                                          />
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          width="15%"
                                        ></TableCell>
                                      </TableRow>                                                                                                         
                                    </Fragment>                                
                                  ): null}

                                  <TableRow>
                                    <TableCell colSpan={2}>
                                      <ExtendedTypography
                                        variant="subtitle2"
                                        fontStyle={
                                          tenantTypeId === 1 || tenantTypeId === 3
                                            ? { fontWeight: "bold" }
                                            : null
                                        }
                                      >
                                        {t("valutazione.form.tabellaB.b5")}
                                      </ExtendedTypography>
                                    </TableCell>
                                    <TableCell align="center" colSpan={1}>
                                      <Slider
                                        id="sliderB5"
                                        name="sliderB5"
                                        step={1}
                                        marks={tenantTypeId === 3 ? sliderLivelliAvv : sliderLivelli}
                                        min={1}
                                        max={tenantTypeId === 3 ? 5 : 4}
                                        valueLabelDisplay="auto"
                                        value={sliderVal.sliderB5}
                                        onChange={handleChange}
                                        disabled={!isUserAllowedToManage}
                                      />
                                    </TableCell>
                                  </TableRow>

                                  {tenantTypeId === 1 ? (
                                    <Fragment>
                                      <TableRow
                                        hover
                                        onClick={() =>
                                          handleTableRowClick("chbB5_1")
                                        }
                                        sx={{ cursor: "pointer" }}
                                      >
                                        <TableCell align="left" width="70%">
                                          {t("valutazione.form.tabellaB.b5_1")}
                                        </TableCell>
                                        <TableCell align="center" width="15%">
                                          <Checkbox
                                            id="chbB5_1"
                                            checked={chbVal.chbB5_1}
                                            onChange={handleChange}
                                            disabled={!isUserAllowedToManage}
                                          />
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          width="15%"
                                        ></TableCell>
                                      </TableRow>
                                      <TableRow
                                        hover
                                        onClick={() =>
                                          handleTableRowClick("chbB5_2")
                                        }
                                        sx={{ cursor: "pointer" }}
                                      >
                                        <TableCell align="left" width="70%">
                                          {t("valutazione.form.tabellaB.b5_2")}
                                        </TableCell>
                                        <TableCell align="center" width="15%">
                                          <Checkbox
                                            id="chbB5_2"
                                            checked={chbVal.chbB5_2}
                                            onChange={handleChange}
                                            disabled={!isUserAllowedToManage}
                                          />
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          width="15%"
                                        ></TableCell>
                                      </TableRow>
                                      <TableRow
                                        hover
                                        onClick={() =>
                                          handleTableRowClick("chbB5_3")
                                        }
                                        sx={{ cursor: "pointer" }}
                                      >
                                        <TableCell align="left" width="70%">
                                          {t("valutazione.form.tabellaB.b5_3")}
                                        </TableCell>
                                        <TableCell align="center" width="15%">
                                          <Checkbox
                                            id="chbB5_3"
                                            checked={chbVal.chbB5_3}
                                            onChange={handleChange}
                                            disabled={!isUserAllowedToManage}
                                          />
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          width="15%"
                                        ></TableCell>
                                      </TableRow>
                                      <TableRow hover>
                                        <TableCell align="left" width="70%">
                                          <Stack direction="column" spacing={1}>
                                            <ExtendedTypography variant="subtitle2">
                                              {t(
                                                "valutazione.form.tabellaB.b5_4"
                                              )}
                                            </ExtendedTypography>
                                            <TextField
                                              id="txtAltroB5"
                                              key={uuidv4()}
                                              label=""
                                              size="small"
                                              fullWidth
                                              multiline
                                              rows={2}
                                              sx={
                                                chbVal.chbB5_Altro
                                                  ? { display: "block" }
                                                  : { display: "none" }
                                              }
                                              defaultValue={
                                                currentRischio.sp_b5_altro_note
                                              }
                                              onChange={handleChange}
                                              inputProps={{
                                                autocomplete: "nope",
                                              }}
                                              disabled={!isUserAllowedToManage}
                                            />
                                          </Stack>
                                        </TableCell>
                                        <TableCell align="center" width="15%">
                                          <Checkbox
                                            id="chbB5_Altro"
                                            checked={chbVal.chbB5_Altro}
                                            onChange={handleChange}
                                            disabled={!isUserAllowedToManage}
                                          />
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          width="15%"
                                        ></TableCell>
                                      </TableRow>
                                    </Fragment>
                                  ) : null}

                                  {tenantTypeId === 3? (                                
                                    <Fragment>                                    
                                      <TableRow
                                        hover
                                        onClick={() =>handleRadioChange("chbB5_1")}
                                        sx={{ cursor: "pointer" }}
                                      >
                                        <TableCell align="left" width="70%">
                                          Congrua
                                        </TableCell>
                                        <TableCell align="center" width="15%">                                      
                                          <Radio
                                            checked={selectedB5val === "chbB5_1"}                                        
                                            id="chbB5_1"
                                            value="chbB5_1"
                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'A' }}
                                          />
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          width="15%"
                                        ></TableCell>
                                      </TableRow>
                                      <TableRow
                                        hover
                                        onClick={() =>
                                          handleRadioChange("chbB5_2")
                                        }
                                        sx={{ cursor: "pointer" }}
                                      >
                                        <TableCell align="left" width="70%">
                                          Non congrua
                                        </TableCell>
                                        <TableCell align="center" width="15%">                                      
                                          <Radio
                                            checked={selectedB5val === "chbB5_2"}                                        
                                            value="chbB5_2"
                                            id="chbB5_2"
                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'A' }}
                                          />
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          width="15%"
                                        ></TableCell>
                                      </TableRow>                                                                                                                                                  
                                    </Fragment>                                
                                  ): null}

                                  <TableRow>
                                    <TableCell colSpan={2}>
                                      <ExtendedTypography
                                        variant="subtitle2"
                                        fontStyle={
                                          tenantTypeId === 1 || tenantTypeId === 3
                                            ? { fontWeight: "bold" }
                                            : null
                                        }
                                      >
                                        {t("valutazione.form.tabellaB.b6")}
                                      </ExtendedTypography>
                                      {rischioPaesePrest.paese !== "Italia" ? (
                                        <VaporTag
                                          variant="standard"
                                          label={
                                            rischioPaesePrest.paese +
                                            " - " +
                                            rischioPaesePrest.rischio +
                                            " (" +
                                            rischioPaesePrest.value +
                                            ")"
                                          }
                                          sx={{
                                            ...rischioPaesePrest.color,
                                            mr: 1,
                                          }}
                                        />
                                      ) : null}

                                      {rischioPaesePrest.paese === "Italia" ? (
                                        <VaporTag
                                          variant="standard"
                                          label={
                                            rischioProvinciaPrest.provincia +
                                            " - " +
                                            rischioProvinciaPrest.rischio +
                                            " (" +
                                            rischioProvinciaPrest.value +
                                            ")"
                                          }
                                          sx={rischioProvinciaPrest.color}
                                        />
                                      ) : null}
                                    </TableCell>
                                    <TableCell align="center" colSpan={1}>
                                      <Slider
                                        id="sliderB6"
                                        name="sliderB6"
                                        step={1}
                                        marks={tenantTypeId === 3 ? sliderLivelliAvv : sliderLivelli}
                                        min={1}
                                        max={tenantTypeId === 3 ? 5 : 4}
                                        valueLabelDisplay="auto"
                                        value={sliderVal.sliderB6}
                                        onChange={handleChange}
                                        disabled={!isUserAllowedToManage}
                                      />
                                    </TableCell>
                                  </TableRow>

                                  {tenantTypeId === 1 ? (
                                    <Fragment>
                                      <TableRow
                                        hover
                                        onClick={() =>
                                          handleTableRowClick("chbB6_1")
                                        }
                                        sx={{ cursor: "pointer" }}
                                      >
                                        <TableCell align="left" width="70%">
                                          {t("valutazione.form.tabellaB.b6_1")}
                                        </TableCell>
                                        <TableCell align="center" width="15%">
                                          <Checkbox
                                            id="chbB6_1"
                                            checked={chbVal.chbB6_1}
                                            onChange={handleChange}
                                            disabled={!isUserAllowedToManage}
                                          />
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          width="15%"
                                        ></TableCell>
                                      </TableRow>
                                      <TableRow
                                        hover
                                        onClick={() =>
                                          handleTableRowClick("chbB6_2")
                                        }
                                        sx={{ cursor: "pointer" }}
                                      >
                                        <TableCell align="left" width="70%">
                                          {t("valutazione.form.tabellaB.b6_2")}
                                        </TableCell>
                                        <TableCell align="center" width="15%">
                                          <Checkbox
                                            id="chbB6_2"
                                            checked={chbVal.chbB6_2}
                                            onChange={handleChange}
                                            disabled={!isUserAllowedToManage}
                                          />
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          width="15%"
                                        ></TableCell>
                                      </TableRow>
                                      <TableRow
                                        hover
                                        onClick={() =>
                                          handleTableRowClick("chbB6_3")
                                        }
                                        sx={{ cursor: "pointer" }}
                                      >
                                        <TableCell align="left" width="70%">
                                          {t("valutazione.form.tabellaB.b6_3")}
                                        </TableCell>
                                        <TableCell align="center" width="15%">
                                          <Checkbox
                                            id="chbB6_3"
                                            checked={chbVal.chbB6_3}
                                            onChange={handleChange}
                                            disabled={!isUserAllowedToManage}
                                          />
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          width="15%"
                                        ></TableCell>
                                      </TableRow>
                                      <TableRow hover>
                                        <TableCell align="left" width="70%">
                                          <Stack direction="column" spacing={1}>
                                            <ExtendedTypography variant="subtitle2">
                                              {t(
                                                "valutazione.form.tabellaB.b6_4"
                                              )}
                                            </ExtendedTypography>
                                            <TextField
                                              id="txtAltroB6"
                                              key={uuidv4()}
                                              label=""
                                              size="small"
                                              fullWidth
                                              multiline
                                              rows={2}
                                              sx={
                                                chbVal.chbB6_Altro
                                                  ? { display: "block" }
                                                  : { display: "none" }
                                              }
                                              defaultValue={
                                                currentRischio.sp_b6_altro_note
                                              }
                                              onChange={handleChange}
                                              inputProps={{
                                                autocomplete: "nope",
                                              }}
                                              disabled={!isUserAllowedToManage}
                                            />
                                          </Stack>
                                        </TableCell>
                                        <TableCell align="center" width="15%">
                                          <Checkbox
                                            id="chbB6_Altro"
                                            checked={chbVal.chbB6_Altro}
                                            onChange={handleChange}
                                            disabled={!isUserAllowedToManage}
                                          />
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          width="15%"
                                        ></TableCell>
                                      </TableRow>
                                    </Fragment>
                                  ) : null}

                                  {tenantTypeId === 3? (                                
                                <Fragment>                                    
                                  <TableRow
                                    hover
                                    onClick={() =>handleRadioChange("chbB6_1")}
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell align="left" width="70%">
                                      Italia
                                    </TableCell>
                                    <TableCell align="center" width="15%">                                      
                                      <Radio
                                        checked={selectedB6val === "chbB6_1"}                                        
                                        id="chbB6_1"
                                        value="chbB6_1"
                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'A' }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="15%"
                                    ></TableCell>
                                  </TableRow>
                                  <TableRow
                                    hover
                                    onClick={() =>
                                      handleRadioChange("chbB6_2")
                                    }
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell align="left" width="70%">
                                      Paesi UE
                                    </TableCell>
                                    <TableCell align="center" width="15%">                                      
                                      <Radio
                                        checked={selectedB6val === "chbB6_2"}                                        
                                        value="chbB6_2"
                                        id="chbB6_2"
                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'A' }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="15%"
                                    ></TableCell>
                                  </TableRow>
                                  <TableRow
                                    hover
                                    onClick={() =>
                                      handleRadioChange("chbB6_3")
                                    }
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell align="left" width="70%">
                                      Paesi extra UE
                                    </TableCell>
                                    <TableCell align="center" width="15%">                                      
                                      <Radio
                                        checked={selectedB6val === "chbB6_3"}                                        
                                        value="chbB6_3"
                                        id="chbB6_3"
                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'A' }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="15%"
                                    ></TableCell>
                                  </TableRow>  
                                  <TableRow
                                    hover
                                    onClick={() =>
                                      handleRadioChange("chbB6_4")
                                    }
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <TableCell align="left" width="70%">
                                      Terrirori off shore
                                    </TableCell>
                                    <TableCell align="center" width="15%">                                      
                                      <Radio
                                        checked={selectedB6val === "chbB6_4"}                                        
                                        value="chbB6_4"
                                        id="chbB6_4"
                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'A' }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="15%"
                                    ></TableCell>
                                  </TableRow>                                                                    
                                </Fragment>                                
                              ): null}

                                  <TableRow
                                    sx={{
                                      backgroundColor: "hsl(199, 100%, 40%)",
                                    }}
                                  >
                                    <TableCell
                                      colSpan="2"
                                      align="right"
                                      width="85%"
                                    >
                                      <ExtendedTypography
                                        variant="subtitle2"
                                        fontStyle={{
                                          fontWeight: "bold",
                                          color: "white",
                                        }}
                                      >
                                        {t("rischio.rischioForm.perfSpRsk")}
                                      </ExtendedTypography>
                                    </TableCell>
                                    <TableCell align="center" width="15%">
                                      <ExtendedTypography
                                        variant="subtitle2"
                                        fontStyle={{
                                          fontWeight: "bold",
                                          color: "white",
                                        }}
                                      >
                                        {subTotaleB}
                                      </ExtendedTypography>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Fragment>
                        ) : null}

                        {tenantTypeId === 2 ? (
                          <Fragment>
                            <Divider sx={{ mt: 2, mb: 2 }} />

                            <TableContainer component={Paper} sx={{ mt: 2 }}>
                              <Table size="small">
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="left" width="70%">
                                      <ExtendedTypography
                                        variant="subtitle2"
                                        fontStyle={{ fontWeight: "bold" }}
                                      >
                                        {t("valutazione.form.tabellaC.titolo")}
                                      </ExtendedTypography>
                                    </TableCell>
                                    <TableCell align="center" width="15%">
                                      <ExtendedTypography
                                        variant="subtitle2"
                                        fontStyle={{ fontWeight: "bold" }}
                                      >
                                        {t(
                                          "valutazione.form.fattoreRischioRiscontrato"
                                        )}
                                      </ExtendedTypography>
                                    </TableCell>
                                    <TableCell align="center" width="15%">
                                      <ExtendedTypography
                                        variant="subtitle2"
                                        fontStyle={{ fontWeight: "bold" }}
                                      >
                                        {t(
                                          "valutazione.form.livelloRischioSpecifico"
                                        )}
                                      </ExtendedTypography>
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow hover>
                                    <TableCell colSpan={2}>
                                      <ExtendedTypography variant="subtitle2">
                                        {t("valutazione.form.tabellaC.c1")}
                                      </ExtendedTypography>
                                    </TableCell>
                                    <TableCell align="center" colSpan={1}>
                                      <Slider
                                        id="sliderC1"
                                        name="sliderC1"
                                        step={1}
                                        marks={tenantTypeId === 3 ? sliderLivelliAvv : sliderLivelli}
                                        min={1}
                                        max={tenantTypeId === 3 ? 5 : 4}
                                        valueLabelDisplay="auto"
                                        value={sliderVal.sliderC1}
                                        onChange={handleChange}
                                        disabled={!isUserAllowedToManage}
                                      />
                                    </TableCell>
                                  </TableRow>
                                  <TableRow hover>
                                    <TableCell colSpan={2}>
                                      <ExtendedTypography variant="subtitle2">
                                        {t("valutazione.form.tabellaC.c2")}
                                      </ExtendedTypography>
                                    </TableCell>
                                    <TableCell align="center" colSpan={1}>
                                      <Slider
                                        id="sliderC2"
                                        name="sliderC2"
                                        step={1}
                                        marks={tenantTypeId === 3 ? sliderLivelliAvv : sliderLivelli}
                                        min={1}
                                        max={tenantTypeId === 3 ? 5 : 4}
                                        valueLabelDisplay="auto"
                                        value={sliderVal.sliderC2}
                                        onChange={handleChange}
                                        disabled={!isUserAllowedToManage}
                                      />
                                    </TableCell>
                                  </TableRow>
                                  <TableRow hover>
                                    <TableCell colSpan={2}>
                                      <ExtendedTypography variant="subtitle2">
                                        {t("valutazione.form.tabellaC.c3")}
                                      </ExtendedTypography>
                                    </TableCell>
                                    <TableCell align="center" colSpan={1}>
                                      <Slider
                                        id="sliderC3"
                                        name="sliderC3"
                                        step={1}
                                        marks={tenantTypeId === 3 ? sliderLivelliAvv : sliderLivelli}
                                        min={1}
                                        max={tenantTypeId === 3 ? 5 : 4}
                                        valueLabelDisplay="auto"
                                        value={sliderVal.sliderC3}
                                        onChange={handleChange}
                                        disabled={!isUserAllowedToManage}
                                      />
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Fragment>
                        ) : null}
                      </Stack>
                      {/* Colonna di destra */}
                      { tenantTypeId !== 3 ?
                      <Stack
                        direction="column"
                        spacing={1}
                        sx={{ width: "30%" }}
                      >
                        <ExtendedTypography
                          variant="titleSmall"
                          color="primary.main"
                        >
                          Rischio inerente
                        </ExtendedTypography>
                        <ExtendedTypography
                          variant="subtitle2"
                          fontStyle={{ fontWeight: "bold" }}
                        >
                          {t("valutazione.form.prestazione")}
                        </ExtendedTypography>
                        <ExtendedTypography variant="subtitle2">
                          {currentPrestazione.prestazioneNome}
                        </ExtendedTypography>
                        <ExtendedTypography
                          variant="subtitle2"
                          fontStyle={{ fontWeight: "bold" }}
                        >
                          {t("valutazione.form.regoleCondotta")}
                        </ExtendedTypography>
                        <ExtendedTypography variant="subtitle2">
                          {currentPrestazione.prestazioneRegole === null
                            ? "Non previste"
                            : currentPrestazione.prestazioneRegole}
                        </ExtendedTypography>
                        <Divider variant="standard" />
                        <Stack direction="row" spacing={1}>
                          <ExtendedTypography
                            variant="subtitle2"
                            fontStyle={{ fontWeight: "bold" }}
                          >
                            {t("valutazione.form.rischioInerente")}
                          </ExtendedTypography>
                          <Box flexGrow={1} />
                          <ExtendedTypography variant="subtitle2">
                            {currentPrestazione.prestazioneRischio.toFixed(2)}
                          </ExtendedTypography>
                        </Stack>
                        <Divider variant="standard" />
                        <Stack direction="row" spacing={1}>
                          <ExtendedTypography
                            variant="subtitle2"
                            fontStyle={{ fontWeight: "bold" }}
                          >
                            {t("valutazione.form.rischioInerente")}{" "}
                            {t("rischio.rischioForm.pond")}{" "}
                            {tenantTypeId === 1 ? "30%" : "40%"}
                          </ExtendedTypography>
                          <Box flexGrow={1} />
                          <ExtendedTypography variant="subtitle2">
                            {totRischioInerente}
                          </ExtendedTypography>
                        </Stack>
                        <VaporTag
                          variant="standard"
                          label={rischioInerenteLivelloS}
                          sx={rischioInerenteLivelloC}
                        />
                        <Divider variant="standard" />
                        <ExtendedTypography
                          variant="titleSmall"
                          color="primary.main"
                        >
                          {t("valutazione.form.rischioSpecifico")}
                        </ExtendedTypography>
                        <Stack direction="row" spacing={1}>
                          <ExtendedTypography
                            variant="subtitle2"
                            fontStyle={{ fontWeight: "bold" }}
                          >
                            {t("rischio.rischioForm.rskSpecTot")}
                          </ExtendedTypography>
                          <Box flexGrow={1} />
                          <ExtendedTypography variant="subtitle2">
                            {totRischioSpecifico}
                          </ExtendedTypography>
                        </Stack>
                        <Divider variant="standard" />
                        <Stack direction="row" spacing={1}>
                          <ExtendedTypography
                            variant="subtitle2"
                            fontStyle={{ fontWeight: "bold" }}
                          >
                            {t("rischio.rischioForm.rskSpecPond")}{" "}
                            {tenantTypeId === 1 ? "70%" : "60%"}
                          </ExtendedTypography>
                          <Box flexGrow={1} />
                          <ExtendedTypography variant="subtitle2">
                            {totRischioSpecificoPonderato}
                          </ExtendedTypography>
                        </Stack>
                        <Divider variant="standard" />
                        <ExtendedTypography
                          variant="titleSmall"
                          color="primary.main"
                        >
                          {t("dashboard.riskeff")}
                        </ExtendedTypography>
                        <Stack direction="row" spacing={1}>
                          <ExtendedTypography
                            variant="subtitle2"
                            fontStyle={{ fontWeight: "bold" }}
                          >
                            {t("valutazione.form.totale")}
                          </ExtendedTypography>
                          <Box flexGrow={1} />
                          <ExtendedTypography variant="subtitle2">
                            {totRischioEffettivo}
                          </ExtendedTypography>
                        </Stack>
                        <VaporTag
                          variant="standard"
                          label={rischioEffettivoLivelloS}
                          sx={rischioEffettivoLivelloC}
                        />
                        <Divider variant="standard" />
                        <ExtendedTypography
                          variant="titleSmall"
                          color="primary.main"
                        >
                          {t("valutazione.form.adeguataVerifica")}
                        </ExtendedTypography>
                        <ExtendedTypography variant="subtitle2">
                          {regoleAdeguataVerifica}
                        </ExtendedTypography>
                        <Divider variant="standard" />
                        <Stack direction="row" spacing={1}>
                          <ExtendedTypography
                            variant="subtitle2"
                            fontStyle={{ fontWeight: "bold" }}
                          >
                            {t("rischio.rischioForm.cntConst")}
                          </ExtendedTypography>
                          <Box flexGrow={1} />
                          <ExtendedTypography variant="subtitle2">
                            {contCostAdeguataVerifica} mesi
                          </ExtendedTypography>
                        </Stack>
                        <Divider variant="standard" />
                        <Stack direction="row" spacing={1}>
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            adapterLocale={it}
                          >
                            <DatePicker
                              label="Prossima verifica"
                              closeOnSelect
                              format="dd/MM/yyyy"
                              mask="__/__/____"
                              minDate={
                                isDateValid(currentRischio.date)
                                  ? new Date(currentRischio.date)
                                  : null
                              }
                              value={
                                isDateValid(dataContCostAdeguataVerifica)
                                  ? new Date(dataContCostAdeguataVerifica)
                                  : null
                              }
                              onChange={handleChangeAdegVerDate}
                              slotProps={{
                                textField: {
                                  required: true,
                                  placeholder: "dd/MM/yyyy",
                                  size: "small",
                                  fullWidth: true,
                                },
                              }}
                              disabled={!isUserAllowedToManage}
                            />
                          </LocalizationProvider>                    
                        </Stack>
                        <VaporTag
                          variant="standard"
                          label={livelloAdeguataVerificaS}
                          sx={livelloAdeguataVerificaC}
                        />
                      </Stack> :
                      <Stack spacing={2}>
                        <TableContainer component={Paper} sx={{ mt: 2 }}>
                              <Table size="small">
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="left" width="70%">
                                      <ExtendedTypography
                                        variant="subtitle2"
                                        fontStyle={{ fontWeight: "bold" }}
                                      >
                                        Rischio di riciclaggio
                                      </ExtendedTypography>
                                    </TableCell>
                                    <TableCell align="center" width="30%">
                                      <ExtendedTypography
                                        variant="subtitle2"
                                        fontStyle={{ fontWeight: "bold" }}
                                      >
                                        Punteggio
                                      </ExtendedTypography>
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell align="left" width="70%">
                                      <ExtendedTypography
                                        variant="subtitle2"
                                        fontStyle={{ fontWeight: "bold" }}
                                      >
                                        Totale punteggio A
                                      </ExtendedTypography>
                                    </TableCell>
                                    <TableCell align="center" width="30%">
                                      <ExtendedTypography
                                        variant="subtitle2"                                        
                                      >
                                        {subTotaleA}
                                      </ExtendedTypography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="left" width="70%">
                                      <ExtendedTypography
                                        variant="subtitle2"
                                        fontStyle={{ fontWeight: "bold" }}
                                      >
                                        Totale punteggio B
                                      </ExtendedTypography>
                                    </TableCell>
                                    <TableCell align="center" width="30%">
                                      <ExtendedTypography
                                        variant="subtitle2"                                        
                                      >
                                        {subTotaleB}
                                      </ExtendedTypography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="left" width="70%">
                                      <ExtendedTypography
                                        variant="subtitle2"
                                        fontStyle={{ fontWeight: "bold" }}
                                      >
                                        Totale complessivo (C)
                                      </ExtendedTypography>
                                    </TableCell>
                                    <TableCell align="center" width="30%">
                                      <ExtendedTypography
                                        variant="subtitle2"                                        
                                      >
                                        {subTotaleA+subTotaleB}
                                      </ExtendedTypography>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                        </TableContainer>                        
                        <ExtendedTypography variant="subtitle2">
                          <b>Controllo Costante :</b> {contCostAdeguataVerifica} mesi
                        </ExtendedTypography>
                        <Divider variant="standard" sx={{mt:2}}/>
                        <Stack direction="row" spacing={1}>
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            adapterLocale={it}
                          >
                            <DatePicker
                              label="Prossima verifica"
                              closeOnSelect
                              format="dd/MM/yyyy"
                              mask="__/__/____"
                              minDate={
                                isDateValid(currentRischio.date)
                                  ? new Date(currentRischio.date)
                                  : null
                              }
                              value={
                                isDateValid(currentRischio.dataControlloCostante)
                                  ? new Date(currentRischio.dataControlloCostante)
                                  : null
                              }
                              onChange={handleChangeAdegVerDate}
                              slotProps={{
                                textField: {
                                  required: true,
                                  placeholder: "dd/MM/yyyy",
                                  size: "small",
                                  fullWidth: true,
                                },
                              }}
                              disabled={!isUserAllowedToManage}
                            />
                          </LocalizationProvider>                    
                        </Stack>
                        <VaporTag
                          variant="standard"
                          label={labelRischioAvv}
                          sx={livelloRischioAvv}                          
                        />
                        <Divider variant="standard" sx={{mt:2}}/>
                      </Stack> }
                    </Stack>
                  </Fragment>
                ) : null}
              </Stack>
            </Stack>
          </Fragment>
        ) : null}
      </VaporPage.Section>
      {isDataLoaded &&
      tenantTypeId > 0 &&
      showRischiForm &&
      userPermissions.canCreate &&
      isUserAllowedToManage ? (
        <VaporToolbar
          variant="regular"
          size="medium"
          withoutAppBar={false}
          contentRight={
            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                color="secondary"
                size="medium"
                endIcon={<CancelIcon />}
                onClick={handleCancelClick}
              >
                {t("actions.annulla")}
              </Button>
              <Button
                variant="contained"
                size="medium"
                endIcon={<SaveIcon />}
                onClick={handleSaveClick}
              >
                {currentRischio.id === 0
                  ? t("actions.salva")
                  : t("actions.aggiorna")}
              </Button>
            </Stack>
          }
        ></VaporToolbar>
      ) : null}
    </Fragment>
  );
  //#endregion
};
